<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <!-- content-header component -->
        <app-content-header [contentHeader]="contentHeader"></app-content-header>

        <!-- Basic Alerts start -->
        <section id="statistics-card">

            <!-- Miscellaneous Charts -->
            <div class="row match-height">
                <div *ngIf="identity.role == 'ROLE_ADMIN' || identity.role == 'ROLE_PRESIDENTA' || identity.role == 'DIRSPM'" class="col-lg-12 col-12">
                    <div class="card card-statistics">

                        <div class="card-body statistics-body">
                            <div class="row">

                                <div class="col mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-gradient-primary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="trending-up" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ totalTotal }}</h4>

                                            <p class="card-text font-small-3 mb-0">Total</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-lighten-2 mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="check" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ countReceived }} </h4>
                                            <p class="card-text font-small-3 mb-0">Recibidos</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-primary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="check-circle" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ countVerified }} </h4>
                                            <p class="card-text font-small-3 mb-0">Verificados</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-gradient-warning mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="calendar" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ countProgrammed }} </h4>


                                            <p class="card-text font-small-3 mb-0">Programados</p>
                                        </div>
                                    </div>
                                </div>



                            </div>
                            <br>
                            <div class="row">
                                <div class="col mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-warning mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="loader" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ countPending }} </h4>


                                            <p class="card-text font-small-3 mb-0">Pendientes</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-success mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="thumbs-up" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ countAttended }} </h4>


                                            <p class="card-text font-small-3 mb-0">Atendidos</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-danger mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="slash" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">

                                            <h4 class="font-weight-bolder mb-0">{{ countClosed }} </h4>

                                            <p class="card-text font-small-3 mb-0">Cerrados</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-secondary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="users" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">

                                            <h4 class="font-weight-bolder mb-0">{{ countSimilar }} </h4>

                                            <p class="card-text font-small-3 mb-0">Similares</p>
                                        </div>
                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>
                </div>

                <!-- ALUMBRADO -->
                <div *ngIf="identity.role == 'ROLE_ADMIN' || identity.role == 'ROLE_PRESIDENTA' || identity.role == 'DIRSPM'" class="col-lg-6 col-6">
                    <div class="card card-statistics">

                        <h4 class="titleCardStatics">ALUMBRADO PÚBLICO</h4>
                        <div class="card-body statistics-body">
                            <div class="row mb-md-2">
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-gradient-primary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="trending-up" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ reportsAL }}</h4>

                                            <p class="card-text font-small-3 mb-0">Total</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-lighten-2 mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="check" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ recibidoAL }} </h4>
                                            <p class="card-text font-small-3 mb-0">Recibidos</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-primary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="check-circle" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ verificadoAL }} </h4>
                                            <p class="card-text font-small-3 mb-0">Verificados</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="row mb-md-2">
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-gradient-warning mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="calendar" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ programadoAL }} </h4>


                                            <p class="card-text font-small-3 mb-0">Programados</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-warning mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="loader" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ pendienteAL }} </h4>


                                            <p class="card-text font-small-3 mb-0">Pendientes</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-success mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="thumbs-up" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ atendidoAL }} </h4>


                                            <p class="card-text font-small-3 mb-0">Atendidos</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row mb-md-2">
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-danger mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="slash" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">

                                            <h4 class="font-weight-bolder mb-0">{{ cerradoAL }} </h4>

                                            <p class="card-text font-small-3 mb-0">Cerrados</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-secondary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="users" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">

                                            <h4 class="font-weight-bolder mb-0">{{ similarAL }} </h4>

                                            <p class="card-text font-small-3 mb-0">Similares</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- PARQUES JARDINES -->
                <div *ngIf="identity.role == 'ROLE_ADMIN' || identity.role == 'ROLE_PRESIDENTA' || identity.role == 'DIRSPM'" class="col-lg-6 col-6">
                    <div class="card card-statistics">

                        <h4 class="titleCardStatics">PARQUES Y JARDÍNES</h4>
                        <div class="card-body statistics-body">
                            <div class="row mb-md-2">
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-gradient-primary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="trending-up" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ reportsPJ }}</h4>

                                            <p class="card-text font-small-3 mb-0">Total</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-lighten-2 mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="check" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ recibidoPJ }} </h4>
                                            <p class="card-text font-small-3 mb-0">Recibidos</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-primary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="check-circle" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ verificadoPJ }} </h4>
                                            <p class="card-text font-small-3 mb-0">Verificados</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="row mb-md-2">
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-gradient-warning mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="calendar" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ programadoPJ }} </h4>


                                            <p class="card-text font-small-3 mb-0">Programados</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-warning mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="loader" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ pendientePJ }} </h4>


                                            <p class="card-text font-small-3 mb-0">Pendientes</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-success mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="thumbs-up" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ atendidoPJ }} </h4>


                                            <p class="card-text font-small-3 mb-0">Atendidos</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row mb-md-2">
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-danger mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="slash" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">

                                            <h4 class="font-weight-bolder mb-0">{{ cerradoPJ }} </h4>

                                            <p class="card-text font-small-3 mb-0">Cerrados</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-secondary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="users" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">

                                            <h4 class="font-weight-bolder mb-0">{{ similarPJ }} </h4>

                                            <p class="card-text font-small-3 mb-0">Similares</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- BACHES -->
                <div *ngIf="identity.role == 'ROLE_ADMIN' || identity.role == 'ROLE_PRESIDENTA' || identity.role == 'DIRSPM'" class="col-lg-6 col-6">
                    <div class="card card-statistics">

                        <h4 class="titleCardStatics">BACHEO Y MAQUINARIA</h4>
                        <div class="card-body statistics-body">
                            <div class="row mb-md-2">
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-gradient-primary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="trending-up" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ reportsBAC }}</h4>

                                            <p class="card-text font-small-3 mb-0">Total</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-lighten-2 mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="check" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ recibidoBAC }} </h4>
                                            <p class="card-text font-small-3 mb-0">Recibidos</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-primary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="check-circle" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ verificadoBAC }} </h4>
                                            <p class="card-text font-small-3 mb-0">Verificados</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="row mb-md-2">
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-gradient-warning mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="calendar" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ programadoBAC }} </h4>


                                            <p class="card-text font-small-3 mb-0">Programados</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-warning mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="loader" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ pendienteBAC }} </h4>


                                            <p class="card-text font-small-3 mb-0">Pendientes</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-success mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="thumbs-up" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ atendidoBAC }} </h4>


                                            <p class="card-text font-small-3 mb-0">Atendidos</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row mb-md-2">
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-danger mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="slash" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">

                                            <h4 class="font-weight-bolder mb-0">{{ cerradoBAC }} </h4>

                                            <p class="card-text font-small-3 mb-0">Cerrados</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-secondary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="users" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">

                                            <h4 class="font-weight-bolder mb-0">{{ similarBAC }} </h4>

                                            <p class="card-text font-small-3 mb-0">Similares</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- EQUIPAMIENT -->
                <div *ngIf="identity.role == 'ROLE_ADMIN' || identity.role == 'ROLE_PRESIDENTA' || identity.role == 'DIRSPM'" class="col-lg-6 col-6">
                    <div class="card card-statistics">

                        <h4 class="titleCardStatics">EQUIPAMIENTO URBANO</h4>
                        <div class="card-body statistics-body">
                            <div class="row mb-md-2">
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-gradient-primary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="trending-up" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ reportsEU }}</h4>

                                            <p class="card-text font-small-3 mb-0">Total</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-lighten-2 mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="check" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ recibidoEU }} </h4>
                                            <p class="card-text font-small-3 mb-0">Recibidos</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-primary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="check-circle" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ verificadoEU }} </h4>
                                            <p class="card-text font-small-3 mb-0">Verificados</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="row mb-md-2">
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-gradient-warning mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="calendar" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ programadoEU }} </h4>


                                            <p class="card-text font-small-3 mb-0">Programados</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-warning mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="loader" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ pendienteEU }} </h4>


                                            <p class="card-text font-small-3 mb-0">Pendientes</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-success mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="thumbs-up" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ atendidoEU }} </h4>


                                            <p class="card-text font-small-3 mb-0">Atendidos</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row mb-md-2">
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-danger mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="slash" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">

                                            <h4 class="font-weight-bolder mb-0">{{ cerradoEU }} </h4>

                                            <p class="card-text font-small-3 mb-0">Cerrados</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-secondary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="users" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">

                                            <h4 class="font-weight-bolder mb-0">{{ similarEU }} </h4>

                                            <p class="card-text font-small-3 mb-0">Similares</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- RESIDUOS -->
                <div *ngIf="identity.role == 'ROLE_ADMIN' || identity.role == 'ROLE_PRESIDENTA' || identity.role == 'DIRSPM'" class="col-lg-6 col-6">
                    <div class="card card-statistics">

                        <h4 class="titleCardStatics">RESIDUOS SÓLIDOS</h4>
                        <div class="card-body statistics-body">
                            <div class="row mb-md-2">
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-gradient-primary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="trending-up" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ reportsRES }}</h4>

                                            <p class="card-text font-small-3 mb-0">Total</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-lighten-2 mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="check" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ recibidoRES }} </h4>
                                            <p class="card-text font-small-3 mb-0">Recibidos</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-primary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="check-circle" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ verificadoRES }} </h4>
                                            <p class="card-text font-small-3 mb-0">Verificados</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="row mb-md-2">
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-gradient-warning mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="calendar" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ programadoRES }} </h4>


                                            <p class="card-text font-small-3 mb-0">Programados</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-warning mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="loader" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ pendienteRES }} </h4>


                                            <p class="card-text font-small-3 mb-0">Pendientes</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-success mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="thumbs-up" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ atendidoRES }} </h4>


                                            <p class="card-text font-small-3 mb-0">Atendidos</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row mb-md-2">
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-danger mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="slash" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">

                                            <h4 class="font-weight-bolder mb-0">{{ cerradoRES }} </h4>

                                            <p class="card-text font-small-3 mb-0">Cerrados</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-secondary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="users" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">

                                            <h4 class="font-weight-bolder mb-0">{{ similarRES }} </h4>

                                            <p class="card-text font-small-3 mb-0">Similares</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- MOVILIDAD -->
                <div *ngIf="identity.role == 'ROLE_ADMIN' || identity.role == 'ROLE_PRESIDENTA' || identity.role == 'DIRSPM'" class="col-lg-6 col-6">
                    <div class="card card-statistics">

                        <h4 class="titleCardStatics">MOVILIDAD</h4>
                        <div class="card-body statistics-body">
                            <div class="row mb-md-2">
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-gradient-primary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="trending-up" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ reportsMOV }}</h4>

                                            <p class="card-text font-small-3 mb-0">Total</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-lighten-2 mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="check" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ recibidoMOV }} </h4>
                                            <p class="card-text font-small-3 mb-0">Recibidos</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-primary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="check-circle" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ verificadoMOV }} </h4>
                                            <p class="card-text font-small-3 mb-0">Verificados</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="row mb-md-2">
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-gradient-warning mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="calendar" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ programadoMOV }} </h4>


                                            <p class="card-text font-small-3 mb-0">Programados</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-warning mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="loader" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ pendienteRES }} </h4>


                                            <p class="card-text font-small-3 mb-0">Pendientes</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-success mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="thumbs-up" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ atendidoMOV }} </h4>


                                            <p class="card-text font-small-3 mb-0">Atendidos</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row mb-md-2">
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-danger mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="slash" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">

                                            <h4 class="font-weight-bolder mb-0">{{ cerradoMOV }} </h4>

                                            <p class="card-text font-small-3 mb-0">Cerrados</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-secondary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="users" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">

                                            <h4 class="font-weight-bolder mb-0">{{ similarMOV }} </h4>

                                            <p class="card-text font-small-3 mb-0">Similares</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- PROTECION CIVIL -->
                <div *ngIf="identity.role == 'ROLE_ADMIN' || identity.role == 'ROLE_PRESIDENTA' " class="col-lg-6 col-6">
                    <div class="card card-statistics">

                        <h4 class="titleCardStatics">PROTECCIÓN CIVIL</h4>
                        <div class="card-body statistics-body">
                            <div class="row mb-md-2">
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-gradient-primary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="trending-up" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ reportsPC }}</h4>

                                            <p class="card-text font-small-3 mb-0">Total</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-lighten-2 mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="check" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ recibidoPC }} </h4>
                                            <p class="card-text font-small-3 mb-0">Recibidos</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-primary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="check-circle" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ verificadoPC }} </h4>
                                            <p class="card-text font-small-3 mb-0">Verificados</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="row mb-md-2">
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-gradient-warning mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="calendar" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ programadoPC }} </h4>


                                            <p class="card-text font-small-3 mb-0">Programados</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-warning mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="loader" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ pendientePC }} </h4>


                                            <p class="card-text font-small-3 mb-0">Pendientes</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-success mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="thumbs-up" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ atendidoPC }} </h4>


                                            <p class="card-text font-small-3 mb-0">Atendidos</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row mb-md-2">
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-danger mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="slash" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">

                                            <h4 class="font-weight-bolder mb-0">{{ cerradoPC }} </h4>

                                            <p class="card-text font-small-3 mb-0">Cerrados</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-secondary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="users" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">

                                            <h4 class="font-weight-bolder mb-0">{{ similarPC }} </h4>

                                            <p class="card-text font-small-3 mb-0">Similares</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- REGLAMENTOS -->
                <div *ngIf="identity.role == 'ROLE_ADMIN' || identity.role == 'ROLE_PRESIDENTA' " class="col-lg-6 col-6">
                    <div class="card card-statistics">

                        <h4 class="titleCardStatics">REGLAMENTOS</h4>
                        <div class="card-body statistics-body">
                            <div class="row mb-md-2">
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-gradient-primary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="trending-up" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ reportsREGLA }}</h4>

                                            <p class="card-text font-small-3 mb-0">Total</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-lighten-2 mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="check" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ recibidoREGLA }} </h4>
                                            <p class="card-text font-small-3 mb-0">Recibidos</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-primary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="check-circle" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ verificadoREGLA }} </h4>
                                            <p class="card-text font-small-3 mb-0">Verificados</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="row mb-md-2">
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-gradient-warning mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="calendar" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ programadoREGLA }} </h4>


                                            <p class="card-text font-small-3 mb-0">Programados</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-warning mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="loader" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ pendienteREGLA }} </h4>


                                            <p class="card-text font-small-3 mb-0">Pendientes</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-success mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="thumbs-up" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ atendidoREGLA }} </h4>


                                            <p class="card-text font-small-3 mb-0">Atendidos</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row mb-md-2">
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-danger mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="slash" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">

                                            <h4 class="font-weight-bolder mb-0">{{ cerradoREGLA }} </h4>

                                            <p class="card-text font-small-3 mb-0">Cerrados</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-secondary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="users" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">

                                            <h4 class="font-weight-bolder mb-0">{{ similarREGLA }} </h4>

                                            <p class="card-text font-small-3 mb-0">Similares</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- TRANSITO -->
                <div *ngIf="identity.role == 'ROLE_ADMIN' || identity.role == 'ROLE_PRESIDENTA' " class="col-lg-6 col-6">
                    <div class="card card-statistics">

                        <h4 class="titleCardStatics">TRANSITO</h4>
                        <div class="card-body statistics-body">
                            <div class="row mb-md-2">
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-gradient-primary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="trending-up" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ reportsTRANS }}</h4>

                                            <p class="card-text font-small-3 mb-0">Total</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-lighten-2 mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="check" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ recibidoTRANS }} </h4>
                                            <p class="card-text font-small-3 mb-0">Recibidos</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-primary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="check-circle" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ verificadoTRANS }} </h4>
                                            <p class="card-text font-small-3 mb-0">Verificados</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="row mb-md-2">
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-gradient-warning mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="calendar" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ programadoTRANS }} </h4>


                                            <p class="card-text font-small-3 mb-0">Programados</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-warning mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="loader" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ pendienteTRANS }} </h4>


                                            <p class="card-text font-small-3 mb-0">Pendientes</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-success mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="thumbs-up" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ atendidoTRANS }} </h4>


                                            <p class="card-text font-small-3 mb-0">Atendidos</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row mb-md-2">
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-danger mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="slash" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">

                                            <h4 class="font-weight-bolder mb-0">{{ cerradoTRANS }} </h4>

                                            <p class="card-text font-small-3 mb-0">Cerrados</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-secondary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="users" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">

                                            <h4 class="font-weight-bolder mb-0">{{ similarTRANS }} </h4>

                                            <p class="card-text font-small-3 mb-0">Similares</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <!-- <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Kick start your project 🚀</h4>
                        </div>
                        <h1>
                        </h1>
                        <div class="card-body pb-1">
                            <p class="card-text">All the best for your new project.</p>
                            <p class="card-text">
                                Please make sure to read our
                                <a href="https://pixinvent.com/demo/vuexy-angular-admin-dashboard-template/documentation/" target="_blank">Template Documentation</a
                >
                to understand where to go from here and how to use our template.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section> -->
        <!-- Basic Alerts end -->
        <!-- 
    <h1>Info Controller</h1>
    <div class="allertas"> 
      <h3>-Alertas</h3>
      <form 
        [formGroup]="AlertForm"
          autocomplete="off">
    
        <div class="form-group material-style">
            <input 
              formControlName="titulo_alerta"
              name="titulo_alerta"
              type="text" 
              class="form-control register">
            <label>Titulo Alerta:</label>
        </div>
    
        <div class="form-group material-style">
            <textarea 
              formControlName="descripcion_breve"          
              name="descripcion_breve"
              placeholder="De que trata..." cols="50" rows="10"
              minlength="10" 
              maxlength="1800" 
              class="form-control register"></textarea>
              <label>Descripcion Breve:</label>
            
        </div>
        <div class="form-group material-style">        
            <label>Fecha de Publicación:</label>
            <b><span>{{Today}}</span></b>
        </div>
        <div class="form-group material-style">
          <input           
            formControlName="xaction"
            name="fecha"
            type="date" 
            class="form-control register">
          <label>Fecha del Evento:</label>
      </div>
        <div class="form-group material-style">
            <input           
              formControlName="xpiration"
              name="fecha"
              type="date" 
              class="form-control register">
            <label>Fecha de Expiración:</label>
        </div>
        <div class="form-group material-style">
            <input           
              name="image"
              type="file" 
              (change)="Onchange($event)"
              class="form-control register">
            <label>image:</label>
        </div>
        <div class="form-group material-style">
            <input           
              formControlName="btn_name"
              name="btn_name"
              type="text" 
              class="form-control register">
            <label>Btn_name:</label>
        </div>
        <div class="form-group material-style">
            <input           
              formControlName="btn_url"
              name="btn_url"
              type="text" 
              class="form-control register">
            <label>Btn_url:</label>
        </div>
      
    
        <button [disabled]="loading"  (click)="Alertsender()" class="btn btn-secondary">
          {{ (loading)? 'Publicando...': 'Publicar' }}
      </button>
      </form>
    </div> -->
        <!-- <div class="Eventos"> 
      <h3>-Eventos</h3>
      <form 
        [formGroup]="EventForm"
          autocomplete="off">
    
        <div class="form-group material-style">
            <input 
              formControlName="titulo_evento"
              name="titulo_evento"
              type="text" 
              class="form-control register">
            <label>Titulo Evento:</label>
        </div>
        <div class="form-group material-style">        
          <label>Fecha de Publicación:</label>
          <b><span>{{Today}}</span></b>
      </div>
      <div class="form-group material-style">
          <input           
            formControlName="xaction"
            name="fecha"
            type="date" 
            class="form-control register">
          <label>Fecha del Evento:</label>
      </div>
      <div class="form-group material-style">
          <input           
            formControlName="xpiration"
            name="fecha"
            type="date" 
            class="form-control register">
          <label>Fecha de Expiración:</label>
      </div>
        <div class="form-group material-style">
            <input 
              formControlName="hora_evento"
              name="duracion_evento"
              type="txt" 
              class="form-control register">
            <label>Hora de Evento:</label>
        </div>
        <div class="form-group material-style">
            <input 
              formControlName="duracion_evento"
              name="duracion_evento"
              type="number" 
              class="form-control register">
            <label>Duracion Evento:</label>
        </div>
        <div class="form-group material-style">
            <textarea 
              formControlName="descripcion_evento"
              name="descripcion_evento"
              placeholder="De que trata..." cols="50" rows="10"
              minlength="10" 
              maxlength="1800" 
              class="form-control register"></textarea>
              
            <label>Descripcion Evento:</label>
        </div>
        <div class="form-group material-style">
            <input           
              name="imagen_evento"
              type="file" 
              (change)="Onchange($event)"
              class="form-control register">
            <label>imagen:</label>
        </div>    
        
        <div class="form-group material-style">
            <input           
              formControlName="lugar_evento"
              name="lugar_evento"
              type="text" 
              class="form-control register">
            <label>Lugar Evento:</label>
        </div>
        <div class="form-group material-style">
            <input           
              formControlName="organizador"
              name="organizador"
              type="text" 
              class="form-control register">
            <label>Organizador:</label>
        </div>
        <div class="form-group material-style">
            <input           
              formControlName="nombre_boton"
              name="nombre_boton"
              type="text" 
              class="form-control register">
            <label>Nombre boton:</label>
        </div>
        <div class="form-group material-style">
            <input           
              formControlName="enlace_boton"
              name="enlace_boton"
              type="text" 
              class="form-control register">
            <label>Enlace al Evento:</label>
        </div>
        <div class="form-group material-style">
            <input           
              formControlName="lp"
              name="lp"
              type="text" 
              class="form-control register">
            <label>Lp:</label>
        </div>
        <p>Coordenadas: </p>
        <div class="form-group material-style">
            <input           
              formControlName="categorias"
              name="categorias"
              type="text" 
              class="form-control register">
            <label>categorias:</label>
        </div>
        <button [disabled]="loading"  (click)="Eventsender()" class="btn btn-secondary">
          {{ (loading)? 'Publicando...': 'Publicar' }}
      </button>
      </form>
    </div> -->
        <!-- <div class="Giornale"> 
      <h3>-Noticias</h3>
      <form 
        [formGroup]="GionaleForm"
          autocomplete="off">
    
        <div class="form-group material-style">
            <input 
              formControlName="titulo_noticia"
              name="titulo_noticia"
              type="text" 
              class="form-control register">
            <label>Titulo Noticia:</label>
        </div>
    
        <div class="form-group material-style">
            <textarea
              formControlName="descripcion_breve"          
              name="descripcion_breve"
              placeholder="De que trata..." cols="50" rows="10"
              minlength="10" 
              maxlength="800" 
              class="form-control register"></textarea>
            <label>Descripcion Breve:</label>
        </div>
        <div class="form-group material-style">        
          <label>Fecha de Publicación:</label>
          <b><span>{{Today}}</span></b>
      </div>
    
        <div class="form-group material-style">
          <input           
            formControlName="xpiration"
            name="fecha"
            type="date" 
            class="form-control register">
          <label>Fecha de Expiración:</label>
      </div>
        <div class="form-group material-style">
            <input           
              formControlName="autor"
              name="autor"
              type="text" 
              class="form-control register">
            <label>Autor:</label>
        </div>
        <div class="form-group material-style">
            <input           
              name="portada"
              type="file" 
              (change)="Onchange($event)"
              class="form-control register">
            <label>image:</label>
        </div>
        <div class="form-group material-style">
            <input           
              formControlName="content"
              name="content"
              type="text" 
              class="form-control register">
            <label>Texto Enriquecido</label>
        </div>
        <div class="form-group material-style">
            <input           
              formControlName="goto"
              name="goto"
              type="text" 
              class="form-control register">
            <label>URIShared:</label>
        </div>
      
    
        <button [disabled]="loading"  (click)="Newsender()" class="btn btn-secondary">
          {{ (loading)? 'Publicando...': 'Publicar' }}
      </button>
      </form>
    </div>
    <div class="Banners"> 
      <h3>-Banners</h3>
      <form 
        [formGroup]="Banner"
          autocomplete="off">
    
        <div class="form-group material-style">
          <label>Banner Municipio:</label>
            <input           
              name="banner_municip"
              type="file" 
              (change)="Bannr5Onchange($event)"
              class="form-control register">
        </div>
        <div class="form-group material-style">
          <input           
            formControlName="link_municip"
            name="link_one"
            type="text" 
            class="form-control register">
          <label>URL Municipal:</label>
      </div>
      <div class="form-group material-style">
        <input           
          formControlName="xpiration"
          name="fecha"
          type="date" 
          class="form-control register">
        <label>Expiración banner Municipal:</label>
      </div>
    
      <br>
      <hr>
        
    
        <div class="form-group material-style">
            <input           
              name="banner_uno"
              type="file" 
              (change)="Bannr1Onchange($event)"
              class="form-control register">
            <label>Banner 1:</label>
        </div>
        <div class="form-group material-style">
            <input           
              name="banner_dos"
              type="file" 
              (change)="Bannr2Onchange($event)"
              class="form-control register">
            <label>Banner 2:</label>
        </div>
        <div class="form-group material-style">
            <input           
              name="banner_tres"
              type="file" 
              (change)="Bannr3Onchange($event)"
              class="form-control register">
            <label>Banner 3:</label>
        </div>
        <div class="form-group material-style">
            <input           
              name="banner_cuatro"
              type="file" 
              (change)="Bannr4Onchange($event)"
              class="form-control register">
            <label>Banner 4:</label>
        </div>
        <div class="form-group material-style">
            <input           
              formControlName="link_one"
              name="link_one"
              type="text" 
              class="form-control register">
            <label>URL 1:</label>
        </div>
        <div class="form-group material-style">
            <input           
              formControlName="link_two"
              name="link_two"
              type="text" 
              class="form-control register">
            <label>URL 2:</label>
        </div>
        <div class="form-group material-style">
            <input           
              formControlName="link_three"
              name="link_three"
              type="text" 
              class="form-control register">
            <label>URL 3:</label>
        </div>
        <div class="form-group material-style">
            <input           
              formControlName="link_four"
              name="link_four"
              type="text" 
              class="form-control register">
            <label>URL 4:</label>
        </div>
    
    
      
    
        <button [disabled]="loading"  (click)="Bannersender()" class="btn btn-secondary">
          {{ (loading)? 'Publicando...': 'Publicar' }}
      </button>
      </form> 
    </div>-->
    </div>
</div>