<div *ngIf="activepopUp" class="lightboxEfe">
    <div class="row">
        <div class="col-12">
            <img [src]="imagenPrevobte" [style.transform]="'rotate(' + rotationAngle + 'deg)' | safeStyle">
            <span class="not">Nota: Si la fotografía no aparece, puede deberse a un error de conexión a Internet mientras el usuario creo el reporte</span>
        </div>
    </div>
    <div class="row botoneslight">
        <div class="col-3">
            <a class="rotatebutton" (click)="rotate()"><i class="fa-sharp fa-solid fa-rotate-right"></i></a>

        </div>
        <div class="col-6">

        </div>
        <div class="col-3">

            <a class="closebutton" (click)="closePopF()"><i class="fa-sharp fa-solid fa-xmark"></i></a>
        </div>
    </div>


</div>
<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <!-- Invoice List -->

        <section id="statistics-card">
            <!-- Miscellaneous Charts -->
            <div class="row match-height">

                <!-- TOTAL POR AREA  PARA COORDINACIONES-->

                <div *ngIf="identity.role != 'ROLE_ADMIN' || identity.role != 'ROLE_PRESIDENTA' || identity.role != 'DIRSPM'" class="col-lg-12 col-12">
                    <div class="card card-statistics">
                        <h4 class="titleCardStatics" *ngIf="identity.role == 'ROLE_PJ'">PARQUES Y JARDÍNES</h4>
                        <h4 class="titleCardStatics" *ngIf="identity.role == 'ROLE_BAC'">BACHEO Y MAQUINARIA</h4>
                        <h4 class="titleCardStatics" *ngIf="identity.role == 'ROLE_AL'">ALUMBRADO PÚBLICO</h4>
                        <h4 class="titleCardStatics" *ngIf="identity.role == 'ROLE_EU'">EQUIPAMIENTO URBANO</h4>
                        <h4 class="titleCardStatics" *ngIf="identity.role == 'ROLE_RES'">RESIDUOS SOLIDOS</h4>
                        <h4 class="titleCardStatics" *ngIf="identity.role == 'ROLE_MOV'">MOVILIDAD</h4>
                        <h4 class="titleCardStatics" *ngIf="identity.role == 'ROLE_PC'">PROTECCIÓN CIVIL</h4>
                        <h4 class="titleCardStatics" *ngIf="identity.role == 'ROLE_PLA'">CONTRALORÍA</h4>
                        <h4 class="titleCardStatics" *ngIf="identity.role == 'ROLE_REGLA'">REGLAMENTOS</h4>
                        <h4 class="titleCardStatics" *ngIf="identity.role == 'ROLE_TRANS'">TRANSITO</h4>
                        <div class="card-body statistics-body">
                            <div class="row">

                                <div class="col mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-gradient-primary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="trending-up" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ totalTotal }}</h4>

                                            <p class="card-text font-small-3 mb-0">Total</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-lighten-2 mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="check" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ countReceived }} </h4>
                                            <p class="card-text font-small-3 mb-0">Recibidos</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-primary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="check-circle" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ countVerified }} </h4>
                                            <p class="card-text font-small-3 mb-0">Verificados</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-gradient-warning mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="calendar" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ countProgrammed }} </h4>


                                            <p class="card-text font-small-3 mb-0">Programados</p>
                                        </div>
                                    </div>
                                </div>



                            </div>
                            <br>
                            <div class="row">
                                <div class="col mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-warning mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="loader" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ countPending }} </h4>


                                            <p class="card-text font-small-3 mb-0">Pendientes</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-success mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="thumbs-up" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ countAttended }} </h4>


                                            <p class="card-text font-small-3 mb-0">Atendidos</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-danger mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="slash" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">

                                            <h4 class="font-weight-bolder mb-0">{{ countClosed }} </h4>

                                            <p class="card-text font-small-3 mb-0">Cerrados</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col mb-2 mb-md-0">
                                    <div class="media">
                                        <div class="avatar bg-light-secondary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="users" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">

                                            <h4 class="font-weight-bolder mb-0">{{ countSimilar }} </h4>

                                            <p class="card-text font-small-3 mb-0">Similares</p>
                                        </div>
                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>
                </div>


                <!-- TOTAL TOTAL PARA ADMINS-->





            </div>
        </section>



        <section class="invoice-list-wrapper">
            <div class="card">
                <!-- Invoice List Header -->
                <div class="row">
                    <div class="col-md-4 col-12">
                        <div class="d-flex justify-content-between align-items-center m-1">
                            <div class="d-flex align-items-center">
                                <!-- <label class="d-flex align-items-center">Mostrar
                                    <select class="form-control mx-25" [(ngModel)]="selectedOption">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </label> -->
                                <label class="d-flex align-items-center mb-0 ml-1 ml-md-0"><input
                                        [(ngModel)]="searchValue" name="searchValue" type="search" class="form-control"
                                        placeholder="Buscar folio..." (keyup)="filterUpdate($event)"
                                        (search)="filterUpdate($event)" /></label>

                            </div>
                        </div>

                    </div>




                    <div class="
                    col-md-8 col-lg-8 col-12
                    d-flex
                    justify-content-start justify-content-md-end
                    align-items-center
                    
                  ">
                        <div class="row pr-1 pb-1 pb-md-0 align-items-center w-100">
                            <div class="col-10 col-md-3 pr-0">
                                <button *ngIf="loadingExcel" class="btn btn-primary" type="button" disabled>
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    Cargando
                                </button>
                                <button *ngIf="!loadingExcel" class="btn btn-primary" type="button" (click)="downloadExcel()">
                                    <i class="far fa-file-download"></i> &nbsp;
                                    Exportar a Excel Total
                                </button>

                            </div>
                            <div class="col-10 col-md-3 pr-0">
                                <button class="btn btn-primary" type="button" (click)="exportexcel(table)">
                                    <i class="far fa-file-download"></i> &nbsp;
                                    Exportar a Excel Previo
                                </button>

                            </div>

                            <div class="col-10 col-md-3 pr-0">
                                <fieldset class="form-group mb-0">
                                    <ng-select [items]="selectStatus" [(ngModel)]="selectedStatus" bindLabel="name" placeholder="Seleccionar Status" (change)="filterByStatus($event)"></ng-select>
                                </fieldset>
                            </div>
                            <div class="col-10 col-md-3 mt-2 ml-1 ml-md-0 mt-md-0">

                                <fieldset class="form-group mb-0">
                                    <ng-select [items]="selectUser" [(ngModel)]="selectedUser" bindLabel="name" placeholder="Seleccionar Usuario" (change)="filterByUser($event)"></ng-select>
                                </fieldset>

                                <!-- <fieldset class="form-group mb-0">
                                    <ng-select [items]="selectDepen" [(ngModel)]="selectDepen" bindLabel="name" placeholder="Seleccionar dpendencia" (change)="filterByDepen($event)"></ng-select>
                                </fieldset> -->
                            </div>
                        </div>
                    </div>
                </div>
                <!--/ Invoice List Header -->

                <!-- Invoice List Table -->

                <ngx-datatable [rows]="rows" [loadingIndicator]="isLoading" (scroll)="loadMoreData()" [rowHeight]="'auto'" class="bootstrap core-bootstrap" [columnMode]="ColumnMode.force" [headerHeight]="50" [footerHeight]="50" [scrollbarH]="true" id="excel-table" [sorts]="[{prop: 'report_date', dir: 'desc'}]">
                    <ngx-datatable-column name="FOLIO" prop="uid" [width]="250">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <a (click)="copy(row.report_id)"><i size="18" data-feather="copy"></i></a> &nbsp; <a [routerLink]="['/reporte', row.uid]" style="font-weight: 800!important;">{{
                                row.report_id }}</a>
                            <hr style="margin: 5px!important;">

                            <div *ngIf="identity.role == 'ROLE_ADMIN'">
                                <div class="badge badge-light-danger" (click)="onDeleteConfirm(row.uid)"><i class="fas fa-times-circle mr-25"></i><span>Borrar</span></div>

                                <div class="confirmacion" *ngIf="confirmado == row.uid">
                                    <div class="badge badge-warning" (click)="onCancelReport()">
                                        <i class="fas fa-trash-alt mr-25"></i><span>Cancelar</span>
                                    </div>

                                    <div class="badge badge-danger" (click)="onDeleteReport(row.uid)">
                                        <i class="fas fa-trash-alt mr-25"></i><span>Borrar</span>
                                    </div>
                                </div>

                            </div>
                        </ng-template>
                    </ngx-datatable-column>


                    <ngx-datatable-column name="Area" prop="report_area" [width]="170">
                        <ng-template let-report_area="value" ngx-datatable-cell-template>
                            <div style="font-weight: 800;">
                                <img class="reportiCON" src="../../../assets/images/iconsapp/P-BAC.png" alt="" *ngIf="report_area === 'Baches' ">
                                <img class="reportiCON" src="../../../assets/images/iconsapp/P-BAS.png" alt="" *ngIf="report_area === 'Señalizaciones' ">
                                <img class="reportiCON" src="../../../assets/images/iconsapp/P-EME.png" alt="" *ngIf="report_area === 'Emergencias' ">
                                <img class="reportiCON" src="../../../assets/images/iconsapp/P-ILU.png" alt="" *ngIf="report_area === 'Iluminación' ">
                                <img class="reportiCON" src="../../../assets/images/iconsapp/P-JAR.png" alt="" *ngIf="report_area === 'Parques y jadínes' ">
                                <img class="reportiCON" src="../../../assets/images/iconsapp/P-OBS.png" alt="" *ngIf="report_area === 'Obstrucciones vía pública' ">
                                <img class="reportiCON" src="../../../assets/images/iconsapp/P-SIG.png" alt="" *ngIf="report_area === 'Basura' ">
                                <img class="reportiCON" src="../../../assets/images/iconsapp/P-TOP.png" alt="" *ngIf="report_area === 'Topes' "> {{ report_area }}
                            </div>
                        </ng-template>
                    </ngx-datatable-column>


                    <!-- <ngx-datatable-column name="Referencias" prop="reference" [width]="200">
                        <ng-template let-reference="value" ngx-datatable-cell-template>
                            <div>
                                {{ reference }}
                            </div>
                        </ng-template>
                    </ngx-datatable-column> -->
                    <ngx-datatable-column name="Foto" prop="report_img" [width]="200" [sortable]="false">
                        <ng-template let-report_img="value" ngx-datatable-cell-template>
                            <div class="d-flex align-items-center">

                                <div (click)="openPopF(report_img)" class="imgReportlist" style="background-image: url('{{ report_img }}')">
                                </div>


                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Usuario" [width]="200" [sortable]="false">
                        <ng-template ngx-datatable-cell-template let-row="row">
                            <div style="font-weight: 800; width: 200px;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;">
                                <span style="font-weight: bolder;" *ngIf="row.usrxgene == 'elizabeth1012b@gmail.com'">VENTANILLA2</span>
                                <span style="font-weight: bolder;" *ngIf="row.usrxgene == 'ednalorenaocanajimenez@gmail.com'">VENTANILLA</span>
                                <span style="font-weight: bolder;" *ngIf="row.usrxgene == 'serviciosalumbrado@gmail.com'">REZAGADOS</span>
                                <span *ngIf="row.usrxgene != 'ednalorenaocanajimenez@gmail.com' && row.usrxgene !=  'serviciosalumbrado@gmail.com' ">{{row.usrxorigin}}</span>
                                <hr style="margin-top: 0px;
                                margin-bottom: 0px;" *ngIf="row.usrxgene != 'ednalorenaocanajimenez@gmail.com' && row.usrxgene !=  'serviciosalumbrado@gmail.com' ">
                                <span *ngIf="row.usrxgene !=  'ednalorenaocanajimenez@gmail.com' && row.usrxgene !=  'serviciosalumbrado@gmail.com' " class="mailist"> {{ row.usrxgene }}</span>


                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Fecha/Hora" prop="report_date" [width]="120">
                        <ng-template let-report_date="value" ngx-datatable-cell-template>
                            <div style="font-weight: 800; text-align: center;">
                                {{ report_date | date:'medium' }}
                            </div>

                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="status" prop="status_report" [width]="120">
                        <ng-template let-row="row" let-status_report="value" ngx-datatable-cell-template>

                            <div class="badge badge-pill badge-light-dark" *ngIf="row.status_report === 'recibido' ">
                                {{ row?.status_report }}
                            </div>
                            <div class="badge badge-pill badge-info" *ngIf="row.status_report === 'verificado' ">
                                {{ row?.status_report }}
                            </div>
                            <div class="badge badge-pill badge-warning" *ngIf="row.status_report === 'programado' ">
                                {{ row?.status_report }}
                            </div>
                            <div class="badge badge-pill badge-light-warning" *ngIf="row.status_report === 'pendiente' ">
                                {{ row?.status_report }}
                            </div>
                            <div class="badge badge-pill badge-success" *ngIf="row.status_report === 'atendido' ">
                                {{ row?.status_report }}
                            </div>
                            <div class="badge badge-pill badge-danger" *ngIf="row.status_report === 'cerrado' ">
                                {{ row?.status_report }}
                            </div>
                            <div class="badge badge-pill badge-secondary" *ngIf="row.status_report === 'similar' ">
                                {{ row?.status_report }}
                            </div>

                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Acciones" [width]="80" [sortable]="false">

                        <ng-template ngx-datatable-cell-template let-row="row">
                            <div class="d-flex align-items-center col-actions">

                                <ng-template #popContent>

                                    <div class="row match-height">

                                        <div class="col-lg-5 col-12">
                                            <div class="card card-developer-meetup" style="padding: 0!important; margin-bottom: 0!important">

                                                <div class="card-body sre">
                                                    <div class="fotoPreview">
                                                        <img src="{{row.report_img}} " alt="">

                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-7 col-12">
                                            <div class="card card-developer-meetup" style="margin-bottom: 0!important">
                                                <div class="card-header">
                                                    <h2 class="card-title" style="
                                                        font-size: 1rem!important;
                                                        margin-top: 10px;
                                                    ">{{ row.report_id }}</h2>

                                                </div>
                                                <div class="card-body">
                                                    <div class="meetup-header d-flex align-items-center">
                                                        <div class="meetup-day">
                                                            <h6 class="mb-0">{{row.report_date | date:'MMM'}}</h6>
                                                            <h3 class="mb-0">{{row.report_date | date:'d'}}</h3>
                                                            <h6 class="mb-0"></h6>
                                                        </div>
                                                        <div class="my-auto">
                                                            <h4 class="card-title mb-25"> <img class="reportiCON2" src="../../../assets/images/iconsapp/P-BAC.png" alt="" *ngIf="row.report_area === 'Baches' ">
                                                                <img class="reportiCON2" src="../../../assets/images/iconsapp/P-BAS.png" alt="" *ngIf="row.report_area === 'Señalizaciones' ">
                                                                <img class="reportiCON2" src="../../../assets/images/iconsapp/P-EME.png" alt="" *ngIf="row.report_area === 'Emergencias' ">
                                                                <img class="reportiCON2" src="../../../assets/images/iconsapp/P-ILU.png" alt="" *ngIf="row.report_area === 'Iluminación' ">
                                                                <img class="reportiCON2" src="../../../assets/images/iconsapp/P-JAR.png" alt="" *ngIf="row.report_area === 'Parques y jadínes' ">
                                                                <img class="reportiCON2" src="../../../assets/images/iconsapp/P-OBS.png" alt="" *ngIf="row.report_area === 'Obstrucciones vía pública' ">
                                                                <img class="reportiCON2" src="../../../assets/images/iconsapp/P-SIG.png" alt="" *ngIf="row.report_area === 'Basura' ">
                                                                <img class="reportiCON2" src="../../../assets/images/iconsapp/P-TOP.png" alt="" *ngIf="row.report_area === 'Topes' "> {{row.report_area}}
                                                            </h4>
                                                            <p class="card-text mb-0">{{row.report_type}}</p>
                                                            <div class="badge badge-pill badge-light-dark" *ngIf="row.status_report === 'recibido' ">
                                                                {{ row.status_report }}
                                                            </div>
                                                            <div class="badge badge-pill badge-info" *ngIf="row.status_report === 'verificado' ">
                                                                {{ row.status_report }}
                                                            </div>
                                                            <div class="badge badge-pill badge-warning" *ngIf="row.status_report === 'programado' ">
                                                                {{ row.status_report }}
                                                            </div>
                                                            <div class="badge badge-pill badge-light-warning" *ngIf="row.status_report === 'pendiente' ">
                                                                {{ row.status_report }}
                                                            </div>
                                                            <div class="badge badge-pill badge-success" *ngIf="row.status_report === 'atendido' ">
                                                                {{ row.status_report }}
                                                            </div>
                                                            <div class="badge badge-pill badge-danger" *ngIf="row.status_report === 'cerrado' ">
                                                                {{ row.status_report }}
                                                            </div>
                                                            <div class="badge badge-pill badge-secondary" *ngIf="row.status_report === 'similar' ">
                                                                {{ row.status_report }}
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="media">
                                                        <div class="avatar bg-light-primary rounded mr-1">
                                                            <div class="avatar-content">
                                                                <i data-feather="clock" class="avatar-icon font-medium-3"></i>
                                                            </div>
                                                        </div>
                                                        <div class="media-body">
                                                            <h6 class="mb-0">{{row.report_date | date:'longDate'}}</h6>
                                                            <small>{{ row.report_date | date:'mediumTime' }}</small>
                                                        </div>
                                                    </div>
                                                    <div class="media">
                                                        <div class="avatar bg-light-primary rounded mr-1">
                                                            <div class="avatar-content">
                                                                <i data-feather="map-pin" class="avatar-icon font-medium-3"></i>
                                                            </div>
                                                        </div>
                                                        <div class="media-body">
                                                            <h6 class="mb-0"> <a target="_blank" href="https://www.google.com.mx/maps/place/{{row.usrxgeoloc.lat}},{{row.usrxgeoloc.lng}} " class="mr-1" container="body" placement="top" ngbTooltip="Ver">
                                                                    Abrir en Google Maps</a></h6>
                                                            <small>{{row.reference}} </small>
                                                        </div>
                                                    </div>
                                                    <div class="media" *ngIf="row.usrxgene != 'ednalorenaocanajimenez@gmail.com' && row.usrxgene !=  'serviciosalumbrado@gmail.com'">
                                                        <div class="avatar bg-light-primary rounded mr-1">
                                                            <div class="avatar-content">
                                                                <i data-feather="user" class="avatar-icon font-medium-3"></i>
                                                            </div>
                                                        </div>
                                                        <div class="media-body">
                                                            <h6 class="mb-0">{{usrxorigin}}</h6>
                                                            <small>{{ row.usrxgene}}</small> <br>
                                                            <small><a
                                                                    href="tel:{{row.xcontact}}">{{row.xcontact}}</a></small>

                                                        </div>
                                                    </div>
                                                    <hr>
                                                    <textarea style="display:none;" #clipboardValue cols="30" rows="10" value='Ubicación: https://www.google.com.mx/maps/place/{{row.usrxgeoloc.lat}},{{row.usrxgeoloc.lng}}{{"\n"}}Folio: {{row.report_id}}{{"\n"}}Area: {{row.report_area}}{{"\n"}}Tipo: {{row.report_type}}{{"\n"}}Descripción: {{row.report_info}}{{"\n"}}Foto: {{row.report_img}}{{"\n"}}'></textarea>
                                                    <button class="btn btn-outline-primary" (click)="copyCode(clipboardValue.value)" rippleEffect><i
                                                            data-feather="copy" class="avatar-icon font-medium-3"></i>
                                                        Copiar</button>

                                                </div>
                                            </div>
                                        </div>




                                        <!-- <div class="col-lg-4 col-12">
                                            <div class="fotoPreview">
                                                <img src="{{row.report_img}} " alt="">
                                            </div>
                                        </div>
                                        <div class="col-lg-8 col-12">
                                            <b><span style="color: #9f2241;">Folio:</span> {{ row.uid }}</b>
                                            <b><span style="color: #9f2241;">Area:</span> {{row.report_area}}</b>
                                            <b><span style="color: #9f2241;">Tipo:</span> {{row.report_type}}</b>
                                            <b><span style="color: #9f2241;">Fecha:</span> {{row.report_date | date:'medium'}}</b>
                                            <b><span style="color: #9f2241;">Referencias Lugar:</span></b>
                                            <p>{{row.reference}} </p>
                                            <b><span style="color: #9f2241;">Descripción:</span></b>
                                            <p>{{row.report_info}} </p>
                                            <a target="_blank" href="https://www.google.com.mx/maps/place/{{row.usrxgeoloc.lat}},{{row.usrxgeoloc.lng}} " class="mr-1" container="body" placement="top" ngbTooltip="Ver">
                                                <i size="18" data-feather="map-pin"></i
                                                    > Abrir en Google Maps</a>
                                        </div> -->
                                    </div>





                                </ng-template>

                                <a type="button" class="mr-1" placement="left" [ngbPopover]="popContent" popoverTitle="Reporte" container="body" ngbTooltip="Vista Previa"><i size="18"
                                        data-feather="eye"></i></a>


                                <a [routerLink]="['/reporte', row.uid]" class="mr-1" container="body" placement="top" ngbTooltip="Abrir"><i size="18" data-feather="plus"></i></a>


                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                </ngx-datatable>
                <div *ngIf="!isLastPage" class="footerTab">
                    <button *ngIf="isLoading" class="btn btn-primary" type="button" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Cargando más...
                    </button>

                    <button (click)="loadMoreData()" *ngIf="!isLoading" class="btn btn-primary" type="button">
                        Cargar más
                    </button>

                    <hr *ngIf="isLastPage">
                    <span class="lastmessage" *ngIf="isLastPage">Ya se cargarón todos los reportes.</span>

                </div>

                <!--/ Invoice List Table -->
            </div>
        </section>








        <!--/ Invoice List -->
    </div>
</div>