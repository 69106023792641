// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  
  // baseUrl:   'http://localhost:80/api/',
  // baze:        'http://localhost:80/dashboard',
  // notify:   'http://localhost:5000/tizapp-28280/us-central1/api',

  baseUrl: 'https://tizaondevtesting.live/api/',
  baze:        'https://tizaondevtesting.live/dashboard',
  notify:   'https://us-central1-tizapp-28280.cloudfunctions.net/api',

   live: 'https://us-central1-tizapp-28280.cloudfunctions.net/api',
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
