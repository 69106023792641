
import { Observable } from "rxjs";


import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';

import { NgbDateStruct, NgbCalendar, NgbDate, NgbDateParserFormatter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';

import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { DomSanitizer } from '@angular/platform-browser';
import { ConectionService } from 'app/services/conection.service';


 import LocationPicker from "location-picker";


// import {
//   I18n,
//   CustomDatepickerI18n
// } from 'app/main/forms/form-elements/date-time-picker/date-picker-i18n/date-picker-i18n.service';


@Component({
  selector: "banner",
  templateUrl: "./banner.component.html",
  styleUrls: ["./banner.component.scss"],
  encapsulation: ViewEncapsulation.None
  // providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }]
})



export class BannerComponent implements OnInit {

  


  file: any;
  item:any;


  // IMG Container
  bnnr1:any;
  bnnr2:any;
  bnnr3:any;
  bnnr4:any;
  bnnr5:any;

  // NAME Container
  name1:any;
  name2:any;
  name3:any;
  name4:any;
  name5:any;


  fecha = this.datePipe.transform(Date.now(),'MMM');
  Today = Date.now();
  

  Banner:FormGroup = this.fb.group({

    xpiration:['',[Validators.minLength(6)]],    
    
    banner_municip:['' ,[Validators.minLength(3)]],
    banner_uno:[''     ,[Validators.minLength(3)]],
    banner_dos:[''     ,[Validators.minLength(3)]],
    banner_tres:[''    ,[Validators.minLength(3)]],
    banner_cuatro:[''  ,[Validators.minLength(3)]],

    link_municip:['',[Validators.minLength(6)]],
    link_one:[''    ,[Validators.minLength(6)]],
    link_two:[''    ,[Validators.minLength(6)]],
    link_three:[''  ,[Validators.minLength(6)]],
    link_four:[''   ,[Validators.minLength(6)]],

    name1:[''],
    name2:[''],
    name3:[''],
    name4:[''],
    name5:[''],

    bann1_date:[''],
    bann2_date:[''],
    bann3_date:[''],
    bann4_date:[''] 
    
  });
  constructor(
    private fb: FormBuilder,
   private datePipe: DatePipe, 
   private conn:ConectionService,
   private sanitize:DomSanitizer)
  {


 }
  public catego;

  public loading:boolean = false;
  lp: LocationPicker;

  // Basic Date Picker
  public basicDPdata: NgbDateStruct;

  // Select Multi with Icon
  public multiIconGithubUsers: Observable<any[]>;
  public multiIconGithubUsersSelected = ["anjmao"];

  public titulo_evento;
  public descripcion_evento;
  public LugarEvento;
  public imagen_evento;
  public DuracionEvento;
  public EnlaceBoton;
  public NombreBoton;

  public Organizador;


  

  public i18nDPdata: NgbDateStruct;
  
  public contentHeader: object
  // Select Multi Custom
  public multiCustomGithubUsersSelected;


  public meridianTP = true;

  public SelectedDate 


  async Bannr1Onchange(e:any){
    
    this.name1 = e.target.files[0].name;
    this.file = e.target.files[0];
    await this.conn.Toolformat64(this.file).then(resp =>{
            
      this.bnnr1 = resp;      
    });
  }
  async Bannr2Onchange(e:any){
    this.name2 = e.target.files[0].name;
    this.file = e.target.files[0];
    await this.conn.Toolformat64(this.file).then(resp =>{
            
      this.bnnr2 = resp;      
    });
  }
  async Bannr3Onchange(e:any){
    
    this.name3 = e.target.files[0].name;
    this.file = e.target.files[0];
    await this.conn.Toolformat64(this.file).then(resp =>{
            
      this.bnnr3 = resp;      
    });
  }
  async Bannr4Onchange(e:any){
    
    this.name4 = e.target.files[0].name;
    this.file = e.target.files[0];
    await this.conn.Toolformat64(this.file).then(resp =>{
            
      this.bnnr4 = resp;      
    });
  }
  async Bannr5Onchange(e:any){
    
    this.name5 = e.target.files[0].name;
    this.file = e.target.files[0];
    await this.conn.Toolformat64(this.file).then(resp =>{
            
      this.bnnr5 = resp;      
    });
  }

  
  async Onchange(e:any){
    if (e.target.files) {
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (e: any) => {
        this.url = e.target.result;
      };
    } else {
    }
    this.file = e.target.files[0];
    await this.conn.Toolformat64(this.file).then(resp =>{
            
      this.item = resp;      
    });
  }


  Bannersender(){    
    // Sanitizacion y ofuscacion de scripts     
    try{
      this.loading  = true
      
      this.Banner.get('banner_uno')?.setValue(this.bnnr1);
      this.Banner.get('banner_dos')?.setValue(this.bnnr2);
      this.Banner.get('banner_tres')?.setValue(this.bnnr3);
      this.Banner.get('banner_cuatro')?.setValue(this.bnnr4);
      this.Banner.get('banner_municip')?.setValue(this.bnnr5);

      //FILE NAME
      this.Banner.get('name1')?.setValue(this.name1);
      this.Banner.get('name2')?.setValue(this.name2);
      this.Banner.get('name3')?.setValue(this.name3);
      this.Banner.get('name4')?.setValue(this.name4);
      this.Banner.get('name5')?.setValue(this.name5);

      if(this.bnnr1 != undefined){        
        this.Banner.get('bann1_date')?.setValue(this.Today);
      }
      if(this.bnnr2 != undefined){

        this.Banner.get('bann2_date')?.setValue(this.Today);
      }
      if(this.bnnr3 != undefined){

        this.Banner.get('bann3_date')?.setValue(this.Today);
      }
      if(this.bnnr4 != undefined){

        this.Banner.get('bann4_date')?.setValue(this.Today);
      }
      
      // console.log(this.Banner.value);

      this.conn.Bannr(this.Banner.value).subscribe(resp=>{
        this.Banner.reset();
        this.loading = false;
      });

    }
    catch(err){
      this.loading = false;
      //console.log(err)
    }    
  }


  ngOnInit() {


    this.contentHeader = {
      headerTitle: 'TizAPP',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Inicio',
            isLink: true,
            link: '/'
          },
          {
            name: 'Editar banners',
            isLink: false
          }
        ]
      }
    }

   this.lp = new LocationPicker('map');

    
  }
  




  url = "../../../../assets/images/picture-default.jpg";

  onFileSelected(event) {
    if (event.target.files) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event: any) => {
        this.url = event.target.result;
      };
    } else {
    }
  }
}
