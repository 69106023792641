export class Admin{
  constructor(
    public _id: string,
    public name: string,
    public lastname: string,
    public mail: string,
    public passid: string,
    public cargo: string,
    public area: string,
    public dependencia: string,
    public role: string

  ){}
}
