
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError,map, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';



@Injectable({
  providedIn: 'root'
})
export class DbService {

  private operative: string = environment.baze;
  constructor(private http:HttpClient) {    
  }
  
  // ------------------- MAP SETTER ---------------------  
  Setusr(payload:any){ 
    const url = `${this.operative}/service-ssp/archive`;

    return this.http.post<any>(url,payload)
    .pipe(
      map(resp => resp),
      catchError(err => of(err.error.msg))
    );
  }

 // ------------------- FOLIOS LIST ---------------------  
  Getlibry(){
    const url = `${this.operative}/service-ssp/foles`;

    return this.http.get<any>(url)
    .pipe(
      tap(resp => true),
      catchError(err => of(err.error.msg))
    );       
  }

  

// ------------------- USR REPORT ---------------------  
  UsrxInfo(data:any){
    const url = `${this.operative}/service-ssp/repoxusr`;

    return this.http.get<any>(url,{
      params:{
        detail:data
      }
    })
    .pipe(
      tap(resp => true),
      catchError(err => of(err.error.msg))
    );
  }
  UsrxHistory(data:any){
    const url = `${this.operative}/service-ssp/historyxusr`;

    return this.http.get<any>(url,{
      params:{
        detail:data
      }
    })
    .pipe(
      tap(resp => true),
      catchError(err => of(err.error.msg))
    );
  }
 
  // ------------------- AGENT-REPORTS ---------------------  
  Agent_repo(data:any,fole:any){
    const url = `${this.operative}/service-ssp/agent`;

    return this.http.post<any>(url,data,{
      params:{
        client:fole
      }
    })
    .pipe(
      map(resp => resp),
      catchError(err => of(err.error.msg))
    );
  }

  // ------------------- Analitca de uso en la plataforma ---------------------
  // >> Atencion de reportes
  // >> Tiempo de Interacción
  Calculate(time?:any){

    const url = `${this.operative}/analytics/ssc`;

    return this.http.post<any>(url,{
      params:{
        repo:true
        // time
      }
    })
    .pipe(
      map(resp => resp),
      catchError(err => of(err.error.msg))
    );
  }
  
  


}