<app-content-header [contentHeader]="contentHeader"></app-content-header>
<div id="user-profile">


    <!-- profile info section -->
    <section id="profile-info">
        <div class="row">
            <!-- left profile info section -->
            <div class="col-lg-3 col-12 order-1 order-lg-1">

                <div class="card">
                    <div class="card-body">

                        <div>

                            <div class="badge badge-pill badge-light-dark" *ngIf="report?.status_report === 'recibido' ">
                                {{ report.status_report }}
                            </div>
                            <div class="descStatus" *ngIf="report?.status_report === 'recibido' ">
                                <span>Su reporte ha sido recibido y registrado con éxito.</span>
                            </div>

                            <div class="badge badge-pill badge-info" *ngIf="report?.status_report === 'verificado' ">
                                {{ report.status_report }}
                            </div>
                            <div class="descStatus" *ngIf="report?.status_report === 'verificado' ">
                                <span>Su reporte ya ha sido verificado por el área correspondiente y en breve se
                                    comunicarán con usted.</span>
                            </div>

                            <div class="badge badge-pill badge-warning" *ngIf="report?.status_report === 'programado' ">
                                {{ report.status_report }}
                            </div>
                            <div class="descStatus" *ngIf="report?.status_report === 'programado' ">
                                <span>Su reporte ya ha sido programado para su atención.</span>
                            </div>

                            <div class="badge badge-pill badge-light-warning" *ngIf="report?.status_report === 'pendiente' ">
                                {{ report.status_report }}
                            </div>
                            <div class="descStatus" *ngIf="report?.status_report === 'pendiente' ">
                                <span>Su reporte se encuentra pendiente.</span>
                            </div>

                            <div class="badge badge-pill badge-success" *ngIf="report?.status_report === 'atendido' ">
                                {{ report.status_report }}
                            </div>
                            <div class="descStatus" *ngIf="report?.status_report === 'atendido' ">
                                <span>Su reporte ya fue atendido con éxito.</span>
                            </div>

                            <div class="badge badge-pill badge-danger" *ngIf="report?.status_report === 'cerrado' ">
                                {{ report.status_report }}
                            </div>
                            <div class="descStatus" *ngIf="report?.status_report === 'cerrado' ">
                                <span>Su reporte ha sido cerrado debido a errores al momento de su generación.</span>
                            </div>

                            <div class="badge badge-pill badge-secondary" *ngIf="report?.status_report === 'similar' ">
                                {{ report.status_report }}
                            </div>
                            <div class="descStatus" *ngIf="report?.status_report === 'similar' ">
                                <span>Su reporte ha sido recibido y registrado con éxito.</span>
                            </div>
                            <hr>
                            <br>
                            <ul class="timeline">
                                <li class="timeline-item">
                                    <span class="timeline-point timeline-point-indicator"></span>
                                    <div class="timeline-event">
                                        <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                            <h4><img class="reportiCON2" src="../../../assets/images/iconsapp/P-BAC.png" alt="" *ngIf="report.report_area === 'Baches' ">
                                                <img class="reportiCON2" src="../../../assets/images/iconsapp/P-BAS.png" alt="" *ngIf="report.report_area === 'Señalizaciones' ">
                                                <img class="reportiCON2" src="../../../assets/images/iconsapp/P-EME.png" alt="" *ngIf="report.report_area === 'Emergencias' ">
                                                <img class="reportiCON2" src="../../../assets/images/iconsapp/P-ILU.png" alt="" *ngIf="report.report_area === 'Iluminación' ">
                                                <img class="reportiCON2" src="../../../assets/images/iconsapp/P-JAR.png" alt="" *ngIf="report.report_area === 'Parques y jadínes' ">
                                                <img class="reportiCON2" src="../../../assets/images/iconsapp/P-OBS.png" alt="" *ngIf="report.report_area === 'Obstrucciones vía pública' ">
                                                <img class="reportiCON2" src="../../../assets/images/iconsapp/P-SIG.png" alt="" *ngIf="report.report_area === 'Basura' ">
                                                <img class="reportiCON2" src="../../../assets/images/iconsapp/P-TOP.png" alt="" *ngIf="report.report_area === 'Topes' "> {{ report.report_area }}
                                            </h4>
                                            <span class="timeline-event-time" style="font-weight: 800">Area</span>
                                        </div>
                                    </div>
                                </li>
                                <li class="timeline-item">
                                    <span class="timeline-point timeline-point-prymary timeline-point-indicator"></span>
                                    <div class="timeline-event">
                                        <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                            <h6 style="text-transform: uppercase;">{{ report.report_type }}</h6>
                                            <span class="timeline-event-time" style="font-weight: 800">Tipo</span>
                                        </div>

                                    </div>
                                </li>
                            </ul>


                        </div>


                    </div>
                </div>
                <!-- about -->
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-start align-items-center mb-1">
                            <!-- avatar -->
                            <div class="avatar mr-1">
                                <img src="../../../../assets/images/portrait/small/avatar-s-11.png" alt="avatar img" height="50" width="50" />
                            </div>
                            <!--/ avatar -->
                            <div class="profile-user-info">
                                <h6 *ngIf="report.usrxgene != 'ednalorenaocanajimenez@gmail.com' && report.usrxgene != 'serviciosalumbrado@gmail.com'" class="mb-0">{{report.usrxorigin}}</h6>

                                <h6 style="font-weight: bolder;" *ngIf="report.usrxgene == 'ednalorenaocanajimenez@gmail.com'">VENTANILLA</h6>
                                <h6 style="font-weight: bolder;" *ngIf="report.usrxgene == 'elizabeth1012b@gmail.com'">VENTANILLA 2</h6>
                                <h6 style="font-weight: bolder;" *ngIf="report.usrxgene == 'serviciosalumbrado@gmail.com'">REZAGADOS</h6>

                            </div>
                        </div>



                        <div class="mt-2" *ngIf="report.usrxgene != 'ednalorenaocanajimenez@gmail.com' && report.usrxgene != 'serviciosalumbrado@gmail.com'">
                            <h5 class="mb-50">Teléfono:</h5>
                            <p class="card-text mb-0"> <a href="tel:{{phone}}"><i size="18" data-feather="phone"></i> {{phone}}</a> </p>
                        </div>

                        <div class="mt-2" *ngIf="report.usrxgene != 'ednalorenaocanajimenez@gmail.com' && report.usrxgene != 'serviciosalumbrado@gmail.com'">
                            <h5 class="mb-75">Email:</h5>
                            <p class="card-text"><a><i size="18" data-feather="mail"></i> {{report.usrxgene}}</a> </p>
                        </div>
                    </div>
                </div>
                <!--/ about -->




            </div>
            <!--/ left profile info section -->

            <!-- center profile info section -->
            <div class="col-lg-5 col-12 order-2 order-lg-2">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-start align-items-center mb-1">
                            <!-- avatar -->

                            <!--/ avatar -->
                            <div class="profile-user-info">
                                <h6 class="mb-0">{{ report.report_id }}</h6>
                                <small style="font-weight: 800; 
                                    font-size: 12px;
                                    line-height: 3;
                                "> <i style="margin-bottom: 5px;" size="18" data-feather="calendar"></i> {{
                                    report.report_date | date:'medium' }}</small>
                            </div>
                        </div>
                        <p class="card-text">
                            {{ report.report_info }}
                        </p>
                        <!-- post img -->
                        <div style="display: flex; ">
                            <img class="img-fluid rounded mb-75" src="{{ report.report_img }}" alt="avatar img" />
                        </div>

                        <br>
                        <textarea style="display:none;" #clipboardValue cols="30" rows="10" value='Ubicación: {{ubicacion}}{{"\n"}}Folio: {{report.report_id}}{{"\n"}}Area: {{report.report_area}}{{"\n"}}Tipo: {{report.report_type}}{{"\n"}}Descripción: {{report.report_info}}{{"\n"}}Foto: {{report.report_img}}{{"\n"}}'></textarea>
                        <button class="btn btn-outline-primary" (click)="copyCode(clipboardValue.value)" rippleEffect><i data-feather="copy" class="avatar-icon font-medium-3"></i>  Copiar</button>


                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-12 order-3 order-lg-3">
                <div class="card text-center">
                    <div class="card-header">Ubicación</div>
                    <div class="card-body">
                        <h4 class="card-title" *ngIf="report.usrxgene != 'ednalorenaocanajimenez@gmail.com' && report.usrxgene != 'serviciosalumbrado@gmail.com'">Referencias proporcionadas por {{report.usrxorigin}}.</h4>
                        <h4 class="card-title" *ngIf="report.usrxgene == 'ednalorenaocanajimenez@gmail.com'">Referencias proporcionadas por VENTANILLA.</h4>
                        <h4 class="card-title" *ngIf="report.usrxgene == 'elizabeth1012b@gmail.com'">Referencias proporcionadas por VENTANILLA2.</h4>
                        <h4 class="card-title" *ngIf="report.usrxgene == 'serviciosalumbrado@gmail.com'">Referencias proporcionadas por REZAGADOS.</h4>
                        <p class="card-text">{{report.reference}}</p>
                        <a class="btn btn-outline-primary" rippleEffect href="{{ubicacion}}" target="_blank"><span [data-feather]="'map-pin'" [class]="'mr-25'"></span> Abrir en Google Maps</a>
                        <br>
                        <br>
                        <a class="btn btn-outline-primary" rippleEffect target="_blank" (click)="copy(ubicacion)"><span [data-feather]="'copy'" [class]="'mr-25'"></span> Copiar Ubicación</a>



                    </div>
                    <div class="card-footer text-muted">Latitud: {{report.usrxgeoloc.lat}} | Longitud: {{report.usrxgeoloc.lng}}
                    </div>
                </div>

                <div class="card  mb-3">
                    <div class="card-body">
                        <h4 class="card-title text-center">Cambiar Status</h4>
                        <form #formReport="ngForm" (ngSubmit)="onSubmit()">
                            <div class="columns">




                                <div class="column">


                                    <div class="field">
                                        <label class="label">Status</label>
                                        <div class="form-group">
                                            <!-- Basic Select with Search -->
                                            <ng-select name="status" [(ngModel)]="report.status_report" [items]="statusOp" bindLabel="nstatusame"> </ng-select>
                                            <!--/ Basic Select with Search -->
                                        </div>

                                        <!-- <div class="select">
                                            <select #status="ngModel" name="status" [(ngModel)]="report.status_report">
                                                <option></option>
                                                <option id='1'>recibido</option>
                                                <option id='2'>verificado</option>
                                                <option id='3'>programado</option>
                                                <option id='4'>pendiente</option>
                                                <option id='5'>atendido</option>
                                                <option id='6'>cerrado</option>
                                                <option id='6'>similar</option>

                                            </select>
                                        </div> -->





                                    </div>


                                    <input class="btn btn-outline-primary text-center" type="submit" value="ACTUALIZAR" [disabled]="!formReport.form.valid">
                                </div>

                                <!-- <div *ngIf="errorMessage" class="notification is-danger is-light">
                                    <button class="delete"></button> {{errorMessage}}
                                </div> -->

                            </div>
                        </form>

                    </div>
                </div>
            </div>


        </div>

        <!--/ reload button -->
    </section>
    <!--/ profile info section -->
</div>

<!-- 
<div class="app-content content  " *ngIf="identity && identity.role == 'ROLE_ADMIN'"> -->



<!--
<section class="container" style="margin-top: 2rem;" *ngIf="identity && identity.role == 'ROLE_ADMIN'">
    <a (click)="backPage()" class="mb-5" style="display: block; font-size:.9rem; color: #9e9e9e; font-weight: 600;"><i
      class="fas fa-chevron-left" style="color: #9e9e9e; font-size: .8rem;"></i>&nbsp; VOLVER</a>

    <div>
        <div class="content">
            <section *ngIf='report'>
                <li>
                    folio: {{report.report_id}} area: {{report.report_area}} tipo: {{report.report_type}} referencia_ {{report.reference}} mas detalles: {{report.report_info}} fecha reporte: {{report.report_date | date:'d/M/yy, h:mm a' }} usuario: {{report.usrxgene}} status:
                    {{report.status_report}}
                </li>
                <img src="{{report.report_img}}" alt="reporte">
            </section>
            <form #formReport="ngForm" (ngSubmit)="onSubmit()">
                <div class="columns">




                    <div class="column">


                        <div class="field">
                            <label class="label">Paraclínicos</label>



                            <div class="select">
                                <select #status="ngModel" name="status" [(ngModel)]="report.status_report">
                  <option></option>
                  <option id='1'>Recibido</option>
                  <option id='2'>Verificado</option>
                  <option id='3'>Programado</option>
                  <option id='4'>Pendiente</option>
                  <option id='5'>Atendido</option>
                  <option id='6'>Cerrado</option>

                </select>
                            </div>



                            <span class="alertForm" *ngIf="!status.valid && status.touched">
                El Estatus es obligatorio
              </span>

                        </div>


                        <input class="button is-success" type="submit" value="{{titulo}}" [disabled]="!formReport.form.valid">
                    </div>

                    <div *ngIf="errorMessage" class="notification is-danger is-light">
                        <button class="delete"></button> {{errorMessage}}
                    </div>

                </div>
            </form>



        </div>




    </div>


</section> -->