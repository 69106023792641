
import { Report } from 'app/models/tzxcityreports';
import { ReportService } from 'app/services/report.service';
import { AdminService } from 'app/services/admin.service';
import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { environment } from 'environments/environment';
import * as XLSX from 'xlsx';
import { Subject } from 'rxjs';
import { CoreConfigService } from '@core/services/config.service';
import { takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { GlobalConfig, ToastrService } from 'ngx-toastr';
import { CustomToastrComponent } from 'app/main/extensions/toastr/custom-toastr/custom-toastr.component';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexStroke, ApexTooltip } from 'ng-apexcharts';


import { Router, ActivatedRoute, Params, ActivatedRouteSnapshot } from '@angular/router';
@Component({
  selector: 'app-home',
  templateUrl: './reportdetail.component.html',
  styleUrls: ['./reportdetail.component.scss'],
  providers: [AdminService, ReportService],
   encapsulation: ViewEncapsulation.None
})

export class ReportDetailComponent implements OnInit {
 
    statusOp = [
     'recibido',
      'verificado',
       'programado' ,
         'pendiente' ,
        'atendido' ,
       'cerrado' ,
       'similar' ,

    ];
    public copyCodeStatus: boolean = false;
    private options: GlobalConfig;
    public titulo: string;
    public report: Report;
    public identity;
    public token;
    private baseUrl: string = environment.baseUrl;
    public errorMessage;
    public is_edit;
    public ubicacion;
    public phone;
    public contentHeader: object;
    constructor(
      private _route: ActivatedRoute,
      private _router: Router,
      private _adminService: AdminService,
      private _reportService: ReportService,
      private toastr: ToastrService
    ) {
        this.options = this.toastr.toastrConfig;
      this.titulo = 'Editar ';
      this.identity = this._adminService.getIdentity();
      this.token = this._adminService.getToken();
  
      this.baseUrl = environment.baseUrl;
      this.report = new Report('','','','','','',null,'','',null,null,null,'','',null);
      this.is_edit = true;
   
    }

   
    ngOnInit() {
  
        
      //lamar al metodo de la api para sacar un artista en base a su id getEmployee
      this.getReport();

      this.contentHeader = {
        headerTitle: 'TizAPP',
        actionButton: true,
        breadcrumb: {
          type: '',
          links: [
            {
              name: 'Todos los reportes',
              isLink: true,
              link: '/reportes'
            },
            {
              name: 'Reporte',
              isLink: false,
            },
           
          ]
        }
      }
  
    }

    copyCode(inputTextValue) {
        const selectBox = document.createElement('textarea');
        selectBox.style.position = 'fixed';
        selectBox.value = inputTextValue;
        document.body.appendChild(selectBox);
        selectBox.focus();
        selectBox.select();
        document.execCommand('copy');
        document.body.removeChild(selectBox);
    
        this.toastr.success(inputTextValue, 'Copiado correctamente!' , {
          progressBar: true,
          toastClass: 'toast ngx-toastr',
          closeButton: true
        });
      }
    copy(value) {
        const selectBox = document.createElement('textarea');
        selectBox.style.position = 'fixed';
        selectBox.value = value;
        document.body.appendChild(selectBox);
        selectBox.focus();
        selectBox.select();
        document.execCommand('copy');
        document.body.removeChild(selectBox);
        setTimeout(() => {
          this.copyCodeStatus = false;
        }, 500);
        this.copyCodeStatus = true;
    
        this.toastr.success( value,'Ubicación copiada correctamente!', {
          progressBar: true,
          toastClass: 'toast ngx-toastr',
          closeButton: true
        });
      }
  
    getReport() {
  
      this._route.params.forEach((params: Params) => {
        let id = params['uid'];
  
        this._reportService.getReport(this.token, id).subscribe(
            (response) => {
              this.report = response.tzxcityreports;
  
              if (!response.tzxcityreports) {
                this.errorMessage = 'error en el servidor';
                this._router.navigate(['/']);
              } else {
                this.errorMessage = 'El reporte se ha obtenido correctamente';
                this.report = response.tzxcityreports;
               this.phone = response.phone;
                const ub1 = 'https://www.google.com.mx/maps/place/'
                const ub2 = ','
                this.ubicacion = ub1 + this.report.usrxgeoloc.lat + ub2 + this.report.usrxgeoloc.lng;
                //console.log(this.ubicacion)
              }
            },
            (error) => {
              var errorMessage = <any>error;
  
              return errorMessage;
            }
        );
      });
    }
  
    borrar() {
      (document.querySelectorAll('.notification .delete') || []).forEach(
        ($delete) => {
          const $notification = $delete.parentNode;
  
          $notification.parentNode.removeChild($notification);
        }
      );
    }
  
    onSubmit() {
  
      this._route.params.forEach((params: Params) => {
        let id = params['uid'];
  
        this._reportService
          .editReport(this.token, id, this.report)
          .subscribe(
  
            (response) => {
  
  
              if (!response.report) {
                this.errorMessage = 'error en el servidor';
                this.toastr.warning( 'Ha sucedido un error, intenta recargar la página.','Error', {
                    progressBar: true,
                    toastClass: 'toast ngx-toastr',
                    closeButton: true
                  });
              } else {
                this.errorMessage = 'El reporte se ha actualizado correctamente';
                // this.employee = response.employee;
                this.toastr.success( 'El reporte se ha actualizado correctamente!', 'Actualizado', {
                    progressBar: true,
                    toastClass: 'toast ngx-toastr',
                    closeButton: true
                  });
  
              }
  
            },
            (error) => {
              var errorMessage = <any>error;
              if (errorMessage != null) {
                this.errorMessage = error.error.message;
              }
            }
          );
      });
    }
    backPage(){
      window.history.back();
    }
  }
  
   
  


  

