export const environment = {
  production: true,
  // baseUrl:   'http://localhost:80/api/',
  // baze:        'http://localhost:80/dashboard',
  // notify:   'http://localhost:5000/tizapp-28280/us-central1/api',  
    baseUrl: 'https://tizaondevtesting.live/api/',
    baze:        'https://tizaondevtesting.live/dashboard',
    notify:   'https://us-central1-tizapp-28280.cloudfunctions.net/api',
    live: 'https://us-central1-tizapp-28280.cloudfunctions.net/api',
};

