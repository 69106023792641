
import { Observable } from "rxjs";


import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { NgbDateStruct, NgbCalendar, NgbDate, NgbDateParserFormatter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';

import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { DomSanitizer } from '@angular/platform-browser';
import { ConectionService } from 'app/services/conection.service';


 import LocationPicker from "location-picker";
 import { GlobalConfig, ToastrService } from 'ngx-toastr';

// import {
//   I18n,
//   CustomDatepickerI18n
// } from 'app/main/forms/form-elements/date-time-picker/date-picker-i18n/date-picker-i18n.service';


@Component({
  selector: "app-add-new",
  templateUrl: "./add-new.component.html",
  styleUrls: ["./add-new.component.scss"],
  encapsulation: ViewEncapsulation.None
  // providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }]
})



export class AddNewComponent implements OnInit {

  snowEditorRef = `
  <h1 class="ql-align-center">Quill Rich Text Editor</h1>
  <p class="card-text"><br /></p>
  <p class="card-text">
    Quill is a free, <a href="https://github.com/quilljs/quill/">open source</a> WYSIWYG editor built
    for the modern web. With its
    <a href="http://quilljs.com/docs/modules/">modular architecture</a> and expressive
    <a href="http://quilljs.com/docs/api/">API</a>, it is completely customizable to fit any need.
  </p>
  <p class="card-text"><br /></p>
  <iframe
    class="ql-video ql-align-center"
    src="https://www.youtube.com/embed/QHH3iSeDBLo?showinfo=0"
    width="560"
    height="238"
  ></iframe>
  <p class="card-text"><br /></p>
  <p class="card-text"><br /></p>
  <h2 class="ql-align-center">Getting Started is Easy</h2>
  <p class="card-text"><br /></p>
  <pre>
// &lt;link href="https://cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet"&gt;
// &lt;script src="https://cdn.quilljs.com/1.3.6/quill.min.js" type="text/javascript"&gt;&lt;/script&gt;
var quill = new Quill('#editor', {
modules: {
toolbar: '#toolbar'
},
theme: 'snow'
});
// Open your browser's developer console to try out the API!</pre
  >
  <p class="card-text"><br /></p>
  <p class="card-text"><br /></p>
  <p class="card-text ql-align-center"><strong>Built with</strong></p>
  <p class="card-text ql-align-center">
    &nbsp;<span class="ql-formula" data-value="x^2 + (y - \sqrt[3]{x^2})^2 = 1"></span>&nbsp;
  </p>
  <p class="card-text"><br /></p>
  `;

  public lati;
  public long;
  public coordenadas;
  private options: GlobalConfig;
  setLocation() {
   
    this.lati = this.lp.getMarkerPosition().lat;
   this.long = this.lp.getMarkerPosition().lng;

 //console.log(this.lp)
    this.coordenadas = this.lati + ',' + this.long;
   //console.log(this.coordenadas)
    
 }

  file: any;
  item:any;
  fecha = this.datePipe.transform(Date.now(),'MMM');
  Today = Date.now();
  
  

  constructor(
    private fb: FormBuilder,
   private datePipe: DatePipe, 
   private conn:ConectionService,
   private sanitize:DomSanitizer,private toastr: ToastrService)
  {
    this.options = this.toastr.toastrConfig;

 }
 

  public catego;

  public loading:boolean = false;
  lp: LocationPicker;

  // Basic Date Picker
  public basicDPdata: NgbDateStruct;

  // Select Multi with Icon
  public multiIconGithubUsers: Observable<any[]>;
  public multiIconGithubUsersSelected = ["anjmao"];

  public titulo_evento;
  public descripcion_evento;
  public LugarEvento;
  public imagen_evento;
  public DuracionEvento;
  public EnlaceBoton;
  public NombreBoton;

  public Organizador;

  

  public i18nDPdata: NgbDateStruct;
  
  public contentHeader: object
  // Select Multi Custom
  public multiCustomGithubUsersSelected;


  public meridianTP = true;

  public SelectedDate 

  
  async Onchange(e:any){
    if (e.target.files) {
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (e: any) => {
        this.url = e.target.result;
      };
    } else {
    }
    this.file = e.target.files[0];
    await this.conn.Toolformat64(this.file).then(resp =>{
            
      this.item = resp;      
    });
  }


   GionaleForm:FormGroup = this.fb.group({

    titulo_noticia:['',[Validators.required,Validators.minLength(3)]],
    descripcion_breve:['',[Validators.required,Validators.minLength(8)]],
    fecha:[this.Today,[Validators.required]],
    xpiration:['',[Validators.required]],
    autor:['',[Validators.required,Validators.minLength(3)]],
    portada:['',[Validators.required,Validators.minLength(2)]],
    content:['',[Validators.required]],
    goto:['',[Validators.minLength(6)]],
    gotoName:['',[Validators.required]]
  });
  
  Newsender(){
    try{
      
      this.GionaleForm.get('portada')?.setValue(this.item);
      
      const { titulo_noticia,descripcion_breve } = this.GionaleForm.value;
      const notfy = {
        titulo_noticia:titulo_noticia,
        descripcion_breve:descripcion_breve
      };

      if(this.GionaleForm.valid){
        this.loading  = true
        
        this.conn.Nws(this.GionaleForm.value).subscribe(resp=>{
          
          if(resp){
  
            this.GionaleForm.reset();
            
            //this.conn.pushNws(notfy).subscribe();        
            this.loading = false;
            this.toastr.success( 'Agregada','Noticia publicada correctamente', {
              progressBar: true,
              toastClass: 'toast ngx-toastr',
              closeButton: true
            });
          }        
          else{
            this.loading = true;
            alert('Error comprueba tu publicación | contacta al administrador')
          }
        });
      }
      else{
        alert('Porfavor Completa el formulario');
      }
      
    }
    catch(err){
      this.loading = false;
      //console.log(err)
    }    
  }

  ngOnInit() {


    this.contentHeader = {
      headerTitle: 'TizAPP',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Inicio',
            isLink: true,
            link: '/'
          },
          {
            name: 'Agregar Noticia',
            isLink: false
          }
        ]
      }
    }

   this.lp = new LocationPicker('map');

    
  }
  




  url = "../../../../assets/images/picture-default.jpg";

  onFileSelected(event) {
    if (event.target.files) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event: any) => {
        this.url = event.target.result;
      };
    } else {
    }
  }
}
