export class Report{
  constructor(

    public _id: string,
    public report_id: string,
    public report_area: string,
    public report_type: string,
    public reference: string,
    public report_info: string,
    public report_date: Date,
    public usrxgene: string,
    public report_img: string,
    public report_user: {
      namextz: {
        type:string,
        required:[true,'Se Requiere de un Nombre'],
    },
    usrxtz: {
        type:string,
        required:[true,'Se Requiere de un Usuario'],
        unique: true,
    },
    curpxtz: {
        type:string,
        required:[true,'Se Requiere de un Usuario'],                
        default:'xxxx'
    },
    passxtz: {
        type:string,
        required:[true,'Se Requiere de una Contraseña']

    }, 
    passconfirm: {
        type:string,
        required:[true,'Se Requiere de una Contraseña']

    },
    phonextz:{
        type:string,
        require:[true,'Se requiere de un Numero']
    },
    compleannoxtz: {
        type:string,
        required: [true,'Se Requiere de una Fecha']
    },
    generextz: {
        type:string,
        required:[true,'Se Requiere de un Valor']
    },
    roluoxtz:{    
        type: string,
        required:true,
        default: 'USER_ROLE'
    },
    active_statsxtz: {   
        type:Boolean,
        required:true,
        default:true
    },
    termsxtz:{
        type:Boolean,
        required:true,
        default:true
    },
    contactxone:{ 
        type:string,
        require:[true,'Se requiere de un numero'],
        default:'contac1'
    },
    contactxtwo:{
        type:string,
        require:[true,'Se requiere de un numero'],
        default:'contac2'
    },
    contactxthree:{ 
        type:string,
        default:'contac3'
    },
    avatar_img:{
        type:string
    },
    creato:{
        type:string,
        required: [true,'Se Requiere de una Fecha']
    }
    },
    public usrxgeoloc: {
        lat: {
            type: number,
            required: [true, 'Se Requiere de un Valor lt'],
        },
        lng: {
            type: number,
            required: [true, 'Se Requiere de un Valor lg'],
        }
    },
    public  status_report: {
        type: FunctionStringCallback,
        require: [true, 'Se requiere de un valor'],
        default: 'recibido'
    },
    public usrxorigin: string,
    public xcontact: string,
    public phone: number,
  ){}
}
