
import { Observable } from "rxjs";


import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';

import { NgbDateStruct, NgbCalendar, NgbDate, NgbDateParserFormatter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';

import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { DomSanitizer } from '@angular/platform-browser';
import { ConectionService } from 'app/services/conection.service';

import { GlobalConfig, ToastrService } from 'ngx-toastr';
 import LocationPicker from "location-picker";


// import {
//   I18n,
//   CustomDatepickerI18n
// } from 'app/main/forms/form-elements/date-time-picker/date-picker-i18n/date-picker-i18n.service';


@Component({
  selector: "app-add-event",
  templateUrl: "./add-event.component.html",
  styleUrls: ["./add-event.component.scss"],
  encapsulation: ViewEncapsulation.None
  // providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }]
})



export class AddEventComponent implements OnInit {

  public lati;
  public long;
  public coordenadas;
  private options: GlobalConfig;
  setLocation() {
   
    this.lati = this.lp.getMarkerPosition().lat;
   this.long = this.lp.getMarkerPosition().lng;

 //console.log(this.lp)
    this.coordenadas = this.lati + ',' + this.long;
   //console.log(this.coordenadas)
    
 }

  file: any;
  item:any;

 fecha = this.datePipe.transform(Date.now(),'MMM');
Today = Date.now();

  constructor(
    private fb: FormBuilder,
   private datePipe: DatePipe, 
   private conn:ConectionService,
   private sanitize:DomSanitizer,
   private toastr: ToastrService)
  {
    this.options = this.toastr.toastrConfig;

 }
  public catego;

  public loading:boolean = false;
  lp: LocationPicker;

  // Basic Date Picker
  public basicDPdata: NgbDateStruct;

  // Select Multi with Icon
  public multiIconGithubUsers: Observable<any[]>;
  public multiIconGithubUsersSelected = ["anjmao"];

  public titulo_evento;
  public descripcion_evento;
  public LugarEvento;
  public imagen_evento;
  public DuracionEvento;
  public EnlaceBoton;
  public NombreBoton;

  public Organizador;


  

  public i18nDPdata: NgbDateStruct;
  
  public contentHeader: object
  // Select Multi Custom
  public multiCustomGithubUsersSelected;


  public meridianTP = true;

  public SelectedDate 

  
  async Onchange(e:any){
    if (e.target.files) {
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (e: any) => {
        this.url = e.target.result;
      };
    } else {
    }
    this.file = e.target.files[0];
    await this.conn.Toolformat64(this.file).then(resp =>{
            
      this.item = resp;      
    });
  }
  EventForm:FormGroup = this.fb.group({

    titulo_evento:['',[Validators.required,Validators.minLength(3)]],
    fecha:[this.Today,[Validators.required]],
    xpiration:['',[Validators.required]],
    xaction:['',[Validators.required]],
    duracion_evento:[''],
    hora_evento:[''],
    descripcion_evento:['',[Validators.required]],
    imagen_evento:['',[Validators.required]],
    lugar_evento:['',[Validators.required]],
    organizador:['',[Validators.required]],
    nombre_boton:['',[Validators.required]],
    enlace_boton:['',[Validators.required]],
    lp:['',[Validators.required]],
    lat:[''],
    lng:[''],
    categorias:['',[Validators.required,Validators.minLength(4)]]        
  });

  Eventsender(){
    
    try{
      
      this.EventForm.get('imagen_evento')?.setValue(this.item);

      const {titulo_evento,descripcion_evento } = this.EventForm.value;
      const notfy = {
        titulo_evento:titulo_evento,
        descripcion_breve:descripcion_evento
      }; 

      if(this.EventForm.valid){        
        this.loading  = true;
        
        this.conn.Evnt(this.EventForm.value).subscribe(resp=>{
  
          if(resp){
            
            this.EventForm.reset();
            
            //this.conn.pushEvent(notfy).subscribe();        
  
            this.loading = false;
            this.toastr.success( 'Agregado','Evento Agregado Correctamente!', {
              progressBar: true,
              toastClass: 'toast ngx-toastr',
              closeButton: true
            });
          }
          else{
            this.loading = true;
            alert('Error comprueba tu publicación | contacta al administrador')
          }
          
        });
      }
      else{
        alert('Porfavor Completa el formulario');
      }
      
    }
    catch(err){
      this.loading = false;
      //console.log(err)
    }    
  }

  ngOnInit() {


    this.contentHeader = {
      headerTitle: 'TizAPP',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Inicio',
            isLink: true,
            link: '/'
          },
          {
            name: 'Panel',
            isLink: false
          }
        ]
      }
    }

   this.lp = new LocationPicker('map');

    
  }
  




  url = "../../../../assets/images/picture-default.jpg";

  onFileSelected(event) {
    if (event.target.files) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event: any) => {
        this.url = event.target.result;
      };
    } else {
    }
  }
}
