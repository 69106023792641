import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConectionService {
  private baze: string = environment.baze; 
  private fire: string = environment.notify; 
  item:any;
 
 
  constructor(private http:HttpClient) { }

  Alrt(datos:FormData){

    // console.log("FROM:",datos);
    const url = `${this.baze}/alerts/up`;    
    return this.http.post(url,datos);
  }
  Evnt(datos:FormData){
    const url = `${this.baze}/events/up`;    
    return this.http.post(url,datos);
  }
  Nws(datos:FormData){    
    const url = `${this.baze}/news/up`;    
    return this.http.post(url,datos);
  }
  Bannr(datos:FormData){
    //console.log("FROM:",datos);
    const url = `${this.baze}/banners/up`;    
    return this.http.post(url,datos);
  }


  // Optional / Default
  pushAlert(data:any){
    const url = `${this.fire}/alerts`;    
    return this.http.post(url,data);
  }
  pushEvent(data:any){
    const url = `${this.fire}/event`;    
    return this.http.post(url,data); 
  }
  pushNws(data:any){
    const url = `${this.fire}/notice`;    
    return this.http.post(url,data); 
  }


  async Toolformat64(event:any){     
    // const unsafeIMG = window.URL.createObjectURL($event);
    // const image = this.sanitize.bypassSecurityTrustUrl(unsafeIMG);
    const proccess = await new Promise((resolve,reject)=>{

      const render = new FileReader();

      // Manejo de error si no llega img
      render.readAsDataURL(event);
  
      render.onload = () =>{     
        
        resolve({
          base:render.result
        })
      };
      render.onerror = () =>{
        reject({
          base: null
        })
      };
      
    }).then(e => e);
    return proccess;
  }
}
