import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  {
    id: 'home',
    title: 'INICIO',
    
    type: 'item',
    icon: 'home',
    url: 'inicio'
  },
 
  {
    id: "tools-section",
    type: "section",
    title: "HERRAMIENTAS",
    children: [
      {
        id: "Reportes",
        title: "Reportes ciudadanía",
        type: "item",
        icon: "tool",
        url: "reportes",
      },
       {
         id: "events",
         title: "SSP",
         type: "collapsible",
         icon: "alert-octagon",
         children: [
           {
             id: "eventsView",
             title: "Mapa Tiempo Real",
             type: "item",
             icon: "map-pin",
             url: "ssp",
           
           },
           {
             id: "eventsAdd",
             title: "Lista activaciones",
             type: "item",
             icon: "list",
             url: "ssp-list",
          
           },
         ],
       },
       {
         id: "stats",
         title: "Estadísticas",
         type: "item",
         icon: "activity",
         url: "",
       },
    ],
  },
   {
     id: "comunicacion",
     type: "section",
     title: "COMUNICACIÓN",
     role: ['Role_User'],
     children: [
       {
         id: "events",
         title: "Eventos",
         type: "collapsible",
         icon: "calendar",
         children: [
            {
              id: "eventsView",
              title: "Eventos",
              type: "item",
              icon: "list",
              url: "eventos/lista",
           
            },
           {
             id: "eventsAdd",
             title: "Agregar Evento",
             type: "item",
             icon: "plus-square",
             url: "agregar-evento",
          
           },
         ],
       },
       {
         id: "news",
         title: "Noticias",
         type: "collapsible",
         icon: "trello",
         children: [
            {
              id: "newsView",
              title: "Noticias",
              type: "item",
              icon: "list",
              url: "email",
          
            },
           {
             id: "newsAdd",
             title: "Agregar Noticia",
             type: "item",
             icon: "plus-square",
             url: "agregar-noticia",
           
           },
         ],
       },
       {
         id: "alerts",
         title: "Alertas",
         type: "collapsible",
         icon: "bell",
         children: [
            {
              id: "alertView",
              title: "Alertas",
              type: "item",
              icon: "list",
              url: "email",
          
            },
           {
             id: "alertAdd",
             title: "Envíar Notificación",
             type: "item",
             icon: "plus-square",
             url: "agregar-alerta",
           
           },
         ],
       },
       {
         id: "banners",
         title: "Banners",
         type: "item",
         icon: "image",
         url: "editar-banners",
       },
     ],
   }
 
]
