

import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnMode, DatatableComponent,  } from '@swimlane/ngx-datatable';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { locale as en } from './i18n/en'
import { locale as fr } from './i18n/fr'
import { locale as de } from './i18n/de'
import { locale as pt } from './i18n/pt'
import { SafeStylePipe } from './safe-styles.pipe'; 
import { map } from 'rxjs/operators';
import { Router, ActivatedRoute, Params, ActivatedRouteSnapshot } from '@angular/router';
import { Report } from 'app/models/tzxcityreports';
import { ReportService } from 'app/services/report.service';
import { AdminService } from 'app/services/admin.service';
import { CoreTranslationService } from '@core/services/translation.service'
import { environment } from 'environments/environment';
import * as XLSX from 'xlsx';
import { Subject, fromEvent } from 'rxjs';
import { CoreConfigService } from '@core/services/config.service';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { GlobalConfig, ToastrService } from 'ngx-toastr';
import { CustomToastrComponent } from 'app/main/extensions/toastr/custom-toastr/custom-toastr.component';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexStroke, ApexTooltip } from 'ng-apexcharts';
import { HttpClient } from '@angular/common/http';
import { colors } from 'app/colors.const';
import { DomSanitizer } from '@angular/platform-browser';
import { Chart } from 'chart.js';

export interface ChartOptions {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  stroke: ApexStroke;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  fill: ApexFill;
  colors: string[];
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  providers: [AdminService, ReportService, SafeStylePipe],
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
  currentPage: number = 2;
  //   @ViewChild('editTmpl', {static: true}) editTmpl:TemplateRef<any>;
  // @ViewChild('hdrtpl', {static: true}) hdrtpl:TemplateRef<any>;
  @ViewChild(NgbPopover, { static: true }) popover: NgbPopover;
   @Input('mode') mode:string = 'page'
  @ViewChild('imageTmpl', { static: true }) imageTmpl: TemplateRef<any>;
  @ViewChild('reference', { static: true }) reference: TemplateRef<any>;
  @ViewChild('viewTmpl', { static: true }) viewTmpl: TemplateRef<any>;
  @ViewChild('hdrTpl', { static: true }) hdrTpl: TemplateRef<any>;
  @ViewChild('statisticsBarRef') statisticsBarRef: any;
  @ViewChild('statisticsLineRef') statisticsLineRef: any;
  @ViewChild('gainedChartoptionsRef') gainedChartoptionsRef: any;
  @ViewChild('revenueChartoptionsRef') revenueChartoptionsRef: any;
  @ViewChild('salesChartoptionsRef') salesChartoptionsRef: any;
  @ViewChild('orderChartoptionsRef') orderChartoptionsRef: any;
  @ViewChild('trafficChartoptionsRef') trafficChartoptionsRef: any;
  @ViewChild('userChartoptionsRef') userChartoptionsRef: any;
  @ViewChild('newsletterChartoptionsRef') newsletterChartoptionsRef: any;



   private $barColor = '#f3f3f3';
  private $trackBgColor = '#EBEBEB';
  private $primary_light = '#A9A2F6';
  private $success_light = '#55DD92';
  private $warning_light = '#ffc085';
  private $primary = '#7367F0';
  private $success = '#28c76f';
  private $danger = '#EA5455';
  private $warning = '#FF9F43';


  public report_area;
  public report_type;
  public shownReports = new Set<string>();
  public statisticsBar;
  public statisticsLine;
  public gainedChartoptions: Partial<ChartOptions>;
  public revenueChartoptions: Partial<ChartOptions>;
  public salesChartoptions: Partial<ChartOptions>;
  public orderChartoptions: Partial<ChartOptions>;
  public trafficChartoptions: Partial<ChartOptions>;
  public userChartoptions: Partial<ChartOptions>;
  public newsletterChartoptions: Partial<ChartOptions>;
  public isMenuToggled = false;
  public totalTotal;
  public countReceived;
  public countVerified;
  public countProgrammed;
  public countPending;
  public countAttended;
  public countClosed;
  public countSimilar;
  isLoadingMore: boolean = false;
  hasMoreData: boolean = true;
  activepopUp: boolean = false;
  imagenPrevobte: string;
  rotationAngle: number = 0;
  isLastPage = false;
  loadingExcel = false;
  public contentHeader: object
  public isLoading: boolean = false;
  //public selectedOption = 30;
  public reports: Report[];
  hasFetched: boolean = false;
  reportCount: number = 0;
  public identity;
  public token;
 public baseUrl;
  public next_page;
  public prev_page;
  public confirmado;
  public errorMessage;
  public ColumnMode = ColumnMode;
  
public Recibidos; 


public st1 = 'recibido';
public st2 = 'verificado';
public st3 = 'programado';
public st4 = 'pendiente';
public st5 = 'atendido';
public st6 = 'cerrado';
public st7 = 'similar';



  public totalRecibidos;
  public totalVerificados;
  public totalProgramados;
  public totalPendientes;
  public totalAtendidos;
  public totalCerrados;
  public totalSimilares;

  public totalAreaPendientes;
 // Private
 private options: GlobalConfig;

  public data = [];
  public cols = [];


  
 

  area1 ='Iluminación';
  area2 = 'Señalizaciones';
  area3 = 'Topes';
  area4 = 'Baches';
  area5 = 'Obstrucciones vía pública';
  area6 = 'Basura';
  area7 = 'Parques y jadínes';
  area8 = 'Emergencias';
  status = ['recibido', 'pendiente', 'verificado', 'programado', 'atendido', 'cerrado', 'similar'];
  counts: any = {};



  fileName= 'reportes.xlsx';
    // decorator
    @ViewChild(DatatableComponent) table: DatatableComponent;

   

 
  // private
  private tempData = [];
  public copyCodeStatus: boolean = false;
  public totalReports;
  public totalReportsArea;
  public expanded = {};
  public timeout: any;
  public offset = 0;
  public rows;
  public url;
  public tempFilterData;
  public tempFilterDataUser;
  public previousStatusFilter = '';
  public previousUserFilter = '';
  private _unsubscribeAll: Subject<any>;


  public reportsEU;
  public reportsPJ;
  public reportsBAC;
  public reportsAL;
  public reportsRES;   
  public reportsMOV;
  public reportsPC;
  public reportsPLA;
  public reportsREGLA;
  public reportsTRANS;
  public reportsDIRSPM;

   //EQUIPAMIENTO
public recibidoEU;
public verificadoEU;
public programadoEU;
public pendienteEU;
public atendidoEU;
public cerradoEU;
public similarEU;

  //PARQUES
public recibidoPJ;
public verificadoPJ;
public programadoPJ;
public pendientePJ;
public atendidoPJ;
public cerradoPJ;
public similarPJ;
//BACHES
public recibidoBAC;
public verificadoBAC;
public programadoBAC;
public pendienteBAC;
public atendidoBAC;
public cerradoBAC;
public similarBAC;
  //ALUMBRADO
public recibidoAL;
public verificadoAL;
public programadoAL;
public pendienteAL;
public atendidoAL;
public cerradoAL;
public similarAL;

 //RESIDUOS
 public recibidoRES;
 public verificadoRES;
 public programadoRES;
 public pendienteRES;
 public atendidoRES;
 public cerradoRES;
 public similarRES;
  //MOVILIDAD
public recibidoMOV;
public verificadoMOV;
public programadoMOV;
public pendienteMOV;
public atendidoMOV;
public cerradoMOV;
public similarMOV;
 //PROTECCION CIVIL
 public recibidoPC;
 public verificadoPC;
 public programadoPC;
 public pendientePC;
 public atendidoPC;
 public cerradoPC;
 public similarPC;
  //PLANEACION
public recibidoPLA;
public verificadoPLA;
public programadoPLA;
public pendientePLA;
public atendidoPLA;
public cerradoPLA;
public similarPLA;
 //REGLAMENTOS
 public recibidoREGLA;
 public verificadoREGLA;
 public programadoREGLA;
 public pendienteREGLA;
 public atendidoREGLA;
 public cerradoREGLA;
 public similarREGLA;
  //TRANSITO
public recibidoTRANS;
public verificadoTRANS;
public programadoTRANS;
public pendienteTRANS;
public atendidoTRANS;
public cerradoTRANS;
public similarTRANS;
 
public chart = [];
  constructor(
    private sanitizer: DomSanitizer,
    private _coreTranslationService: CoreTranslationService,
    private _route: ActivatedRoute, 
    private _router: Router,
    private http: HttpClient,
    private _adminService: AdminService,
    private _reportService: ReportService,
    private _coreConfigService: CoreConfigService,
    private toastr: ToastrService
    ) {

      this.totalReports = 0;
      this.totalReportsArea = 0;
      this.options = this.toastr.toastrConfig;
      this._unsubscribeAll = new Subject();
    this._coreTranslationService.translate(en, fr, de, pt);
    this.identity = this._adminService.getIdentity();
    this.token = this._adminService.getToken();
    

    this.baseUrl = environment.baseUrl;
    this.next_page = 1;
    this.prev_page = 1;


    this.contentHeader = {
      headerTitle: 'TizAPP',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
           
            isLink: false,
            
          }
          
         
        ]
      }
    }

    this.salesChartoptions = {
      chart: {
        height: 100,
        type: 'area',
        toolbar: {
          show: false
        },
        sparkline: {
          enabled: true
        }
      },
      colors: [this.$danger],
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 2.5
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 0.9,
          opacityFrom: 0.7,
          opacityTo: 0.5,
          stops: [0, 80, 100]
        }
      },
      series: [
        {
          name: 'Sales',
          data: [10, 15, 7, 12, 3, 16]
        }
      ],
      tooltip: {
        x: { show: false }
      }
    };


    
   
    
  }
  onPage(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //console.log('paged!', event);
    }, 100);
  }


 async ngOnInit() {  
  // this.chart = new Chart('pie', {
  //   type: 'pie',
  //   data: {
  //     labels: ['Recibido', 'Verificado', 'Programado', 'Pendiente', 'Atendido', 'Cerrado', 'Similar'],
  //     datasets: [{
  //       data: [this.countReceived, this.countVerified, this.countProgrammed, this.countPending, this.countAttended, this.countClosed, this.countSimilar],
  //       backgroundColor: ['#ff6384', '#36a2eb', '#cc65fe', '#ffce56', '#4bc0c0','#ff9f40','#c9cbcf'],
  //       borderWidth: 1
  //     }]
  //   },
  //   options: {
  //     responsive: true,
  //     maintainAspectRatio: false,
  //     legend: {
  //       position: 'bottom',
  //       labels: {
  //         fontColor: 'black',
  //         fontSize: 12
  //       }
  //     }
  //   }
  // });





   if(this.identity.role == 'ROLE_DIRSPM'){

    await this._reportService.getReportsPaginatedsinIf(this.token, 1, 'Obstrucciones vía pública,Topes,Basura,Parques y jadínes,Señalizaciones,Iluminación,Baches', '').subscribe(
      (response) => {
        this.totalTotal = response.total_items;
        this.countReceived = response.countReceived;
        this.countVerified = response.countVerified;
        this.countProgrammed = response.countProgrammed;
        this.countPending = response.countPending;
        this.countAttended = response.countAttended;
        this.countClosed = response.countClosed;
        this.countSimilar = response.countSimilar;
        
      },
      (error) => {
        var errorMessage = <any>error;
      });
         
  }else{
    await this._reportService.getReportsPaginatedsinIf(this.token, 1, '', '').subscribe(
      (response) => {
        this.totalTotal = response.total_items;
        this.countReceived = response.countReceived;
        this.countVerified = response.countVerified;
        this.countProgrammed = response.countProgrammed;
        this.countPending = response.countPending;
        this.countAttended = response.countAttended;
        this.countClosed = response.countClosed;
        this.countSimilar = response.countSimilar;
      },
      (error) => {
        var errorMessage = <any>error;
      });
  }
//parques
  await this._reportService.getReportsPaginatedsinIf(this.token, 1, 'Parques%20y%20jad%C3%ADnes', '').subscribe(
      (response) => {
        this.reportsPJ = response.total_items;
        this.recibidoPJ = response.countReceived;
        this.verificadoPJ = response.countVerified;
        this.programadoPJ = response.countProgrammed;
        this.pendientePJ = response.countPending;
        this.atendidoPJ = response.countAttended;
        this.cerradoPJ = response.countClosed;
        this.similarPJ = response.countSimilar;
        
      },
      (error) => {
        var errorMessage = <any>error;
      });
      //baches
      await  this._reportService.getReportsPaginatedsinIf(this.token, 1, 'Baches','').subscribe(
      (response) => {
        this.reportsBAC = response.total_items;
        this.recibidoBAC = response.countReceived;
        this.verificadoBAC = response.countVerified;
        this.programadoBAC = response.countProgrammed;
        this.pendienteBAC = response.countPending;
        this.atendidoBAC = response.countAttended;
        this.cerradoBAC = response.countClosed;
        this.similarBAC = response.countSimilar;
      },
      (error) => {
        var errorMessage = <any>error;
      });

      //equipamient
      await  this._reportService.getReportsPaginatedsinIf(this.token, 1, 'Parques y jadínes,Señalizaciones','').subscribe(
        (response) => {
          this.reportsEU = response.total_items;
          this.recibidoEU = response.countReceived;
          this.verificadoEU = response.countVerified;
          this.programadoEU = response.countProgrammed;
          this.pendienteEU = response.countPending;
          this.atendidoEU = response.countAttended;
          this.cerradoEU = response.countClosed;
          this.similarEU = response.countSimilar;
        },
        (error) => {
          var errorMessage = <any>error;
        });
        //alumbrado
        await  this._reportService.getReportsPaginatedsinIf(this.token, 1, 'Iluminación','').subscribe(
          (response) => {
            this.reportsAL = response.total_items;
            this.recibidoAL = response.countReceived;
            this.verificadoAL = response.countVerified;
            this.programadoAL = response.countProgrammed;
            this.pendienteAL = response.countPending;
            this.atendidoAL = response.countAttended;
            this.cerradoAL = response.countClosed;
            this.similarAL = response.countSimilar;
          },
          (error) => {
            var errorMessage = <any>error;
          });
          //RESIDUOS
          await  this._reportService.getReportsPaginatedsinIf(this.token, 1, 'Basura','').subscribe(
        (response) => {
          this.reportsRES = response.total_items;
          this.recibidoRES = response.countReceived;
          this.verificadoRES = response.countVerified;
          this.programadoRES = response.countProgrammed;
          this.pendienteRES = response.countPending;
          this.atendidoRES = response.countAttended;
          this.cerradoRES = response.countClosed;
          this.similarRES = response.countSimilar;
        },
        (error) => {
          var errorMessage = <any>error;
        });
        //MOVILIDAD
        await  this._reportService.getReportsPaginatedsinIf(this.token, 1, 'Topes','').subscribe(
          (response) => {
            this.reportsMOV = response.total_items;
            this.recibidoMOV = response.countReceived;
            this.verificadoMOV = response.countVerified;
            this.programadoMOV = response.countProgrammed;
            this.pendienteMOV = response.countPending;
            this.atendidoMOV = response.countAttended;
            this.cerradoMOV = response.countClosed;
            this.similarMOV = response.countSimilar;
          },
          (error) => {
            var errorMessage = <any>error;
          });
      //PROTECCION CIVIL
          await  this._reportService.getReportsPaginatedsinIf(this.token, 1, 'Emergencias','').subscribe(
            (response) => {
              this.reportsPC = response.total_items;
              this.recibidoPC = response.countReceived;
              this.verificadoPC = response.countVerified;
              this.programadoPC = response.countProgrammed;
              this.pendientePC = response.countPending;
              this.atendidoPC = response.countAttended;
              this.cerradoPC = response.countClosed;
              this.similarPC = response.countSimilar;
            },
            (error) => {
              var errorMessage = <any>error;
            });

            //REGLAMENTOS
            await  this._reportService.getReportsPaginatedsinIf(this.token, 1, '','Invasión').subscribe(
        (response) => {
          this.reportsREGLA = response.total_items;
          this.recibidoREGLA = response.countReceived;
          this.verificadoREGLA = response.countVerified;
          this.programadoREGLA = response.countProgrammed;
          this.pendienteREGLA = response.countPending;
          this.atendidoREGLA = response.countAttended;
          this.cerradoREGLA = response.countClosed;
          this.similarREGLA = response.countSimilar;
        },
        (error) => {
          var errorMessage = <any>error;
        });
        //PLANEACION
        await  this._reportService.getReportsPaginatedsinIf(this.token, 1, 'Otro','').subscribe(
        (response) => {
          this.reportsPLA = response.total_items;
          this.recibidoPLA = response.countReceived;
          this.verificadoPLA = response.countVerified;
          this.programadoPLA = response.countProgrammed;
          this.pendientePLA = response.countPending;
          this.atendidoPLA = response.countAttended;
          this.cerradoPLA = response.countClosed;
          this.similarPLA = response.countSimilar;
        },
        (error) => {
          var errorMessage = <any>error;
        });
        //TRANSITO
         await  this._reportService.getReportsPaginatedsinIf(this.token, 1, '','Coche,Estacionamiento,Cerrada,Obstaculo').subscribe(
        (response) => {
          this.reportsTRANS = response.total_items;
          this.recibidoTRANS = response.countReceived;
          this.verificadoTRANS = response.countVerified;
          this.programadoTRANS = response.countProgrammed;
          this.pendienteTRANS = response.countPending;
          this.atendidoTRANS = response.countAttended;
          this.cerradoTRANS = response.countClosed;
          this.similarTRANS = response.countSimilar;
        },
        (error) => {
          var errorMessage = <any>error;
        });


  //     console.log('sda2');
    //   this._reportService.getReportsPaginated(this.token, 1, 'Parques y jardines','').subscribe(response => {
    //     this.reportsPJ = response;
    //     });
    // this._reportService.getReportsPaginated(this.token, 1, 'Baches','').subscribe(response => {
    //   this.reportsBAC =  response;
    //     });
    //  this._reportService.getReportsPaginated(this.token, 1, 'Iluminación','').subscribe(response => {
    //   this.reportsAL = response;
    //     });
    //   this._reportService.getReportsPaginated(this.token, 1, 'Basura','').subscribe(response => {
    //     this.reportsRES = response;
    //     });
    //  this._reportService.getReportsPaginated(this.token, 1, 'Topes','').subscribe(response => {
    //   this.reportsMOV = response;
    //     });
    //  this._reportService.getReportsPaginated(this.token, 1, 'Emergencias','').subscribe(response => {
    //   this.reportsPC = response;
    //     });
    //  this._reportService.getReportsPaginated(this.token, 1, 'Otro','').subscribe(response => {
    //   this.reportsPLA = response;
    //     });
    //  this._reportService.getReportsPaginated(this.token, 1, '','Invasión').subscribe(response => {
    //   this.reportsREGLA = response;
    //     });
    //  this._reportService.getReportsPaginated(this.token, 1, '','Coche,Estacionamiento,Cerrada,Obstaculo').subscribe(response => {
    //   this.reportsTRANS = response;
    //     });
    //  this._reportService.getReportsPaginated(this.token, 1, 'Obstrucciones vía pública,Topes,Basura,Parques y jadínes,Señalizaciones,Iluminación,Baches','').subscribe(response => {      
    //   this.reportsDIRSPM = response;
    //     });
      
   
    
      
      
     
      
      
      
 

    

   
    if(this.identity.role == 'ROLE_PJ'){
    
   
      this.report_area = 'Parques y jadínes';
      this.report_type = '';
      
            

    }else if(this.identity.role == 'ROLE_PC'){
  

      this.report_area = 'Emergencias';
      this.report_type = '';
    }
    else if(this.identity.role == 'ROLE_TRANS'){
     
      this.report_area = '';
      this.report_type = 'Coche';
    }
    else if(this.identity.role == 'ROLE_BAC'){
  
      this.report_area = 'Baches';
      this.report_type = '';
      
    }else if(this.identity.role == 'ROLE_AL'){
     
      this.report_area = 'Iluminación';
      this.report_type = '';
      
    }
    else if(this.identity.role == 'ROLE_EU'){

     
      this.report_area = 'Parques y jadínes,Señalizaciones';
      this.report_type = '';

    }else if(this.identity.role == 'ROLE_REGLA'){
      
     this.report_area = '';
      this.report_type = 'Invasión';

    }else if(this.identity.role == 'ROLE_RES'){
   
      this.report_area = 'Basura';
      this.report_type = '';
    }else if(this.identity.role == 'ROLE_MOV'){
    
      this.report_area = 'Topes';
      this.report_type = '';
    }

    else if(this.identity.role == 'ROLE_PLA'){
      
      this.report_area = 'Otro';
      this.report_type = '';
      

    }
    //DIRECTOR SPM
    else if(this.identity.role == 'ROLE_DIRSPM'){
      this.report_area = 'Obstrucciones vía pública,Topes,Basura,Parques y jadínes,Señalizaciones,Iluminación,Baches';
      this.report_type = '';
     
    }
    
    else if(this.identity.role == 'ROLE_PRESIDENTA'  || this.identity.role == 'ROLE_ADMIN'){
      this.report_area = '';
      this.report_type = '';
        
    }
   
  //  this.getReportCountByAreaAndStatus('Iluminación', 'atendido');


    // this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
    //   // If we have zoomIn route Transition then load datatable after 450ms(Transition will finish in 400ms)
    //   if (config.layout.animation === 'zoomIn') {
    //     setTimeout(() => {
    //       this._reportService.onInvoiceListChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
    //         this.data = response;
    //         this.rows = this.data;
    //         this.tempData = this.rows;
    //         this.tempFilterData = this.rows;

    //         console.log('si se pudo', this.rows)
    //       });
          
    //     }, 450);
    //   } else {
    //     this._reportService.onInvoiceListChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
    //       this.data = response;
    //       this.rows = this.data;
    //       this.tempData = this.rows;
    //       this.tempFilterData = this.rows;
    //       console.log('no se pudo', this.rows)
    //     });
    //   }
    // });
   
   
    
    //console.log(this.identity)  
    
  }
  ngAfterViewInit() {
    // Subscribe to core config changes
    this._coreConfigService.getConfig().subscribe(config => {
      // If Menu Collapsed Changes
      if (
        (config.layout.menu.collapsed === true || config.layout.menu.collapsed === false) &&
        localStorage.getItem('currentUser')
      ) {
        setTimeout(() => {
          // Get Dynamic Width for Charts
          this.isMenuToggled = true;
          this.statisticsBar.chart.width = this.statisticsBarRef?.nativeElement.offsetWidth;
          this.statisticsLine.chart.width = this.statisticsLineRef?.nativeElement.offsetWidth;
          this.gainedChartoptions.chart.width = this.gainedChartoptionsRef?.nativeElement.offsetWidth;
          this.revenueChartoptions.chart.width = this.revenueChartoptionsRef?.nativeElement.offsetWidth;
          this.salesChartoptions.chart.width = this.salesChartoptionsRef?.nativeElement.offsetWidth;
          this.orderChartoptions.chart.width = this.orderChartoptionsRef?.nativeElement.offsetWidth;
          this.trafficChartoptions.chart.width = this.trafficChartoptionsRef?.nativeElement.offsetWidth;
          this.userChartoptions.chart.width = this.userChartoptionsRef?.nativeElement.offsetWidth;
          this.newsletterChartoptions.chart.width = this.newsletterChartoptionsRef?.nativeElement.offsetWidth;
        }, 500);
      }
    });
  }

  getReports(report_area: string, report_type: string) {
    if (!this.hasFetched) {
    this._route.params.forEach((params: Params) => {
      let page = +params['page'];
      
      if (!page) {
        page = 1;
      } else {
        this.next_page = page + 1;
        this.prev_page = page - 1;

        if (this.prev_page == 0) {
          this.prev_page = 1;
        }

        report_area = encodeURIComponent(report_area);
        report_type = encodeURIComponent(report_type);
      }
      this._reportService.getReportsPaginatedsinIf(this.token, page, report_area, report_type).subscribe(
        (response) => {
          if (!response.tzxcityreports) {
            this._router.navigate(['/']);
          } else {
            
            
            this.reports = response.tzxcityreports;
            this.data = this.reports;
            this.rows = this.data;
            this.tempData = this.rows;
            this.tempFilterData = this.rows;
            this.tempFilterDataUser = this.rows;
            this.tempFilterData = this.rows.length;
            this.tempFilterDataUser = this.rows.length;
            this.totalTotal = response.total_items;
            this.countReceived = response.countReceived;
            this.countVerified = response.countVerified;
            this.countProgrammed = response.countProgrammed;
            this.countPending = response.countPending;
            this.countAttended = response.countAttended;
            this.countClosed = response.countClosed;
            this.countSimilar = response.countSimilar;
            
          }
        },
        (error) => {
          var errorMessage = <any>error;
        }
      );
    });
     }
  }

  loadMoreData() {
  





    if (!this.isLoading && this.hasFetched) {
     
    this.isLoading = true;

    this._reportService.getReportsPaginated(this.token, this.currentPage, this.report_area, this.report_type).subscribe(
      (response) => {
        if (!response.tzxcityreports) {
          this._router.navigate(['/']);
        } else {
          this.currentPage++;
          this.hasFetched = true; // cambia el estado de ha cargado alguna vez a true
          const newReports = response.tzxcityreports.filter(report => !this.shownReports.has(report.uid));
        newReports.forEach(report => this.shownReports.add(report.uid));
        this.reports = this.reports.concat(newReports);
          this.data = this.reports;
          this.rows = this.data;
          this.tempData = this.rows;
          this.tempFilterData = this.rows;
          this.tempFilterDataUser = this.rows;
          this.tempFilterData = this.rows.length;
          this.tempFilterDataUser = this.rows.length;
          this.isLoading = false;
          //nueva comprobación
          if(response.isLastPage || newReports.length<10) {
            this.isLastPage = true;
          }
        }
      },
      (error) => {
        this.isLoading = false;
        var errorMessage = <any>error;
      }
    );
     } 
  }




  onDeleteConfirm(id) {
    this.confirmado = id;
  }

  onCancelReport() {
    this.confirmado = null;
  }

  onDeleteReport(id) {
    this._reportService.deleteReport(this.token, id).subscribe(
      (response) => {
        if (!response.tzxcityreports) {
          
          location.reload();
        }
       // this.getReports();
      },
      (error) => {
        var errorMessage = <any>error;

      }
    );
  }

  borrar() {
    (document.querySelectorAll('.notification .delete') || []).forEach(
      ($delete) => {
        const $notification = $delete.parentNode;

        $notification.parentNode.removeChild($notification);
      }
    );
  }
  backPage(){
    window.history.back();
  }


//   exportexcel(): void
//   {
//     /* pass here the table id */
//     let element = document.getElementById('excel-table');
//     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

//     /* generate workbook and add the worksheet */
//     const wb: XLSX.WorkBook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

//     /* save to file */
//     XLSX.writeFile(wb, this.fileName);

//   }
openPopover(popoverContent) {
  this.popover.open({ content: popoverContent });
}

openPopF(imagenPrev){
 
  this.imagenPrevobte = imagenPrev;
  
  this.activepopUp = true;
}
closePopF(){
  this.activepopUp = false;
}

rotate() {
   this.rotationAngle += 90;
}
get safeStyle() {
  return this.sanitizer.bypassSecurityTrustStyle('rotate(' + this.rotationAngle + 'deg)');
}

// PANTALLA EXCEL
exportexcel(table: any): void {
  let data = table.rows; // Obtener los datos de la tabla ngx-datatable
  let ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data); // Convertir los datos en un objeto de hoja de cálculo
  let wb: XLSX.WorkBook = XLSX.utils.book_new(); // Crear un nuevo libro de trabajo
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1'); // Agregar la hoja de trabajo al libro
  XLSX.writeFile(wb, 'reportes-en-pantalla' +  + '.xlsx'); // Guardar el libro en un archivo con el nombre 'data.xlsx'
}



  
  
copy(value) {
  const selectBox = document.createElement('textarea');
  selectBox.style.position = 'fixed';
  selectBox.value = value;
  document.body.appendChild(selectBox);
  selectBox.focus();
  selectBox.select();
  document.execCommand('copy');
  document.body.removeChild(selectBox);
  setTimeout(() => {
    this.copyCodeStatus = false;
  }, 500);
  this.copyCodeStatus = true;

  this.toastr.success('Folio copiado correctamente!', value, {
    progressBar: true,
    toastClass: 'toast ngx-toastr',
    closeButton: true
  });
}

copyCode(inputTextValue) {
  const selectBox = document.createElement('textarea');
  selectBox.style.position = 'fixed';
  selectBox.value = inputTextValue;
  document.body.appendChild(selectBox);
  selectBox.focus();
  selectBox.select();
  document.execCommand('copy');
  document.body.removeChild(selectBox);

  this.toastr.success(inputTextValue, 'Copiado correctamente!' , {
    progressBar: true,
    toastClass: 'toast ngx-toastr',
    closeButton: true
  });
}
//TODOS EXCEL

getReportsExcel() {
  this._route.params.forEach((params: Params) => {
    let page = +params['page'];
    
    if (!page) {
      page = 1;
    } else {
      this.next_page = page + 1;
      this.prev_page = page - 1;

      if (this.prev_page == 0) {
        this.prev_page = 1;
      }
    }
    this._reportService.getReports(this.token, page).subscribe(
      (response) => {
        this.loadingExcel = false;
        if (!response.tzxcityreports) {
          this._router.navigate(['/']);
          
        }

          
          else{
            this.reports = response.tzxcityreports;

            const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.reports);
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Reports');

            /* generar un archivo excel y forzar la descarga */
            XLSX.writeFile(wb, 'TOTAL-Reportes.xlsx');

          }
      },
      (error) => {
        this.loadingExcel = false;
        var errorMessage = <any>error;


      }
    ) ;
  });

}


downloadExcel() {
  this.loadingExcel = true;
  this.getReportsExcel();
}

 }
