<div class="content-wrapper container-xxl p-0">
    <div class="content-body">

        <app-content-header [contentHeader]="contentHeader"></app-content-header>


        <section class="invoice-add-wrapper">
            <!-- <div class="Eventos">
                <h3>-Eventos</h3>
                <form [formGroup]="EventForm" autocomplete="off">
                    

                    SIUN USAR

                     <div class="form-group material-style">
                        <input formControlName="hora_evento" name="duracion_evento" type="txt" class="form-control register">
                        <label>Hora de Evento:</label>
                    </div>





                    <div class="form-group material-style">
                        <input formControlName="titulo_evento" name="titulo_evento" type="text" class="form-control register">
                        <label>Titulo Evento:</label>
                    </div>
 <div class="form-group material-style">
                        <textarea formControlName="descripcion_evento" name="descripcion_evento" placeholder="De que trata..." cols="50" rows="10" minlength="10" maxlength="1800" class="form-control register"></textarea>

                        <label>Descripcion Evento:</label>
                    </div>
<div class="form-group material-style">
                        <input name="imagen_evento" type="file" (change)="Onchange($event)" class="form-control register">
                        <label>imagen:</label>
                    </div>

                    <div class="form-group material-style">
                        <input formControlName="xaction" name="fecha" type="date" class="form-control register">
                        <label>Fecha del Evento:</label>
                    </div>


<div class="form-group material-style">
                        <input formControlName="enlace_boton" name="enlace_boton" type="text" class="form-control register">
                        <label>Enlace al Evento:</label>
                    </div>

 <div class="form-group material-style">
                        <input formControlName="nombre_boton" name="nombre_boton" type="text" class="form-control register">
                        <label>Nombre boton:</label>
                    </div>
                    

                    
                   
                    <div class="form-group material-style">
                        <input formControlName="xpiration" name="fecha" type="date" class="form-control register">
                        <label>Fecha de Expiración:</label>
                    </div>
                   
                    <div class="form-group material-style">
                        <input formControlName="duracion_evento" name="duracion_evento" type="number" class="form-control register">
                        <label>Duracion Evento:</label>
                    </div>
                   <div class="form-group material-style">
                        <input formControlName="organizador" name="organizador" type="text" class="form-control register">
                        <label>Organizador:</label>
                    </div>
                    

                    <div class="form-group material-style">
                        <input formControlName="lugar_evento" name="lugar_evento" type="text" class="form-control register">
                        <label>Lugar Evento:</label>
                    </div>
                    
                   
                    <div class="form-group material-style">
                        <input formControlName="lp" name="lp" type="text" class="form-control register">
                        <label>Lp:</label>
                    </div>
                    <p>Coordenadas: </p>
                    <div class="form-group material-style">
                        <input formControlName="categorias" name="categorias" type="text" class="form-control register">
                        <label>categorias:</label>
                    </div>
                    <button [disabled]="loading" (click)="Eventsender()" class="btn btn-secondary">
                    {{ (loading)? 'Publicando...': 'Publicar' }}
                </button>
                </form>
            </div> -->
            <div class="row invoice-add">
                <!-- Invoice Add Left starts -->
                <div class="col-xl-8 col-md-7 col-12">
                    <div class="card invoice-preview-card">


                        <!-- <div class="form-group material-style">
                            <input formControlName="titulo_evento" name="titulo_evento" type="text" class="form-control register">
                            <label>Titulo Evento:</label>
                        </div>
                        <div class="form-group material-style">
                            <label>Fecha de Publicación:</label>
                            <b><span>{{Today}}</span></b>
                        </div>
                        <div class="form-group material-style">
                            <input formControlName="xaction" name="fecha" type="date" class="form-control register">
                            <label>Fecha del Evento:</label>
                        </div>
                        <div class="form-group material-style">
                            <input formControlName="xpiration" name="fecha" type="date" class="form-control register">
                            <label>Fecha de Expiración:</label>
                        </div>
                        <div class="form-group material-style">
                            <input formControlName="hora_evento" name="duracion_evento" type="txt" class="form-control register">
                            <label>Hora de Evento:</label>
                        </div>
                        <div class="form-group material-style">
                            <input formControlName="duracion_evento" name="duracion_evento" type="number" class="form-control register">
                            <label>Duracion Evento:</label>
                        </div>
                        <div class="form-group material-style">
                            <textarea formControlName="descripcion_evento" name="descripcion_evento" placeholder="De que trata..." cols="50" rows="10" minlength="10" maxlength="1800" class="form-control register"></textarea>

                            <label>Descripcion Evento:</label>
                        </div>
                        <div class="form-group material-style">
                            <input name="imagen_evento" type="file" (change)="Onchange($event)" class="form-control register">
                            <label>imagen:</label>
                        </div>

                        <div class="form-group material-style">
                            <input formControlName="lugar_evento" name="lugar_evento" type="text" class="form-control register">
                            <label>Lugar Evento:</label>
                        </div>
                        <div class="form-group material-style">
                            <input formControlName="organizador" name="organizador" type="text" class="form-control register">
                            <label>Organizador:</label>
                        </div>
                        <div class="form-group material-style">
                            <input formControlName="nombre_boton" name="nombre_boton" type="text" class="form-control register">
                            <label>Nombre boton:</label>
                        </div>
                        <div class="form-group material-style">
                            <input formControlName="enlace_boton" name="enlace_boton" type="text" class="form-control register">
                            <label>Enlace al Evento:</label>
                        </div>
                        <div class="form-group material-style">
                            <input formControlName="lp" name="lp" type="text" class="form-control register">
                            <label>Lp:</label>
                        </div>
                        <p>Coordenadas: </p>
                        <div class="form-group material-style">
                            <input formControlName="categorias" name="categorias" type="text" class="form-control register">
                            <label>categorias:</label>
                        </div>
                        <button [disabled]="loading" (click)="Eventsender()" class="btn btn-secondary">
                      {{ (loading)? 'Publicando...': 'Publicar' }}
                  </button> -->



                        <div class="card-body invoice-padding pt-2">

                            <form class="form form-vertical" [formGroup]="EventForm" autocomplete="off">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="card-header" style="justify-content: center;">
                                            <h4 class="card-title">AGREGAR EVENTO</h4>
                                        </div>
                                        <div class="form-group">
                                            <label for="title-event">Titulo</label>
                                            <div class="input-group input-group-merge">
                                                <input formControlName="titulo_evento" class="form-control" name="titulo_evento" placeholder="Titulo" type="text" class="form-control register" [(ngModel)]="titulo_evento">

                                            </div>
                                        </div>
                                        <label for="description-event">Descripción</label>
                                        <fieldset class="form-group">

                                            <textarea formControlName="descripcion_evento" name="descripcion_evento" class="form-control" id="description-event" rows="3" placeholder="Descripción" [(ngModel)]="descripcion_evento"></textarea>
                                        </fieldset>
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-md-6 col-12">

                                        <fieldset class="form-group">
                                            <label for="picture">Subir Imagén:</label>

                                            <input name="imagen_evento" type="file" (change)="Onchange($event)" class="file-up">
                                        </fieldset>


                                    </div>
                                    <div class="col-md-6 col-12">


                                        <div class="form-group">
                                            <label for="date">Fecha:</label>


                                            <div class="input-group-append">
                                                <input formControlName="xaction" name="fecha" class="form-control" [(ngModel)]="SelectedDate" [owlDateTime]="dt2" placeholder="Fecha y Hora">

                                                <button class="btn btn-outline-secondary feather icon-calendar" [owlDateTimeTrigger]="dt2" type="button" rippleEffect></button>
                                            </div>

                                            <owl-date-time #dt2></owl-date-time>



                                        </div>
                                    </div>

                                </div>


                                <div class="row">
                                    <div class="col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="organizer">Organizador</label>
                                            <div class="input-group input-group-merge">

                                                <input formControlName="organizador" name="organizador" type="text" id="organizer" class="form-control" placeholder="Organizador" [(ngModel)]="Organizador" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-12">
                                        <div class="form-group">


                                            <!-- <input formControlName="duracion_evento" name="duracion_evento" type="number" class="form-control register" [(ngModel)]="DuracionEvento"> -->
                                            <label>Duracion Evento:</label>

                                            <select class="form-control" id="duration" [(ngModel)]="DuracionEvento" formControlName="duracion_evento" name="duracion_evento">
                                                <option selected value=" ">No Aplica</option>
                                                <option>1 Día</option>
                                                <option>2 Días</option>
                                                <option>3 Días</option>
                                                <option>4 Días</option>
                                                <option>5 Días</option>
                                                <option>6 Días</option>
                                                <option>7 Días</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="link-button">Enlace Botón</label>
                                            <div class="input-group input-group-merge">

                                                <input formControlName="enlace_boton" name="enlace_boton" type="text" id="link-button" class="form-control" placeholder="Enlace" [(ngModel)]="EnlaceBoton" />
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="text-button">Texto Botón</label>
                                            <div class="input-group input-group-merge">

                                                <input formControlName="nombre_boton" name="nombre_boton" type="text" id="text-button" class="form-control" placeholder="Texto que aparecerá en el botón" [(ngModel)]="NombreBoton" />
                                            </div>
                                        </div>
                                    </div>

                                </div>



                                <div class="form-group">
                                    <label for="location">Ubicación</label>
                                    <div id="map"></div>
                                    <div class="row justify-content-center" style="margin-top: 15px;">
                                        <div class="col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="place">Lugar</label>
                                                <div class="input-group input-group-merge">
                                                    <input formControlName="lugar_evento" placeholder="Lugar" name="lugar_evento" type="text" class="form-control" [(ngModel)]="LugarEvento">

                                                </div>
                                            </div>

                                            <button class="locatefix" (click)="setLocation()"> &nbsp;Fijar ubicación</button>


                                        </div>



                                    </div>

                                </div>


                                <div class="row">
                                    <div class="col-md-6 col-12">
                                        <div class="form-group">
                                            <label>Fecha de Expiración:</label>
                                            <input formControlName="xpiration" name="fecha" type="date" class="form-control register">

                                        </div>

                                    </div>
                                    <div class="col-md-6 col-12">
                                        <div class="form-group">
                                            <!-- <input formControlName="duracion_evento" name="duracion_evento" type="number" class="form-control register" [(ngModel)]="DuracionEvento"> -->
                                            <label>Categorías:</label>

                                            <select class="form-control" formControlName="categorias" name="categorias" [(ngModel)]="catego">
                                            <option selected value="">No Aplica</option>
                                            <option>Arte</option>
                                            <option>Deporte</option>
                                            <option>Tecnología</option>
                                            <option>Entretenimiento</option>
                                            
                                      
                                        </select>
                                        </div>
                                    </div>

                                </div>






                                <div class="form-group material-style" style="display: none;">
                                    <input formControlName="lp" name="lp" [(ngModel)]="coordenadas" type="text" class="form-control register">
                                    <label>Lp:</label>
                                </div>




                                <button [disabled]="loading" (click)="Eventsender()" class="btn btn-secondary">
                                    {{ (loading)? 'Publicando...': 'Publicar' }}
                                </button>



                            </form>
                            <!-- <form [formGroup]="EventForm" autocomplete="off">

                                <div class="form-group material-style">
                                    <input formControlName="titulo_evento" name="titulo_evento" type="text" class="form-control register">
                                    <label>Titulo Evento:</label>
                                </div>
                                <div class="form-group material-style">
                                    <label>Fecha de Publicación:</label>
                                    <b><span>{{Today}}</span></b>
                                </div>
                                <div class="form-group material-style">
                                    <input formControlName="xaction" name="fecha" type="date" class="form-control register">
                                    <label>Fecha del Evento:</label>
                                </div>
                                <div class="form-group material-style">
                                    <input formControlName="xpiration" name="fecha" type="date" class="form-control register">
                                    <label>Fecha de Expiración:</label>
                                </div>
                                <div class="form-group material-style">
                                    <input formControlName="hora_evento" name="duracion_evento" type="txt" class="form-control register">
                                    <label>Hora de Evento:</label>
                                </div>
                                <div class="form-group material-style">
                                    <input formControlName="duracion_evento" name="duracion_evento" type="number" class="form-control register">
                                    <label>Duracion Evento:</label>
                                </div>
                                <div class="form-group material-style">
                                    <textarea formControlName="descripcion_evento" name="descripcion_evento" placeholder="De que trata..." cols="50" rows="10" minlength="10" maxlength="1800" class="form-control register"></textarea>

                                    <label>Descripcion Evento:</label>
                                </div>
                                <div class="form-group material-style">
                                    <input name="imagen_evento" type="file" (change)="Onchange($event)" class="form-control register">
                                    <label>imagen:</label>
                                </div>

                                <div class="form-group material-style">
                                    <input formControlName="lugar_evento" name="lugar_evento" type="text" class="form-control register">
                                    <label>Lugar Evento:</label>
                                </div>
                                <div class="form-group material-style">
                                    <input formControlName="organizador" name="organizador" type="text" class="form-control register">
                                    <label>Organizador:</label>
                                </div>
                                <div class="form-group material-style">
                                    <input formControlName="nombre_boton" name="nombre_boton" type="text" class="form-control register">
                                    <label>Nombre boton:</label>
                                </div>
                                <div class="form-group material-style">
                                    <input formControlName="enlace_boton" name="enlace_boton" type="text" class="form-control register">
                                    <label>Enlace al Evento:</label>
                                </div>
                                <div class="form-group material-style">
                                    <input formControlName="lp" name="lp" type="text" class="form-control register">
                                    <label>Lp:</label>
                                </div>
                                <p>Coordenadas: </p>
                                <div class="form-group material-style">
                                    <input formControlName="categorias" name="categorias" type="text" class="form-control register">
                                    <label>categorias:</label>
                                </div>
                                <button [disabled]="loading" (click)="Eventsender()" class="btn btn-secondary">
                              {{ (loading)? 'Publicando...': 'Publicar' }}
                          </button>
                            </form> -->

                        </div>





                    </div>



                </div>

                <!-- Invoice Add Right starts -->
                <div class="col-xl-4 col-md-5 col-12 mt-md-0 mt-2">
                    <div class="frame">
                        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" width="209.9" height="27.8" viewBox="0 0 209.9 27.8">
                            <defs>
                                <style>
                                    .cls-1 {
                                        fill: #1a1a1a;
                                    }
                
                                    .cls-2 {
                                        fill: #161616;
                                    }
                
                                    .cls-3 {
                                        fill: #111110;
                                    }
                                </style>
                            </defs>
                            <g>
                                <path
                                    d="M491.2,129.3H299.6v.7h8.2a9.3,9.3,0,0,1,9.3,9.3h0A19,19,0,0,0,336,157.1H473a19,19,0,0,0,18.9-17.7h0a9.3,9.3,0,0,1,9.4-9.3h8.2v-.7Zm-150.8,20a5.4,5.4,0,0,1-5.5-5.5,5.5,5.5,0,0,1,5.5-5.5,5.5,5.5,0,0,1,5.5,5.5A5.5,5.5,0,0,1,340.4,149.3Zm26.4,2.7a8.2,8.2,0,0,1-8.1-8.2,8.1,8.1,0,0,1,8.1-8.1,8.2,8.2,0,0,1,8.2,8.1A8.2,8.2,0,0,1,366.8,152Zm56.8-6.3H385.4a2.4,2.4,0,1,1,0-4.7h38.2a2.4,2.4,0,1,1,0,4.7Zm19.9,6.3a8.2,8.2,0,0,1-8.2-8.2,8.2,8.2,0,0,1,8.2-8.1,8.1,8.1,0,0,1,8.1,8.1A8.2,8.2,0,0,1,443.5,152Zm28.6-2.7a5.5,5.5,0,0,1-5.5-5.5,5.5,5.5,0,0,1,5.5-5.5,5.5,5.5,0,0,1,5.5,5.5A5.4,5.4,0,0,1,472.1,149.3Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path class="cls-1" d="M423.6,141H385.4a2.4,2.4,0,1,0,0,4.7h38.2a2.4,2.4,0,1,0,0-4.7Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path class="cls-2"
                                    d="M366.8,136.7a7.2,7.2,0,1,0,7.2,7.1A7.1,7.1,0,0,0,366.8,136.7Zm0,9.9a2.8,2.8,0,0,1-2.8-2.8,2.9,2.9,0,0,1,2.8-2.8,2.8,2.8,0,0,1,2.8,2.8A2.7,2.7,0,0,1,366.8,146.6Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path class="cls-3"
                                    d="M366.8,135.7a8.1,8.1,0,0,0-8.1,8.1,8.2,8.2,0,0,0,8.1,8.2,8.2,8.2,0,0,0,8.2-8.2A8.2,8.2,0,0,0,366.8,135.7Zm0,15.3a7.2,7.2,0,1,1,7.2-7.2A7.1,7.1,0,0,1,366.8,151Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path
                                    d="M366.8,141a2.9,2.9,0,0,0-2.8,2.8,2.8,2.8,0,0,0,2.8,2.8,2.7,2.7,0,0,0,2.8-2.8A2.8,2.8,0,0,0,366.8,141Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path class="cls-2"
                                    d="M443.5,136.7a7.2,7.2,0,1,0,7.1,7.1A7.1,7.1,0,0,0,443.5,136.7Zm0,9.9a2.8,2.8,0,0,1-2.9-2.8,2.9,2.9,0,0,1,5.7,0A2.8,2.8,0,0,1,443.5,146.6Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path class="cls-3"
                                    d="M443.5,135.7a8.2,8.2,0,0,0-8.2,8.1,8.2,8.2,0,0,0,8.2,8.2,8.2,8.2,0,0,0,8.1-8.2A8.1,8.1,0,0,0,443.5,135.7Zm0,15.3a7.2,7.2,0,1,1,7.1-7.2A7.2,7.2,0,0,1,443.5,151Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path d="M443.5,141a2.9,2.9,0,0,0-2.9,2.8,2.9,2.9,0,0,0,5.7,0A2.9,2.9,0,0,0,443.5,141Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path class="cls-2"
                                    d="M472.1,139a4.9,4.9,0,1,0,4.9,4.8A4.9,4.9,0,0,0,472.1,139Zm0,6.7a1.9,1.9,0,0,1-1.9-1.9,2,2,0,0,1,1.9-1.9,1.9,1.9,0,0,1,1.9,1.9A1.8,1.8,0,0,1,472.1,145.7Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path class="cls-3"
                                    d="M472.1,138.3a5.5,5.5,0,0,0-5.5,5.5,5.5,5.5,0,0,0,5.5,5.5,5.4,5.4,0,0,0,5.5-5.5A5.5,5.5,0,0,0,472.1,138.3Zm0,10.4a4.9,4.9,0,1,1,4.9-4.9A4.9,4.9,0,0,1,472.1,148.7Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path
                                    d="M472.1,141.9a2,2,0,0,0-1.9,1.9,1.9,1.9,0,0,0,1.9,1.9,1.8,1.8,0,0,0,1.9-1.9A1.9,1.9,0,0,0,472.1,141.9Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path class="cls-2"
                                    d="M340.4,139a4.9,4.9,0,0,0,0,9.7,4.9,4.9,0,0,0,0-9.7Zm0,6.7a1.8,1.8,0,0,1-1.9-1.9,1.9,1.9,0,0,1,1.9-1.9,2,2,0,0,1,1.9,1.9A1.9,1.9,0,0,1,340.4,145.7Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path class="cls-3"
                                    d="M340.4,138.3a5.5,5.5,0,0,0-5.5,5.5,5.4,5.4,0,0,0,5.5,5.5,5.5,5.5,0,0,0,5.5-5.5A5.5,5.5,0,0,0,340.4,138.3Zm0,10.4a4.9,4.9,0,1,1,4.8-4.9A4.9,4.9,0,0,1,340.4,148.7Z"
                                    transform="translate(-299.6 -129.3)" />
                                <circle cx="40.8" cy="14.6" r="1.9" />
                            </g>
                        </svg>
                        <div class="card-phone">
                            <div class="card preview-event-add">
                                <div _ngcontent-oip-c218="" class="header-preview">
                                    <div _ngcontent-oip-c218="" class="col left-item">
                                        <div _ngcontent-oip-c218="" class="eventIconHead">
                                            <i class="fa-solid fa-arrow-left"></i>
                                        </div>
                                        <div _ngcontent-oip-c218="" class="eventIconHead">
                                            <i class="fa-regular fa-arrow-up-right-from-square"></i>
                                        </div>
                                    </div>
                                </div>
                                <img [src]="url ">
                                <section class="content-event">
                                    <h2 class="title-event">{{ titulo_evento }}</h2>

                                    <div _ngcontent-oip-c218="" class="row time-date">
                                        <div _ngcontent-oip-c218="" class="col-7">
                                            <span _ngcontent-oip-c218="" class="location-event"><i class="fa-duotone fa-map-location-dot"></i> &nbsp;<a _ngcontent-oip-c218="" target="_blank" href="https://www.google.com/maps/@{{ coordenadas }},18.3z">{{ LugarEvento }}  </a></span>
                                        </div>
                                        <div _ngcontent-oip-c218="" class="col-5">
                                            <span class="fecha-event"><i class="fa-duotone fa-calendar-days"></i>&nbsp;&nbsp;<a _ngcontent-oip-c218="" href="" style="color: black;">{{ SelectedDate | date:'d MMM y'}}</a></span>
                                        </div>
                                    </div>


                                    <hr>
                                    <div class="row section-preview">
                                        <div class="col-md-12">
                                            <h3 class="organizer">{{ Organizador }}</h3>
                                        </div>
                                        <div class="col-md-12">
                                            <span class="DurEvent">{{ SelectedDate | date:'h:mm a'}} &#183; {{ DuracionEvento }} </span>
                                        </div>
                                    </div>
                                    <hr>
                                    <br>
                                    <h3 class="organizer">Más detalles</h3>
                                    <div class="row section-preview">
                                        <div class="col-md-12">
                                            <p>{{ descripcion_evento }}</p>
                                            {{multiCustomGithubUsersSelected | json}}
                                        </div>
                                    </div>
                                    <hr>


                                    <div class="row section-preview" *ngIf="catego === 'Arte'">
                                        <div class="col-2">
                                            <span class="iconCate"><i class="fal fa-paint-brush"></i></span>

                                        </div>
                                        <div class="col-10 cateNAME">
                                            {{catego}}
                                        </div>
                                    </div>
                                    <div class="row section-preview" *ngIf="catego === 'Tecnología'">
                                        <div class="col-2">
                                            <span class="iconCate"><i class="fal fa-chart-network"></i></span>

                                        </div>
                                        <div class="col-10 cateNAME">
                                            {{catego}}
                                        </div>
                                    </div>
                                    <div class="row section-preview" *ngIf="catego === 'Deporte'">
                                        <div class="col-2">
                                            <span class="iconCate">   <i class="fal fa-biking"></i></span>

                                        </div>
                                        <div class="col-10 cateNAME">
                                            {{catego}}
                                        </div>
                                    </div>
                                    <hr>


                                </section>



                                <div class="footer-event">
                                    <div class="row">
                                        <div class="col-5">
                                            <h6 class="TitleFooter">{{titulo_evento}}</h6>
                                        </div>
                                        <div class="col-7">
                                            <a *ngIf="NombreBoton" class="BtnFooter" style="text-align: center!important" href="{{EnlaceBoton}}}">{{ NombreBoton }}</a>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>



                </div>


            </div>


        </section>
    </div>


    <!--/  Add New Customer Sidebar -->
</div>