import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GLOBAL } from './global';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';



@Injectable()
export class AdminService {
  public token;
  public identity;
  private baseUrl: string = environment.baseUrl;

  constructor(private _http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }

  signup(admins_to_login, gethash = null): Observable<any> {
    if (gethash != null) {
      admins_to_login.gethash = gethash;
    }
    let json = JSON.stringify(admins_to_login);
    let params = json;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http
      .post(this.baseUrl + 'login', params, { headers: headers })
      .pipe(map((res) => res));
  } //signup

  getIdentity() {
    let identity = JSON.parse(localStorage.getItem('identity'));
    if (identity != 'undefined') {
      this.identity = identity;
    }else{
      this.identity = null;
    }

    return this.identity;
  }


  getToken() {
    let token = localStorage.getItem('token');
    if (token != 'undefined') {
      this.token = token;
    }else{
      this.token = null;
    }

    return this.token;
  }


}
