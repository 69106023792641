import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class FollowService {

  public socketStatus = false;  
  private fire:string = environment.live;
  constructor(
    private http:HttpClient
    ) { }

  // checkStatus(){
    
  //   this.socket.on('connect', () => {
  //     console.log('Conectado del servidor');
  //     this.socketStatus = true;
  //   });    
    
  //   this.socket.on('disconnect', () => {
  //     console.log('Desconeectado del servidor');
  //     this.socketStatus = false;
  //   });    
    
  // }

  // //emit('EVENTO,PAYLOAD,CALLBACK)
  // emissor(evento:string,payload?:any,callback?:Function){
        
  //   // console.log('Emitiendo: '+ evento+ 'y lleva '+ payload);
  //   this.socket.emit(evento,payload,callback);
  // }

  // listener(evento:string){
    
  //   return this.socket.fromEvent(evento);
  //   // .pipe(
  //   //   map(data =>  {console.log("fromServe: ",data) })
  //   // );   

  // }

  firestoreDD(){
    const url = `${this.fire}/point`;

    return this.http.get<any>(url)
    .pipe(
      map(resp => resp),
      catchError(err => of(err.error.msg))
    );
  }
}