<!-- <h2>Usuario: {{ xusr[0] }}</h2> -->

<!-- Prox Version / solucion temporal Buscador general 
    <h2>Historial</h2>
<div class="cont-coords" *ngFor="let item of xfolios">
            
    <span (click)="Goto(item)">{{item}}</span>
</div>   -->




<!-- 
<div class="cont-coords" *ngFor="let item of FDetail[0]">

    <h3>Folio: {{ xinfo }}</h3>
    <span>Detalles:</spa    n>
    <p>Lat: {{ item.lat }} </p>
    <p>Lat: {{ item.lng }} </p>
    <p>Fecha: {{ item.dayorigin | date: 'short' }} </p>
</div> -->



<!-- Separar marcas por cuota y muestreo por folio -->

<app-content-header [contentHeader]="contentHeader"></app-content-header>

<div class="content-wrapper container-xxl p-0">
    <div class="content-body">


        <div class="row match-height">
            <div class="col-lg-7 col-12">
                <div class="prev-vw">
                    <div #mapas id="mapa" class="map-container">
                    </div>
                </div>
            </div>
            <!--/ User Timeline Card -->

            <!-- Chat Card -->
            <div class="col-lg-5 col-md-6 col-12">
                <div class="card card-user-timeline">
                    <div class="card-header">
                        <div class="d-flex align-items-center">
                            <i data-feather="list" class="user-timeline-title-icon"></i>
                            <h4 class="card-title">Actividad</h4>
                        </div>
                        <hr>
                        <p>
                            <b>Folio:</b>&nbsp;{{ usrxfole }}<br>
                          

                        </p>

                    </div>
                    <div class="card-body">
                        <ul class="timeline ml-50 mb-0">
                            <li class="timeline-item" *ngFor="let item of DetailStack[0]">
                                <span class="timeline-point timeline-point-indicator"></span>
                                <div class="timeline-event">
                                    <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                        <h6>{{ item.dayorigin | date: 'h:mm:ss a'  }}</h6>

                                    </div>
                                    <p> <b><a target="_blank" href="https://www.google.com.mx/maps/place/{{ item.lat }},{{ item.lng }} " class="mr-1" container="body" placement="top" ngbTooltip="Ver">Abrir Google Maps</a></b> <br>
                                        <b>Latitud:</b>&nbsp;{{ item.lat }} <br>
                                        <b>Longitud:</b>&nbsp;{{ item.lng }} <br>
                                        <b>Fecha/Hora:</b>&nbsp;{{ item.dayorigin | date: 'mediumDate'  }}  </p>
                                </div>
                            </li>
                            <!-- <li class="timeline-item">
                                <span class="timeline-point timeline-point-indicator"></span>
                                <div class="timeline-event">
                                    <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                        <h6>tzxORSXG5BQGAYQ-ssp-1992022</h6>

                                    </div>
                                    <p> <b>Latitud:</b> 19.841207 <br>
                                        <b>Longitud:</b> -98.981791 <br>
                                        <b>Fecha/Hora:</b>19/09/2022 - 22:34:05 hrs. </p>
                                </div>
                            </li>
                            <li class="timeline-item">
                                <span class="timeline-point timeline-point-indicator"></span>
                                <div class="timeline-event">
                                    <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                        <h6>tzxORSXG5BQGAYQ-ssp-1992022</h6>

                                    </div>
                                    <p> <b>Latitud:</b> 19.841207 <br>
                                        <b>Longitud:</b> -98.981791 <br>
                                        <b>Fecha/Hora:</b>19/09/2022 - 22:34:05 hrs. </p>
                                </div>
                            </li>
                            <li class="timeline-item">
                                <span class="timeline-point timeline-point-indicator"></span>
                                <div class="timeline-event">
                                    <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                        <h6>tzxORSXG5BQGAYQ-ssp-1992022</h6>

                                    </div>
                                    <p> <b>Latitud:</b> 19.841207 <br>
                                        <b>Longitud:</b> -98.981791 <br>
                                        <b>Fecha/Hora:</b>19/09/2022 - 22:34:05 hrs. </p>
                                </div>
                            </li> -->

                        </ul>
                    </div>
                </div>
                <!-- <div class="col-lg-12 col-md-12 col-12">
                <div class="card card-browser-states">
                    <div class="card-header">
                        <h4 class="card-title">Actividad</h4>

                    </div>
                    <div class="card-body">

                        <div class="browser-states head-st">
                            <div class="media">
                                <h6 class="align-self-center mb-0" style="text-align:center; color:white!important;">FOLIO</h6>
                            </div>
                            <div class="d-flex align-items-center">
                                <div class="font-weight-bold text-body-heading mr-1" style="text-align:center; color:white!important;">FECHA / HORA</div>

                            </div>
                            <div class="d-flex align-items-center" style="text-align:center; color:white!important;">
                                <div class="font-weight-bold text-body-heading mr-1" style="text-align:center; color:white!important;">USUARIO</div>
                            </div>
                        </div>

                        <div routerLink="/solicitud" class="browser-states">
                            <div class="media">
                                <h6 class="align-self-center mb-0">tzxORSXG5BQGAYQ-ssp-1992022</h6>
                            </div>
                            <div class="d-flex align-items-center">
                                <div class="font-weight-bold text-body-heading mr-1">19/09/2022 - 22:34:05 hrs.</div>

                            </div>
                            <div class="d-flex align-items-center">
                                <div class="font-weight-bold text-body-heading mr-1">ferbock10@gmail.com</div>
                            </div>
                        </div>
                        <div routerLink="/solicitud" class="browser-states">
                            <div class="media">
                                <h6 class="align-self-center mb-0">tzxORSXG5BQGAYQ-ssp-992022</h6>
                            </div>
                            <div class="d-flex align-items-center">
                                <div class="font-weight-bold text-body-heading mr-1">09/09/2022 - 12:09:45 hrs.</div>

                            </div>
                            <div class="d-flex align-items-center">
                                <div class="font-weight-bold text-body-heading mr-1">ferbock10@gmail.com</div>
                            </div>
                        </div>
                        <div routerLink="/solicitud" class="browser-states">
                            <div class="media">
                                <h6 class="align-self-center mb-0">tzxORSXG5BQGAYQ-ssp-792022</h6>
                            </div>
                            <div class="d-flex align-items-center">
                                <div class="font-weight-bold text-body-heading mr-1">07/09/2022 - 20:12:05 hrs.</div>

                            </div>
                            <div class="d-flex align-items-center">
                                <div class="font-weight-bold text-body-heading mr-1">ferbock10@gmail.com</div>
                            </div>
                        </div>
                        <div routerLink="/solicitud" class="browser-states">
                            <div class="media">
                                <h6 class="align-self-center mb-0">tzxORSXG5BQGAYQ-ssp-492022</h6>
                            </div>
                            <div class="d-flex align-items-center">
                                <div class="font-weight-bold text-body-heading mr-1">04/09/2022 - 20:12:05 hrs.</div>

                            </div>
                            <div class="d-flex align-items-center">
                                <div class="font-weight-bold text-body-heading mr-1">ferbock10@gmail.com</div>
                            </div>
                        </div>
                        <div routerLink="/solicitud" class="browser-states">
                            <div class="media">
                                <h6 class="align-self-center mb-0">tzxORSXG5BQGAYQ-ssp-192022</h6>
                            </div>
                            <div class="d-flex align-items-center">
                                <div class="font-weight-bold text-body-heading mr-1">01/09/2022 - 05:03:18 hrs.</div>

                            </div>
                            <div class="d-flex align-items-center">
                                <div class="font-weight-bold text-body-heading mr-1">ferbock10@gmail.com</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            </div>






        </div>
    </div>