<div class="content-wrapper container-xxl p-0">
    <div class="content-body">

        <app-content-header [contentHeader]="contentHeader"></app-content-header>


        <section class="invoice-add-wrapper">

            <div class="row invoice-add">
                <!-- Invoice Add Left starts -->
                <div class="col-xl-8 col-md-7 col-12">
                    <div class="card invoice-preview-card">
                        <div class="card-body invoice-padding pt-2">



                            <form [formGroup]="AlertForm" autocomplete="off" class="form form-vertical">




                                <div class="row">
                                    <div class="col-12">
                                        <div class="card-header" style="justify-content: center;">
                                            <h4 class="card-title">AGREGAR ALERTA</h4>
                                        </div>


                                        <div class="form-group">
                                            <label for="title-event">Titulo</label>
                                            <div class="input-group input-group-merge">

                                                <input [(ngModel)]="titulo_alerta" formControlName="titulo_alerta" name="titulo_alerta" class="form-control" type="text" class="form-control register">
                                            </div>
                                        </div>

                                        <span>
                                            Poner muchos espacios al final de la descripcion
                                        </span>
                                        <div class="form-group">
                                            <label for="title-event">Descripción breve</label>
                                            <div class="input-group input-group-merge">

                                                <textarea [(ngModel)]="descripcion_breve" formControlName="descripcion_breve" name="descripcion_breve" placeholder="De que trata..." cols="50" rows="10" minlength="10" maxlength="1800" class="form-control "></textarea>
                                            </div>
                                        </div>

                                        <fieldset class="form-group">
                                            <label for="picture">Subir Imagén:</label>

                                            <input name="imagen_evento" type="file" (change)="Onchange($event)" class="file-up">
                                        </fieldset>


                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6 col-12">

                                        <div class="form-group">
                                            <label for="date">Fecha:</label>
                                            <input [(ngModel)]="xaction" formControlName="xaction" name="fecha" type="date" class="form-control register">

                                        </div>


                                    </div>
                                    <div class="col-md-6 col-12">


                                        <div class="form-group">

                                            <label for="date">Fecha Expiración:</label>
                                            <input formControlName="xpiration" name="fecha" type="date" class="form-control register">

                                        </div>
                                    </div>

                                </div>

                                <div class="row">
                                    <div class="col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="link-button">Texto Botón</label>
                                            <div class="input-group input-group-merge">

                                                <input [(ngModel)]="btn_name" formControlName="btn_name" name="btn_name" type="text" id="link-button" class="form-control" placeholder="Enlace" />
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="text-button">Enlace Botón</label>
                                            <div class="input-group input-group-merge">

                                                <input [(ngModel)]="btn_url" formControlName="btn_url" name="btn_url" type="text" id="text-button" class="form-control" placeholder="Texto que aparecerá en el botón" />
                                            </div>
                                        </div>
                                    </div>

                                </div>











                                <button [disabled]="loading" (click)="Alertsender()" class="btn btn-secondary">
                                    {{ (loading)? 'Publicando...': 'Publicar' }}
                                </button>
                            </form>




                        </div>



                    </div>

                    <!-- Invoice Add Right starts -->
                    <div class="col-xl-4 col-md-5 col-12 mt-md-0 mt-2">




                    </div>


                </div>





                <div class="col-xl-4 col-md-5 col-12 mt-md-0 mt-2">


                    <div class="changue-view" style="text-align: center;">

                        <h5>Cambiar Vista</h5>


                        <div class="custom-control custom-switch custom-control-inline">
                            <label>Detalle</label> &nbsp;&nbsp;
                            <input [(ngModel)]="ChangueView" type="checkbox" class="custom-control-input" id="customSwitch1" />
                            <label class="custom-control-label" for="customSwitch1"> &nbsp;Lista</label>
                        </div>
                        <hr>
                        <br>



                    </div>



                    <div class="frame" *ngIf="ChangueView == false">
                        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" width="209.9" height="27.8" viewBox="0 0 209.9 27.8">
                            <defs>
                                <style>
                                    .cls-1 {
                                        fill: #1a1a1a;
                                    }

                                    .cls-2 {
                                        fill: #161616;
                                    }

                                    .cls-3 {
                                        fill: #111110;
                                    }
                                </style>
                            </defs>
                            <g>
                                <path
                                    d="M491.2,129.3H299.6v.7h8.2a9.3,9.3,0,0,1,9.3,9.3h0A19,19,0,0,0,336,157.1H473a19,19,0,0,0,18.9-17.7h0a9.3,9.3,0,0,1,9.4-9.3h8.2v-.7Zm-150.8,20a5.4,5.4,0,0,1-5.5-5.5,5.5,5.5,0,0,1,5.5-5.5,5.5,5.5,0,0,1,5.5,5.5A5.5,5.5,0,0,1,340.4,149.3Zm26.4,2.7a8.2,8.2,0,0,1-8.1-8.2,8.1,8.1,0,0,1,8.1-8.1,8.2,8.2,0,0,1,8.2,8.1A8.2,8.2,0,0,1,366.8,152Zm56.8-6.3H385.4a2.4,2.4,0,1,1,0-4.7h38.2a2.4,2.4,0,1,1,0,4.7Zm19.9,6.3a8.2,8.2,0,0,1-8.2-8.2,8.2,8.2,0,0,1,8.2-8.1,8.1,8.1,0,0,1,8.1,8.1A8.2,8.2,0,0,1,443.5,152Zm28.6-2.7a5.5,5.5,0,0,1-5.5-5.5,5.5,5.5,0,0,1,5.5-5.5,5.5,5.5,0,0,1,5.5,5.5A5.4,5.4,0,0,1,472.1,149.3Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path class="cls-1" d="M423.6,141H385.4a2.4,2.4,0,1,0,0,4.7h38.2a2.4,2.4,0,1,0,0-4.7Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path class="cls-2"
                                    d="M366.8,136.7a7.2,7.2,0,1,0,7.2,7.1A7.1,7.1,0,0,0,366.8,136.7Zm0,9.9a2.8,2.8,0,0,1-2.8-2.8,2.9,2.9,0,0,1,2.8-2.8,2.8,2.8,0,0,1,2.8,2.8A2.7,2.7,0,0,1,366.8,146.6Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path class="cls-3"
                                    d="M366.8,135.7a8.1,8.1,0,0,0-8.1,8.1,8.2,8.2,0,0,0,8.1,8.2,8.2,8.2,0,0,0,8.2-8.2A8.2,8.2,0,0,0,366.8,135.7Zm0,15.3a7.2,7.2,0,1,1,7.2-7.2A7.1,7.1,0,0,1,366.8,151Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path
                                    d="M366.8,141a2.9,2.9,0,0,0-2.8,2.8,2.8,2.8,0,0,0,2.8,2.8,2.7,2.7,0,0,0,2.8-2.8A2.8,2.8,0,0,0,366.8,141Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path class="cls-2"
                                    d="M443.5,136.7a7.2,7.2,0,1,0,7.1,7.1A7.1,7.1,0,0,0,443.5,136.7Zm0,9.9a2.8,2.8,0,0,1-2.9-2.8,2.9,2.9,0,0,1,5.7,0A2.8,2.8,0,0,1,443.5,146.6Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path class="cls-3"
                                    d="M443.5,135.7a8.2,8.2,0,0,0-8.2,8.1,8.2,8.2,0,0,0,8.2,8.2,8.2,8.2,0,0,0,8.1-8.2A8.1,8.1,0,0,0,443.5,135.7Zm0,15.3a7.2,7.2,0,1,1,7.1-7.2A7.2,7.2,0,0,1,443.5,151Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path d="M443.5,141a2.9,2.9,0,0,0-2.9,2.8,2.9,2.9,0,0,0,5.7,0A2.9,2.9,0,0,0,443.5,141Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path class="cls-2"
                                    d="M472.1,139a4.9,4.9,0,1,0,4.9,4.8A4.9,4.9,0,0,0,472.1,139Zm0,6.7a1.9,1.9,0,0,1-1.9-1.9,2,2,0,0,1,1.9-1.9,1.9,1.9,0,0,1,1.9,1.9A1.8,1.8,0,0,1,472.1,145.7Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path class="cls-3"
                                    d="M472.1,138.3a5.5,5.5,0,0,0-5.5,5.5,5.5,5.5,0,0,0,5.5,5.5,5.4,5.4,0,0,0,5.5-5.5A5.5,5.5,0,0,0,472.1,138.3Zm0,10.4a4.9,4.9,0,1,1,4.9-4.9A4.9,4.9,0,0,1,472.1,148.7Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path
                                    d="M472.1,141.9a2,2,0,0,0-1.9,1.9,1.9,1.9,0,0,0,1.9,1.9,1.8,1.8,0,0,0,1.9-1.9A1.9,1.9,0,0,0,472.1,141.9Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path class="cls-2"
                                    d="M340.4,139a4.9,4.9,0,0,0,0,9.7,4.9,4.9,0,0,0,0-9.7Zm0,6.7a1.8,1.8,0,0,1-1.9-1.9,1.9,1.9,0,0,1,1.9-1.9,2,2,0,0,1,1.9,1.9A1.9,1.9,0,0,1,340.4,145.7Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path class="cls-3"
                                    d="M340.4,138.3a5.5,5.5,0,0,0-5.5,5.5,5.4,5.4,0,0,0,5.5,5.5,5.5,5.5,0,0,0,5.5-5.5A5.5,5.5,0,0,0,340.4,138.3Zm0,10.4a4.9,4.9,0,1,1,4.8-4.9A4.9,4.9,0,0,1,340.4,148.7Z"
                                    transform="translate(-299.6 -129.3)" />
                                <circle cx="40.8" cy="14.6" r="1.9" />
                            </g>
                        </svg>
                        <div class="card-phone animate__fadeInLeft">
                            <div class="card preview-event-add">
                                <div _ngcontent-oip-c218="" class="header-preview">
                                    <div _ngcontent-oip-c218="" class="col left-item">
                                        <div _ngcontent-oip-c218="" class="eventIconHead">
                                            <i class="fa-solid fa-arrow-left"></i>
                                        </div>
                                        <div _ngcontent-oip-c218="" class="eventIconHead">
                                            <i class="fa-regular fa-arrow-up-right-from-square"></i>
                                        </div>
                                    </div>
                                </div>
                                <img [src]="url">
                                <section class="content-event">
                                    <h2 class="title-event">{{ titulo_alerta }}</h2>

                                    <div _ngcontent-oip-c218="" class="row time-date">

                                        <div _ngcontent-oip-c218="" class="col-5">
                                            <span class="fecha-event"><i
                                                    class="fa-duotone fa-calendar-days"></i>&nbsp;&nbsp;<a
                                                    _ngcontent-oip-c218="" href="" style="color: black;">{{ xaction |
                                                    date:'d MMM y'}}</a></span>
                                        </div>
                                    </div>


                                    <hr>
                                    <div class="row section-preview">
                                        <div class="col-md-12">
                                            <h3 class="organizer">{{ Organizador }}</h3>
                                        </div>
                                        <div class="col-md-12">
                                            <span class="DurEvent">{{ SelectedDate | date:'h:mm a'}} &#183; {{
                                                DuracionEvento }} </span>
                                        </div>
                                    </div>
                                    <hr>
                                    <br>
                                    <h3 class="organizer">Más detalles</h3>
                                    <div class="row section-preview">
                                        <div class="col-md-12">
                                            <p>{{ descripcion_breve }}</p>

                                        </div>
                                    </div>
                                    <hr>


                                    <div class="row section-preview" *ngIf="catego === 'Arte'">
                                        <div class="col-2">
                                            <span class="iconCate"><i class="fal fa-paint-brush"></i></span>

                                        </div>
                                        <div class="col-10 cateNAME">
                                            {{catego}}
                                        </div>
                                    </div>
                                    <div class="row section-preview" *ngIf="catego === 'Tecnología'">
                                        <div class="col-2">
                                            <span class="iconCate"><i class="fal fa-chart-network"></i></span>

                                        </div>
                                        <div class="col-10 cateNAME">
                                            {{catego}}
                                        </div>
                                    </div>
                                    <div class="row section-preview" *ngIf="catego === 'Deporte'">
                                        <div class="col-2">
                                            <span class="iconCate"> <i class="fal fa-biking"></i></span>

                                        </div>
                                        <div class="col-10 cateNAME">
                                            {{catego}}
                                        </div>
                                    </div>
                                    <hr>


                                </section>



                                <div class="footer-event">
                                    <div class="row">
                                        <div class="col-5">
                                            <h6 class="TitleFooter">{{titulo_alerta}}</h6>
                                        </div>
                                        <div class="col-7">
                                            <a *ngIf="btn_name" class="BtnFooter" style="text-align: center!important" href="{{btn_url}}}">{{
                                                btn_name }}</a>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>


                    <div style="background-color: transparent!important;" class="frame" *ngIf="ChangueView == true">
                        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" width="209.9" height="27.8" viewBox="0 0 209.9 27.8">
                            <defs>
                                <style>
                                    .cls-1 {
                                        fill: #1a1a1a;
                                    }

                                    .cls-2 {
                                        fill: #161616;
                                    }

                                    .cls-3 {
                                        fill: #111110;
                                    }
                                </style>
                            </defs>
                            <g>
                                <path
                                    d="M491.2,129.3H299.6v.7h8.2a9.3,9.3,0,0,1,9.3,9.3h0A19,19,0,0,0,336,157.1H473a19,19,0,0,0,18.9-17.7h0a9.3,9.3,0,0,1,9.4-9.3h8.2v-.7Zm-150.8,20a5.4,5.4,0,0,1-5.5-5.5,5.5,5.5,0,0,1,5.5-5.5,5.5,5.5,0,0,1,5.5,5.5A5.5,5.5,0,0,1,340.4,149.3Zm26.4,2.7a8.2,8.2,0,0,1-8.1-8.2,8.1,8.1,0,0,1,8.1-8.1,8.2,8.2,0,0,1,8.2,8.1A8.2,8.2,0,0,1,366.8,152Zm56.8-6.3H385.4a2.4,2.4,0,1,1,0-4.7h38.2a2.4,2.4,0,1,1,0,4.7Zm19.9,6.3a8.2,8.2,0,0,1-8.2-8.2,8.2,8.2,0,0,1,8.2-8.1,8.1,8.1,0,0,1,8.1,8.1A8.2,8.2,0,0,1,443.5,152Zm28.6-2.7a5.5,5.5,0,0,1-5.5-5.5,5.5,5.5,0,0,1,5.5-5.5,5.5,5.5,0,0,1,5.5,5.5A5.4,5.4,0,0,1,472.1,149.3Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path class="cls-1" d="M423.6,141H385.4a2.4,2.4,0,1,0,0,4.7h38.2a2.4,2.4,0,1,0,0-4.7Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path class="cls-2"
                                    d="M366.8,136.7a7.2,7.2,0,1,0,7.2,7.1A7.1,7.1,0,0,0,366.8,136.7Zm0,9.9a2.8,2.8,0,0,1-2.8-2.8,2.9,2.9,0,0,1,2.8-2.8,2.8,2.8,0,0,1,2.8,2.8A2.7,2.7,0,0,1,366.8,146.6Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path class="cls-3"
                                    d="M366.8,135.7a8.1,8.1,0,0,0-8.1,8.1,8.2,8.2,0,0,0,8.1,8.2,8.2,8.2,0,0,0,8.2-8.2A8.2,8.2,0,0,0,366.8,135.7Zm0,15.3a7.2,7.2,0,1,1,7.2-7.2A7.1,7.1,0,0,1,366.8,151Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path
                                    d="M366.8,141a2.9,2.9,0,0,0-2.8,2.8,2.8,2.8,0,0,0,2.8,2.8,2.7,2.7,0,0,0,2.8-2.8A2.8,2.8,0,0,0,366.8,141Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path class="cls-2"
                                    d="M443.5,136.7a7.2,7.2,0,1,0,7.1,7.1A7.1,7.1,0,0,0,443.5,136.7Zm0,9.9a2.8,2.8,0,0,1-2.9-2.8,2.9,2.9,0,0,1,5.7,0A2.8,2.8,0,0,1,443.5,146.6Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path class="cls-3"
                                    d="M443.5,135.7a8.2,8.2,0,0,0-8.2,8.1,8.2,8.2,0,0,0,8.2,8.2,8.2,8.2,0,0,0,8.1-8.2A8.1,8.1,0,0,0,443.5,135.7Zm0,15.3a7.2,7.2,0,1,1,7.1-7.2A7.2,7.2,0,0,1,443.5,151Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path d="M443.5,141a2.9,2.9,0,0,0-2.9,2.8,2.9,2.9,0,0,0,5.7,0A2.9,2.9,0,0,0,443.5,141Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path class="cls-2"
                                    d="M472.1,139a4.9,4.9,0,1,0,4.9,4.8A4.9,4.9,0,0,0,472.1,139Zm0,6.7a1.9,1.9,0,0,1-1.9-1.9,2,2,0,0,1,1.9-1.9,1.9,1.9,0,0,1,1.9,1.9A1.8,1.8,0,0,1,472.1,145.7Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path class="cls-3"
                                    d="M472.1,138.3a5.5,5.5,0,0,0-5.5,5.5,5.5,5.5,0,0,0,5.5,5.5,5.4,5.4,0,0,0,5.5-5.5A5.5,5.5,0,0,0,472.1,138.3Zm0,10.4a4.9,4.9,0,1,1,4.9-4.9A4.9,4.9,0,0,1,472.1,148.7Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path
                                    d="M472.1,141.9a2,2,0,0,0-1.9,1.9,1.9,1.9,0,0,0,1.9,1.9,1.8,1.8,0,0,0,1.9-1.9A1.9,1.9,0,0,0,472.1,141.9Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path class="cls-2"
                                    d="M340.4,139a4.9,4.9,0,0,0,0,9.7,4.9,4.9,0,0,0,0-9.7Zm0,6.7a1.8,1.8,0,0,1-1.9-1.9,1.9,1.9,0,0,1,1.9-1.9,2,2,0,0,1,1.9,1.9A1.9,1.9,0,0,1,340.4,145.7Z"
                                    transform="translate(-299.6 -129.3)" />
                                <path class="cls-3"
                                    d="M340.4,138.3a5.5,5.5,0,0,0-5.5,5.5,5.4,5.4,0,0,0,5.5,5.5,5.5,5.5,0,0,0,5.5-5.5A5.5,5.5,0,0,0,340.4,138.3Zm0,10.4a4.9,4.9,0,1,1,4.8-4.9A4.9,4.9,0,0,1,340.4,148.7Z"
                                    transform="translate(-299.6 -129.3)" />
                                <circle cx="40.8" cy="14.6" r="1.9" />
                            </g>
                        </svg>
                        <div class="card-phone animate__zoomIn" style=" 
                        z-index: 1;">
                            <div class="header-background">

                            </div>



                            <div class="hello" style="margin-top: 10px; position: absolute;
                            top: 15px;">
                                <div class="name-header">
                                    <div class="backto" onclick="window.history.go(-1);" style="margin-top: 0; font-size: 1.2rem; color: white; margin-bottom: .5rem;">
                                        <i class="fa-light fa-arrow-left"></i>
                                    </div>
                                    <h2 class="sub2" style="color: white;">Notificaciones</h2>

                                </div>



                            </div>


                            <section class="standard" style="position: absolute;
                            top: 95px;">
                                <div class="eventos notifications" style="background-color: white; ">


                                    <!-- <div class="empty-content">
                                        <img class="emptyImg" src="assets/img/illustrations/notifications-empty2.png" alt="">
                                        <div class="content-event" style="text-align: center;">
                                            <h6>Aún no tienes notificaciones...</h6>
                        
                                        </div>
                        
                                    </div> -->


                                    <a class="event noti visited">
                                        <div class="thumbnail-event" style="background-image: url({{url}})">

                                        </div>
                                        <div class="body-event">


                                            <div class="content-event" style="padding: 0!important ; ">
                                                <h6>{{titulo_alerta }}</h6>
                                                <span>{{ descripcion_breve }}</span>
                                            </div>
                                            <div class="more-event">

                                                <div class="hour">
                                                    {{ xaction | date:'d MMM y'}}
                                                </div>


                                            </div>
                                        </div>
                                    </a>

                                    <a class="event noti">
                                        <div class="thumbnail-event" style="background-image: url('https://cdn2.atraccion360.com/media/aa/styles/xlarge/public/images/2016/07/bache-24-portada.jpg');">

                                        </div>
                                        <div class="body-event">


                                            <div class="content-event" style="padding: 0!important">
                                                <h6>Alerta Ejemplo</h6>
                                                <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa
                                                    laborum tempora quidem sint
                                                    inventore quod repellat minima hic eum expedita earum rem placeat
                                                    fugit et perferendis,
                                                    labore beatae cum sapiente.</span>
                                            </div>
                                            <div class="more-event">

                                                <div class="hour">
                                                    &nbsp;11 abr 2023
                                                </div>


                                            </div>
                                        </div>
                                    </a>

                                    <a class="event noti">
                                        <div class="thumbnail-event" style="background-image: url('https://cdn2.atraccion360.com/media/aa/styles/xlarge/public/images/2016/07/bache-24-portada.jpg');">

                                        </div>
                                        <div class="body-event">


                                            <div class="content-event" style="padding: 0!important">
                                                <h6>Alerta Ejemplo 2</h6>
                                                <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa
                                                    laborum tempora quidem sint
                                                    inventore quod repellat minima hic eum expedita earum rem placeat
                                                    fugit et perferendis,
                                                    labore beatae cum sapiente.</span>
                                            </div>
                                            <div class="more-event">

                                                <div class="hour">
                                                    &nbsp;11 abr 2023
                                                </div>


                                            </div>
                                        </div>
                                    </a>






                                </div>
                            </section>



                            <div class="separator-bottom"></div>


                            <nav class="navigation-tab fixed-bottom">
                                <div class="navigation-tab-item stroke ">
                                    <span class="navigation-tab__icon">
                                        <!--<i class="fa-duotone fa-objects-column"></i> -->
                                        <i class="fa-light fa-objects-column"></i>

                                    </span>
                                    <span class="navigation-tab__txt">INICIO</span>

                                </div>
                                <div class="navigation-tab-item ">
                                    <span class="navigation-tab__icon">
                                        <i class="fa-light fa-bullhorn fa-rotate-by"
                                            style="--fa-rotate-angle: -25deg; font-size: 19px;"></i>
                                        <!--<i class="fa-duotone fa-bullhorn fa-rotate-by" style="--fa-rotate-angle: -25deg; font-size: 19px;"></i>-->
                                    </span>
                                    <span class="navigation-tab__txt">REPORTES</span>
                                </div>
                                <div class="navigation-tab-item">
                                    <span class="navigation-tab__icon">
                                        <i class="fa-light fa-brake-warning"></i>
                                        <!--<i class="fa-duotone fa-brake-warning"></i> -->

                                    </span>
                                    <span class="navigation-tab__txt">PÁNICO</span>
                                </div>
                                <div class="navigation-tab-item active">
                                    <span class="navigation-tab__icon">
                                        <!--   <i class="fa-light fa-bell"></i> -->
                                        <i class="fa-duotone fa-bell"></i>

                                    </span>
                                    <span class="navigation-tab__txt">ALERTAS</span>
                                </div>


                                <div class="navigation-tab-item  stroketwo">
                                    <span class="navigation-tab__icon">
                                        <i class="fa-light fa-ellipsis-stroke"></i>
                                        <!--<i class="fa-duotone fa-ellipsis-stroke"></i>-->
                                    </span>
                                    <span class="navigation-tab__txt">MÁS</span>
                                </div>

                            </nav>
                        </div>
                    </div>



                </div>
            </div>
        </section>
    </div>


    <!--/  Add New Customer Sidebar -->
</div>