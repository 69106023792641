import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { StorageService } from '../../services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DbService } from '../../services/transit-info.service';
import { Geodata, ITM } from '../../interfaces/watch-user';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './sspdetail.component.html',
  styleUrls: ['./sspdetail.component.css']
})
export class SspdetailComponent implements OnInit {

  @ViewChild('mapas', { static: true }) mapasElement!:ElementRef;
  Li:any[]=[];

  usrxfole:any;
  xusr:any;

  _mark:any;
  cluch = false;
  
  xtem:any;
  xfolios:any[]=[];

  Tmpfolcut1:any[]=[];
  Tmpfolcut2:any[]=[];
  Tmpfolcut3:any[]=[];
  Tmpfolcut4:any[]=[];
  Tmpfolcut5:any[]=[];
  Tmpfolcut6:any[]=[];
  Tmpfolcut7:any[]=[];
  
  TmpNameMarks:any[] = [];

  //Fole Detail
  DetailStack:any[]=[];
  
  //skelet
  ContSkelet:boolean = false;

  //Map details
  map!: google.maps.Map;
  marcadores: google.maps.Marker[] = [];
  infowindows: google.maps.InfoWindow[] = [];



  constructor(private route:ActivatedRoute,
    private router:Router,
    private storage:StorageService,
    private datePipe: DatePipe,
    private trasit:DbService){


} 

  public contentHeader: object

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.contentHeader = {
      headerTitle: 'TizAPP',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'SSC',
            isLink: true,
            link: '/ssp'
          },
          {
            name: 'Lista Activaciones',
            isLink: true,
            link: '/ssp-list'
          },
          {
            name: 'Folio',
            isLink: false
          }
        ]
      }
    }




    try{
      this.LoadMap();   
      this.usrxfole = this.route.snapshot.paramMap.get('informant'); 
      
      // const dat2 = this.route.snapshot.paramMap.get('ide');
      this.xusr = this.usrxfole.split('_');
      this.consulTDB(this.usrxfole);
    }
    catch(err){
      // alert('Comprueba tu conexion a internet');
      this.ContSkelet = true;
    }
    
    //  LISTA - historial de uso total x usuario
    // this.trasit.UsrxHistory(this.xusr[0]).subscribe( resp =>{
    
    //   if(resp){
    //     this.USR_History(resp.arch);
    //   }
    // });   
  }



  ngOnDestroy() {
    this.storage.CLEAN_FOLs();     
    this.storage.CLEAR_Cuota();
    this.Reset();
  }


  // ------------------- INITIALS ---------------------
  LoadMap(CoordsInfo?:Geodata){   
    const latLng = new google.maps.LatLng(19.8509038,-98.9658552);
    
    const mapOptions:google.maps.MapOptions = {
        center:latLng,
        zoom:14,
        mapTypeId: google.maps.MapTypeId.ROADMAP
    }

    this.map = new google.maps.Map(this.mapasElement.nativeElement, mapOptions);
  }

 

  Reset(){
    // check if this func is applycable
    this.marcadores = [];
    this.Tmpfolcut1 = [];
    this.Tmpfolcut2 = [];
    this.Tmpfolcut3 = [];
    this.Tmpfolcut4 = [];
    this.Tmpfolcut5 = [];
    this.Tmpfolcut6 = [];
    this.Tmpfolcut7 = [];
  }

// ------------------- BY COUTE ---------------------
  get cuot1(){
    return this.storage.TypeUno;
  }
  get cuot2(){
    return this.storage.TypeDue;
  }
  get cuot3(){
    return this.storage.TypeTree;
  }
  get cuot4(){
    return this.storage.TypeCuatro;
  }
  get cuot5(){
    return this.storage.TypeCinque;
  }
  get cuot6(){
    return this.storage.TypeSei;
  }
  get cuot7(){
    return this.storage.TypeSette;
  }

  
  ActivityDetails(coords:any){
    this.DetailStack = [coords,...this.DetailStack]; 
    // console.log('Stack_:',this.DetailStack)
  }

  consulTDB(data?:any){      

    this.trasit.UsrxInfo(data).subscribe( resp => {  
      if(resp){
        // console.log("Query::",resp);
        const coordStack = resp.arch.coordsxusr;
        const kyname = resp.arch.usrxfolio;

        this.PreLoad(coordStack,kyname);
        this.ActivityDetails(coordStack);
      }
    });
  }

  async PreLoad(data:any,kname:any){
    
    // ------------------- Open Fole ---------------------
    // (estilo CSS para indicar abierto / usar fecha para verificar si fue abierto/ prox base de datos)

    const format = new Date();
    const out = format.toLocaleDateString();
    const lapuerta = [out,true];
    await this.storage.FOLES_OPEN(lapuerta,kname);
    

    // ------------------- Principal Flow ---------------------

    await this.storage.FOLES_POST(data,kname);
    const TempsF = await this.storage.FOLES_GET(kname);
    this.storage.Filterby_cuota(TempsF);
            
    // console.log('Cuota1_: ',this.cuot1);      
      if(this.cuot1.data.length > 0){
        await this.PRE_VIEW(this.cuot1,this.usrxfole,this.cuot1.total);
      }
      if(this.cuot2.data.length > 0){
        await this.PRE_VIEW(this.cuot2,this.usrxfole,this.cuot2.total);
      }
      if(this.cuot3.data.length > 0){        
        await this.PRE_VIEW(this.cuot3,this.usrxfole,this.cuot3.total);
      }
      if(this.cuot4.data.length > 0){
        await this.PRE_VIEW(this.cuot4,this.usrxfole,this.cuot4.total);
      }
      if(this.cuot5.data.length > 0){
        await this.PRE_VIEW(this.cuot5,this.usrxfole,this.cuot5.total);
      }
      if(this.cuot6.data.length > 0){        
        await this.PRE_VIEW(this.cuot6,this.usrxfole,this.cuot6.total);
      }
      if(this.cuot7.data.length > 0){          
        await this.PRE_VIEW(this.cuot7,this.usrxfole,this.cuot7.total);          
      }

  }
  // Goto(fole:any){ next version  | last Update 25 Jul 23 
  //   console.log('IN>>',fole)
  //   this.router.navigate(['/panel/',fole]);
  // }

  // MAP INSERTION
  
  async PRE_VIEW(coords:any,usr:any,total:any){
    
    const content = coords.data;       
    for(let i = 0; i <  content.length;i++){

      this.AddMark(usr, content[i].lat, content[i].lng, content[i].dayorigin, content[0].usrxcuota,total);
    }      
  } 

  //Per cuote
  AddMark(usr:any,lat:any,lng:any,date:any,xcuota:any,total:number){

      if(!this.TmpNameMarks.includes(usr)){   
        
        this.TmpNameMarks.push(usr);
        // console.log('USRADDED:',this.TmpNameMarks);

        this.MarkStyle(usr,'../../../assets/icons/usrx-32x32.png', lat,lng ,date, xcuota);
      }
      else if(this.TmpNameMarks.includes(usr) ){
        
        if(this.Tmpfolcut1.length != total || this.Tmpfolcut2.length != total || this.Tmpfolcut3.length != total || this.Tmpfolcut4.length != total || this.Tmpfolcut5.length != total || this.Tmpfolcut5.length != total || this.Tmpfolcut7.length != total){

          // console.log('xPoint',this.Tmpfolcut1.length);
          this.MarkStyle(usr,'../../../assets/icons/usrxpoint-18x18.png',lat,lng ,date, xcuota);
        }
        if(this.Tmpfolcut1.length == total){
          this.MarkStyle(usr,'../../../assets/icons/usrxfin-32x32.png',lat,lng,date,xcuota);          
        }


        //  CUT 2
        if(this.Tmpfolcut2.length == 1){
          this.MarkStyle(usr,'../../../assets/icons/usrx-32x32.png',lat,lng ,date, xcuota);
        }      
        if(this.Tmpfolcut2.length == total+1){
          this.MarkStyle(usr,'../../../assets/icons/usrxfin-32x32.png',lat,lng,date,xcuota);          
        }

        //  CUT 3
        if(this.Tmpfolcut3.length == 1){
          this.MarkStyle(usr,'../../../assets/icons/usrx-32x32.png',lat,lng ,date, xcuota);
        }      
        if(this.Tmpfolcut3.length == total+1){
          this.MarkStyle(usr,'../../../assets/icons/usrxfin-32x32.png',lat,lng,date,xcuota);          
        }

        //  CUT 4
        if(this.Tmpfolcut4.length == 1){
          this.MarkStyle(usr,'../../../assets/icons/usrx-32x32.png',lat,lng ,date, xcuota);
        }      
        if(this.Tmpfolcut4.length == total+1){
          this.MarkStyle(usr,'../../../assets/icons/usrxfin-32x32.png',lat,lng,date,xcuota);          
        }

        //  CUT 5
        if(this.Tmpfolcut5.length == 1){
          this.MarkStyle(usr,'../../../assets/icons/usrx-32x32.png',lat,lng ,date, xcuota);
        }      
        if(this.Tmpfolcut5.length == total+1){
          this.MarkStyle(usr,'../../../assets/icons/usrxfin-32x32.png',lat,lng,date,xcuota);          
        }

        //  CUT 6
        if(this.Tmpfolcut6.length == 1){
          this.MarkStyle(usr,'../../../assets/icons/usrx-32x32.png',lat,lng ,date, xcuota);
        }      
        if(this.Tmpfolcut6.length == total+1){
          this.MarkStyle(usr,'../../../assets/icons/usrxfin-32x32.png',lat,lng,date,xcuota);          
        }

        //  CUT 7
        if(this.Tmpfolcut7.length == 1){
          this.MarkStyle(usr,'../../../assets/icons/usrx-32x32.png',lat,lng ,date, xcuota);
        }      
        if(this.Tmpfolcut7.length == total+1){
          this.MarkStyle(usr,'../../../assets/icons/usrxfin-32x32.png',lat,lng,date,xcuota);          
        }
        
      } 
      else{
        return;
      }

  }

  // :: Common Tool ::
  MarkStyle(usr:any,dir:string,lat:any,lng:any,date:any,cuote:any){''
    
    try{
        
      const latLng = new google.maps.LatLng(lat,lng);
      const _mark = new google.maps.Marker({
        map: this.map,       
        position:latLng,
        draggable:false,
        title:usr,
        // label:date,
        icon:dir
      });

      this.marcadores.push(_mark);
      // console.log('MARCAS:',this.marcadores);

        if(cuote == 1){
          this.Tmpfolcut1.push(_mark);
        }
        if(cuote == 2){
          this.Tmpfolcut2.push(_mark);
        }
        if(cuote == 3){
          this.Tmpfolcut3.push(_mark);
        }
        if(cuote == 4){
          this.Tmpfolcut4.push(_mark);
        }
        if(cuote == 5){
          this.Tmpfolcut5.push(_mark);
        }
        if(cuote == 6){
          this.Tmpfolcut6.push(_mark);
        }
        if(cuote == 7){
          this.Tmpfolcut7.push(_mark);
        }      

      const ilday = this.datePipe.transform(date,'medium');
      // CONTENT INFO /ADD TIME
      const content = `<h2>Solicito: | ${usr} |</h2>
          <br>
          <p>Lat: ${lat}</p>
          <p>Lng: ${lng}</p>
          <p>Tiempo: ${ilday} </p>
          <br>
      `;
      const infowindow = new google.maps.InfoWindow({
        content:content
      });
      this.infowindows.push(infowindow);
      // LISTENERS
      google.maps.event.addListener(_mark,'click',(coords) =>{      
        this.infowindows.forEach(infow => infow.close() )
        infowindow.open(this.map,_mark);
        
      });  
    }
    catch(error){
      return;
    }
  }

 
  

  
}
