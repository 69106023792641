<div *ngIf='!identity' class="auth-wrapper auth-v2">
    <div class="auth-inner row m-0">
        <!-- Brand logo-->
        <a class="brand-logo" href="javascript:void(0);">
            <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="28" />
            <h2 class="brand-text text-primary ml-1">TizApp</h2>
        </a>
        <!-- /Brand logo-->
        <!-- Left Text-->
        <div class="d-none d-lg-flex col-lg-8 align-items-center p-5">
            <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                <img class="img-fluid" [src]="
            coreConfig.layout.skin === 'dark'
              ? 'assets/images/pages/login-v2-dark.svg'
              : 'assets/images/pages/login-v2.svg'
          " alt="Login V2" />
            </div>
        </div>
        <!-- /Left Text-->
        <!-- Login-->
        <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
            <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
                <h2 class="card-title font-weight-bold mb-1">Bienvenido! 👋</h2>
                <p class="card-text mb-2">Inicia sesión para continuar</p>

                <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                    <div class="alert-body">
                        <p>{{ error }}</p>
                    </div>
                </ngb-alert>
                <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="errorMessage">
                    <div class="alert-body">
                        <p>{{ errorMessage }}</p>
                    </div>
                </ngb-alert>

                <form class="auth-login-form mt-2" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label class="form-label" for="mail">Correo</label>
                        <input class="form-control" type="email" #email="ngModel" [(ngModel)]="user.mail" name="mail" required id="login-mail" type="text" placeholder="" aria-describedby="login-mail" autofocus="" tabindex="1" />
                        <div *ngIf="!loginForm.controls['mail'].valid && loginForm.controls['mail']?.touched" class="invalid-feedback">
                            <div> El correo es obligatorio</div>

                        </div>
                    </div>
                    <div class="form-group">
                        <div class="d-flex justify-content-between">
                            <label for="login-password">Contraseña</label>
                        </div>
                        <div class="input-group input-group-merge form-password-toggle">
                            <input class="form-control form-control-merge" type="password" #password="ngModel" [(ngModel)]="user.passid" required autocomplete="off" id="passid" name="passid" placeholder="" aria-describedby="passid" tabindex="2" />
                            <div class="input-group-append">
                                <span class="input-group-text cursor-pointer"><i class="feather font-small-4" [ngClass]="{
                      'icon-eye-off': passidTextType,
                      'icon-eye': !passidTextType
                    }" (click)="togglePassidTextType()"></i></span>
                            </div>
                        </div>
                        <div *ngIf="!loginForm.controls['passid'].valid && loginForm.controls['passid']?.touched" class="invalid-feedback">
                            <div *ngIf="loginForm.controls['passid']?.touched">La contraseña es obligatoria</div>
                        </div>
                    </div>

                    <button [disabled]="loading" class="btn btn-primary btn-block" tabindex="4" rippleEffect>
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Iniciar Sesión
          </button>
                </form>

            </div>
        </div>
        <!-- /Login-->
    </div>
</div>