<div class="content-wrapper container-xxl p-0">
    <div class="content-body">

        <app-content-header [contentHeader]="contentHeader"></app-content-header>


        <section class="invoice-add-wrapper">

            <div class="row invoice-add">
                <!-- Invoice Add Left starts -->
                <div class="col-xl-8 col-md-7 col-12">
                    <div class="card invoice-preview-card">
                        <div class="card-body invoice-padding pt-2">



                            <form [formGroup]="GionaleForm" autocomplete="off" class="form form-vertical">




                                <div class="row">
                                    <div class="col-12">
                                        <div class="card-header" style="justify-content: center;">
                                            <h4 class="card-title">AGREGAR NOTICIA</h4>
                                        </div>


                                        <div class="form-group">
                                            <label for="title-event">Titulo</label>
                                            <div class="input-group input-group-merge">

                                                <input formControlName="titulo_noticia" name="titulo_noticia" class="form-control" type="text" class="form-control register">
                                            </div>
                                        </div>


                                        <div class="form-group">
                                            <label for="title-event">Descripción breve</label>
                                            <div class="input-group input-group-merge">

                                                <textarea formControlName="descripcion_breve" name="descripcion_breve" placeholder="De que trata..." cols="50" rows="10" minlength="10" maxlength="800" class="form-control"></textarea>
                                            </div>
                                        </div>

                                        <fieldset class="form-group">
                                            <label for="picture">Subir Imagén:</label>

                                            <input name="portada" type="file" (change)="Onchange($event)" class="file-up">
                                        </fieldset>


                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6 col-12">

                                        <div class="form-group">

                                            <label for="">Autor</label>
                                            <input formControlName="autor" name="autor" type="text" class="form-control">

                                        </div>


                                    </div>
                                    <div class="col-md-6 col-12">


                                        <div class="form-group">
                                            <label for="date">Fecha Expiración:</label>
                                            <input formControlName="xpiration" name="fecha" type="date" class="form-control register">

                                        </div>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">

                                            <label for="content">Contenido:</label>
                                            <quill-editor  formControlName="content" [(ngModel)]="snowEditorRef" [modules]="{ syntax: true, toolbar: [['code-block']] }">
                                                <div quill-editor-toolbar>
                                                    <span class="ql-formats">
                                                    <button class="ql-bold" [title]="'Bold'"></button>
                                                    <button class="ql-italic" [title]="'italic'"></button>
                                                    <button class="ql-underline" [title]="'underline'"></button>
                                                  </span>
                                                    <span class="ql-formats">
                                                    <select class="ql-align" [title]="'Alignment'">
                                                      <option selected></option>
                                                      <option value="center"></option>
                                                      <option value="right"></option>
                                                      <option value="justify"></option>
                                                    </select>
                                                  </span>
                                                    <span class="ql-formats">
                                                    <button class="ql-list" value="ordered" type="button"></button>
                                                  </span>
                                                    <span class="ql-formats">
                                                    <button class="ql-link" value="ordered" type="button"></button>
                                                    <button class="ql-image" value="ordered" type="button"></button>
                                                    <button class="ql-video" value="ordered" type="button"></button>
                                                  </span>
                                                    <span class="ql-formats">
                                                    <button class="ql-formula" value="ordered" type="button"></button>
                                                    <button class="ql-code-block" value="ordered" type="button"></button>
                                                  </span>
                                                    <span class="ql-formats">
                                                    <button class="ql-clean" value="ordered" type="button"></button>
                                                  </span>
                                                </div>
                                            </quill-editor>


                                        </div>
                                    </div>
                                </div>

                                <div class="row">

                                    <div class="col-md-6 col-6">
                                        <div class="form-group">
                                            <label for="text-button">Nombre Botón</label>
                                            <div class="input-group input-group-merge">

                                                <input formControlName="gotoName" name="gotoName" type="text" id="text-button" class="form-control" placeholder="Texto que aparecerá en el botón" [(ngModel)]="NombreBoton" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-6">
                                        <div class="form-group">
                                            <label for="text-button">Enlace Botón</label>
                                            <div class="input-group input-group-merge">

                                                <input formControlName="goto" name="goto" type="text" id="text-button" class="form-control" placeholder="Enlace al que envía el botón" [(ngModel)]="EnlaceBoton" />
                                            </div>
                                        </div>
                                    </div>

                                </div>











                                <button [disabled]="loading" (click)="Newsender()" class="btn btn-secondary">
                                    {{ (loading)? 'Publicando...': 'Publicar' }}
                                </button>
                            </form>




                        </div>



                    </div>

                    <!-- Invoice Add Right starts -->
                    <div class="col-xl-4 col-md-5 col-12 mt-md-0 mt-2">


                        {{snowEditorRef}}

                    </div>


                </div>

            </div>
        </section>
    </div>


    <!--/  Add New Customer Sidebar -->
</div>