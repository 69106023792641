import { Injectable } from '@angular/core';

import { DatePipe } from '@angular/common';
import { openDB, deleteDB, wrap, unwrap } from 'idb';

import { DbService } from './transit-info.service';


@Injectable({
  providedIn: 'root'
})

export class StorageService  {

  db:any;
  _xfoles:any[] = [];
  private LiveCoords:any[] = [];
  private liveOut:any[] = [];
  
  private _TemUsr:any[]=[];

  private _typ1:any[] = [];
  private _typ2:any[] = [];
  private _typ3:any[] = [];
  private _typ4:any[] = [];
  private _typ5:any[] = [];
  private _typ6:any[] = [];
  private _typ7:any[] = [];
  
  private _WaitLi:any[] = [];
  

  fecha = this.datePipe.transform(Date.now(),'shortDate');
  Ilmomento = this.datePipe.transform(Date.now(),'a');

  day:any = this.datePipe.transform(Date.now(),'d');
  time:any = this.datePipe.transform(Date.now(),'shortTime');
  hour:any  = this.datePipe.transform(Date.now(),'H');
  minut:any = this.datePipe.transform(Date.now(),'mm');
  
  constructor(private datePipe: DatePipe,
              private transit:DbService, ) {
    this.SaveLiveAction(); 
  }

  get TypeUno(){

    const pre = {
      'data':this._typ1,
      'cuot':1,
      'total':this._typ1.length
    }
    return pre;
  }
  get TypeDue(){
    const pre = {
      'data':this._typ2,
      'cuot':2,
      'total':this._typ2.length
    }
    return pre;
  }
  get TypeTree(){
    const pre = {
      'data':this._typ3,
      'cuot':3,
      'total':this._typ3.length
    }
    return pre;
  }
  get TypeCuatro(){
    const pre = {
      'data':this._typ4,
      'cuot':4,
      'total':this._typ4.length
    }
    return pre;    
  }
  get TypeCinque(){
    const pre = {
      'data':this._typ5,
      'cuot':5,
      'total':this._typ5.length
    }
    return pre;
  }
  get TypeSei(){
    const pre = {
      'data':this._typ6,
      'cuot':6,
      'total':this._typ6.length
    }
    return pre;    
  }
  get TypeSette(){
    const pre = {
      'data':this._typ7,
      'cuot':7,
      'total':this._typ7.length
    }
    return pre;
  }

  async SaveLiveAction(){

    const idb = await openDB('SSC',1,{      
      upgrade(db){
        db.createObjectStore('LiveUsr');
        db.createObjectStore('TempUsr');

        // db.createObjectStore('?');

        db.createObjectStore('TempFol');
        db.createObjectStore('FoleOpen');
        
      }
    });
    // idb.getAll('WaitList',)
    return this.db = idb;    
  }

  // ----------------- SET LIVE USRs -------------------------

  async ADD_LIVE_USR(stack:any, usrKey:any){
    
  const fole = await this.db.get('LiveUsr', usrKey);// first return undefined
  this.LiveCoords = stack;
  // console.log("Stack_Coords:::",this.LiveCoords);

  // this.PurgeFlow(); function config

    if(fole != undefined){

      for(let i = 0; i < stack.length;i++){
        
        if(this.LiveCoords[i].usrxgene.includes(usrKey)){

          // console.log('Topequal',this.liveF[i]);
          this.LiveCoords.forEach(e => {
            if(e.usrxgene == usrKey){
              this.liveOut.push(e);
            }            
          });          
          // console.log('Out:',this.liveOut);
          await this.db.put('LiveUsr', this.liveOut,usrKey);  
          return this.liveOut = [];
        }

      }
    }
    else if(fole == undefined){
      // console.log('CCREATO');
      for(let i = 0; i < stack.length;i++){
        
        if(!this.LiveCoords[i].usrxgene != usrKey){

          this.LiveCoords.forEach(e => {
            if(e.usrxgene == usrKey){
              this.liveOut.push(e);
            }
          });

          // console.log('aftePush',this.liveOut);
          await this.db.put('LiveUsr', this.liveOut,usrKey);  
          return this.liveOut = [];
        }
        else if(this.LiveCoords[i].usrxgene != usrKey){          
          // CHECH THIs QUE puede retornar
          this.liveOut = [...stack[i]];

          // console.log('NOT_INCLUDES(NEW ITEM):',this.liveOut);
          return await this.db.add('LiveUsr', this.liveOut,usrKey);  
        }
      }
      
    }      
  }

  async ENTRY_NOTIFY(usrVal:any, key:any){ 
  
    const entrys = await this.db.get('TempUsr','ListEntry');  
    if(entrys == undefined){
                  
      this._TemUsr.push(usrVal);
      
      const sound = new Audio('../../assets/audio/kasero.wav');
      sound.play();

      return await this.db.add('TempUsr', this._TemUsr, key);
    }    
    else if( entrys != undefined){

      const contains = entrys;
        if(!contains.includes(usrVal) ){          
                            
          this._TemUsr.push(usrVal);          
          
          const sound = new Audio('../../assets/audio/kasero.wav');
          sound.play();

          return await this.db.put('TempUsr', this._TemUsr, key);
        }      
    }  
  } 
  
  // ----------------- GET BACKUP USR -------------------------

  async GET_DATA_BY_BACKUP(kname:any){      
    const fole = await this.db.get('LiveUsr', kname);        
    return await fole;
  }
  async ENTRIES_BACKUP(){
    const temps = await this.db.get('TempUsr','ListEntry');
    return temps;
  }

  // ----------------- Purge LiveUsr  -------------------------

  async Filterby_Exp(data:any){
    
    const day:any  = this.datePipe.transform(data.report_date,'d');
    // console.log("Today::",parseInt(this.day),' - DOCday::', parseInt(day));
      
      if(parseInt(this.day) >  parseInt(day)){
        
        this.Timevaluator(data);
      }
      else{
        //si el dia es 31 borrar historial general y hacer corte / util?
        return;
      }          
  }
  async Timevaluator(usr:any) {      

    const Ihour:any  = this.datePipe.transform(usr.rescuexuser.dayreport,'H');
    const Iminut:any = this.datePipe.transform(usr.rescuexuser.dayreport,'mm');
    const Imoment    = this.datePipe.transform(usr.rescuexuser.dayreport,'a'); // AM - PM
    
    let Realhour = parseInt(this.hour); 
    let Realmin  = parseInt(this.minut); 
    
    let Dochour  = parseInt(Ihour);       // # Hour   - Doc-hour
    let Docmin   = parseInt(Iminut);      // # Mint   - Doc-mint

    // console.log("RealHour::",Realhour);
    // console.log("DocHour::",Dochour);
    
    if(Realhour == Dochour && Realmin >= Docmin){ // DEL if is Major
      if(this.Ilmomento == Imoment){ 
        await this.db.delete('LiveUsr',usr.usrxgene);  
        await this.CLEAN_TempUsr(usr.usrxgene);      
      }
      
    }
    
    // 1:59 para 2:00 
    else if(Realhour > Dochour && Realmin < Docmin){ // DEL if is Major
      if(this.Ilmomento == Imoment){ 
        await this.db.delete('LiveUsr',usr.usrxgene);  
        await this.CLEAN_TempUsr(usr.usrxgene);        
      }      
    }

    else if( Realhour > Dochour){ // DEL if Hour is Major.  
      if(this.Ilmomento == Imoment){      
        await this.db.delete('LiveUsr',usr.usrxgene); 
        await this.CLEAN_TempUsr(usr.usrxgene);    
      }
    }
  }


  // ----------------- TEMP ARCHIVE (analitcs)----------------------
  // Remplazar x valor implicito en DB(analitica de atencion de reporte)
  async FOLES_OPEN(val:any, key:any){
    return await this.db.put('FoleOpen', val,key);
  }


  // -------------------- FOLE DETAIL -------------------------
  async FOLES_POST(val:any, key:any){ 
    return await this.db.add('TempFol', val,key);          
  }
  async FOLES_GET(kname:any){
      
    const fole = await this.db.get('TempFol', kname);
    
    this._xfoles = await fole;
    return this._xfoles;  
  }
  Filterby_cuota(data:any){
    let Conduct2 = data;

    for(let fol = 0; fol < Conduct2.length;fol++){
      //Evaluation      
      if(Conduct2[fol].usrxcuota == 1){    
        this._typ1.push(Conduct2[fol]) ; 
        // console.log('Conduct2_cuota1: ',this._typ1);      
      }
      if(Conduct2[fol].usrxcuota == 2){
        this._typ2.push(Conduct2[fol]);  
      }
      if(Conduct2[fol].usrxcuota == 3){      
        this._typ3.push(Conduct2[fol]); 
      }
      if(Conduct2[fol].usrxcuota == 4){
        console.log(4);
        this._typ4.push(Conduct2[fol]);       
      }
      if(Conduct2[fol].usrxcuota == 5){
        this._typ5.push(Conduct2[fol]);       
      }
      if(Conduct2[fol].usrxcuota == 6){
        this._typ6.push(Conduct2[fol]);       
      }
      if(Conduct2[fol].usrxcuota == 7){
        this._typ7.push(Conduct2[fol]);       
      }  
    }    
  }
 
  // ------------------- PURGE TEMPS ---------------------

  //>> SubRutine
  async CLEAN_TempUsr(kname:any){
    const usr = await this.db.get('TempUsr', 'ListEntry');

    if(usr.includes(kname)){

      const dats = usr.filter((item:any) => item != kname)
      await this.db.put('TempUsr', dats, 'ListEntry');
      
      // console.log('Restructurando..');
    }

  }

  async CLEAN_FOLs(){
    
    return await this.db.clear('TempFol');
  }
  async CLEAR_Cuota(){
    this._typ1 = [];
    this._typ2 = [];
    this._typ3 = [];
    this._typ4 = [];
    this._typ5 = [];
    this._typ6 = [];
    this._typ7 = [];
  }

  // ------------------- Analitics ---------------------

}