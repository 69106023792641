<div class="content-wrapper container-xxl p-0">
    <div class="content-body">

        <app-content-header [contentHeader]="contentHeader"></app-content-header>


        <section class="invoice-add-wrapper">

            <div class="row invoice-add">
                <!-- Invoice Add Left starts -->
                <div class="col-xl-8 col-md-7 col-12">
                    <div class="card invoice-preview-card">
                        <div class="card-body invoice-padding pt-2">


                            <form [formGroup]="Banner" autocomplete="off">

                                <div class="form-group material-style">
                                    <label>Banner Municipio:</label>
                                    <input name="banner_municip" type="file" (change)="Bannr5Onchange($event)" class="form-control register">
                                </div>
                                <div class="form-group material-style">
                                    <input formControlName="link_municip" name="link_one" type="text" class="form-control register">
                                    <label>URL Municipal:</label>
                                </div>
                                <!-- <div class="form-group material-style">
                                    <input formControlName="xpiration" name="fecha" type="date" class="form-control register">
                                    <label>Expiración banner Municipal:</label>
                                </div> -->

                                <br>
                                <hr>

                                <!-- 
                                <div class="form-group ">
                                    <input name="banner_uno" type="file" (change)="Bannr1Onchange($event)" class="form-control ">
                                    <label>Banner 1:</label>
                                </div>
                                <div class="form-group">
                                    <input name="banner_dos" type="file" (change)="Bannr2Onchange($event)" class="form-control ">
                                    <label>Banner 2:</label>
                                </div>
                                <div class="form-group ">
                                    <input name="banner_tres" type="file" (change)="Bannr3Onchange($event)" class="form-control ">
                                    <label>Banner 3:</label>
                                </div>
                                <div class="form-group ">
                                    <input name="banner_cuatro" type="file" (change)="Bannr4Onchange($event)" class="form-control ">
                                    <label>Banner 4:</label>
                                </div>
                                <div class="form-group ">
                                    <input formControlName="link_one" name="link_one" type="text" class="form-control ">
                                    <label>URL 1:</label>
                                </div>
                                <div class="form-group ">
                                    <input formControlName="link_two" name="link_two" type="text" class="form-control ">
                                    <label>URL 2:</label>
                                </div>
                                <div class="form-group">
                                    <input formControlName="link_three" name="link_three" type="text" class="form-control ">
                                    <label>URL 3:</label>
                                </div>
                                <div class="form-group ">
                                    <input formControlName="link_four" name="link_four" type="text" class="form-control ">
                                    <label>URL 4:</label>
                                </div> -->




                                <button [disabled]="loading" (click)="Bannersender()" class="btn btn-secondary">
                              {{ (loading)? 'Publicando...': 'Publicar' }}
                          </button>
                            </form>



                        </div>



                    </div>

                    <!-- Invoice Add Right starts -->
                    <div class="col-xl-4 col-md-5 col-12 mt-md-0 mt-2">




                    </div>


                </div>

            </div>
        </section>
    </div>


    <!--/  Add New Customer Sidebar -->
</div>