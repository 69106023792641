

import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnMode, DatatableComponent,  } from '@swimlane/ngx-datatable';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { locale as en } from './i18n/en'
import { locale as fr } from './i18n/fr'
import { locale as de } from './i18n/de'
import { locale as pt } from './i18n/pt'
import { SafeStylePipe } from './safe-styles.pipe'; 
import { map } from 'rxjs/operators';
import { Router, ActivatedRoute, Params, ActivatedRouteSnapshot } from '@angular/router';
import { Report } from 'app/models/tzxcityreports';
import { ReportService } from 'app/services/report.service';
import { AdminService } from 'app/services/admin.service';
import { CoreTranslationService } from '@core/services/translation.service'
import { environment } from 'environments/environment';
import * as XLSX from 'xlsx';
import { Subject, fromEvent } from 'rxjs';
import { CoreConfigService } from '@core/services/config.service';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { GlobalConfig, ToastrService } from 'ngx-toastr';
import { CustomToastrComponent } from 'app/main/extensions/toastr/custom-toastr/custom-toastr.component';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexStroke, ApexTooltip } from 'ng-apexcharts';
import { HttpClient } from '@angular/common/http';
import { colors } from 'app/colors.const';
import { DomSanitizer } from '@angular/platform-browser';

export interface ChartOptions {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  stroke: ApexStroke;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  fill: ApexFill;
  colors: string[];
}
@Component({
  selector: 'app-sample',
  templateUrl: './sample.component.html',
  providers: [AdminService, ReportService, SafeStylePipe],
  styleUrls: ['./sample.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SampleComponent implements OnInit {
  currentPage: number = 2;
  //   @ViewChild('editTmpl', {static: true}) editTmpl:TemplateRef<any>;
  // @ViewChild('hdrtpl', {static: true}) hdrtpl:TemplateRef<any>;
  @ViewChild(NgbPopover, { static: true }) popover: NgbPopover;
   @Input('mode') mode:string = 'page'
  @ViewChild('imageTmpl', { static: true }) imageTmpl: TemplateRef<any>;
  @ViewChild('reference', { static: true }) reference: TemplateRef<any>;
  @ViewChild('viewTmpl', { static: true }) viewTmpl: TemplateRef<any>;
  @ViewChild('hdrTpl', { static: true }) hdrTpl: TemplateRef<any>;
  @ViewChild('statisticsBarRef') statisticsBarRef: any;
  @ViewChild('statisticsLineRef') statisticsLineRef: any;
  @ViewChild('gainedChartoptionsRef') gainedChartoptionsRef: any;
  @ViewChild('revenueChartoptionsRef') revenueChartoptionsRef: any;
  @ViewChild('salesChartoptionsRef') salesChartoptionsRef: any;
  @ViewChild('orderChartoptionsRef') orderChartoptionsRef: any;
  @ViewChild('trafficChartoptionsRef') trafficChartoptionsRef: any;
  @ViewChild('userChartoptionsRef') userChartoptionsRef: any;
  @ViewChild('newsletterChartoptionsRef') newsletterChartoptionsRef: any;



   private $barColor = '#f3f3f3';
  private $trackBgColor = '#EBEBEB';
  private $primary_light = '#A9A2F6';
  private $success_light = '#55DD92';
  private $warning_light = '#ffc085';
  private $primary = '#7367F0';
  private $success = '#28c76f';
  private $danger = '#EA5455';
  private $warning = '#FF9F43';
  
  public report_area;
  public report_type;
  public shownReports = new Set<string>();
  public statisticsBar;
  public statisticsLine;
  public gainedChartoptions: Partial<ChartOptions>;
  public revenueChartoptions: Partial<ChartOptions>;
  public salesChartoptions: Partial<ChartOptions>;
  public orderChartoptions: Partial<ChartOptions>;
  public trafficChartoptions: Partial<ChartOptions>;
  public userChartoptions: Partial<ChartOptions>;
  public newsletterChartoptions: Partial<ChartOptions>;
  public isMenuToggled = false;
  public totalTotal;
  public countReceived;
  public countVerified;
  public countProgrammed;
  public countPending;
  public countAttended;
  public countClosed;
  public countSimilar;
  isLoadingMore: boolean = false;
  hasMoreData: boolean = true;
  activepopUp: boolean = false;
  imagenPrevobte: string;
  rotationAngle: number = 0;
  isLastPage = false;
  loadingExcel = false;
  public contentHeader: object
  public isLoading: boolean = false;
  //public selectedOption = 30;
  public reports: Report[];
  hasFetched: boolean = false;
  reportCount: number = 0;
  public identity;
  public token;
 public baseUrl;
  public next_page;
  public prev_page;
  public confirmado;
  public errorMessage;
  public ColumnMode = ColumnMode;
  
public Recibidos; 


public st1 = 'recibido';
public st2 = 'verificado';
public st3 = 'programado';
public st4 = 'pendiente';
public st5 = 'atendido';
public st6 = 'cerrado';
public st7 = 'similar';

public ilurecibido;
public iluverificado;
public iluprogramado;
public ilupendiente;
public iluatendido;
public ilucerrado;
public similar;

  public totalRecibidos;
  public totalVerificados;
  public totalProgramados;
  public totalPendientes;
  public totalAtendidos;
  public totalCerrados;
  public totalSimilares;

  public totalAreaPendientes;
 // Private
 private options: GlobalConfig;

  public data = [];
  public cols = [];


  
  // public selectDepen: any = [
  //   { name: 'Todos', value: '' },
  //   { name: 'ALUMBRADO PUBLICO', value: 'Iluminación' },
  //   { name: 'Verificado', value: 'Señalizaciones' },
  //   { name: 'Programado', value: 'Topes' },
  //   { name: 'BACHEO Y MAQUINARIA', value: 'Baches' },
  //   { name: 'ovp', value: 'Obstrucciones vía pública' },
  //   { name: 'RESIDUOS SOLIDOS', value: 'Basura' },
  //   { name: 'Parques y jadínes', value: 'Parques y jadínes' },
  //   { name: 'SSC', value: 'Emergencias' }
  // ]; 

  public selectUser: any = [
   
    { name: 'PRESIDENCIA', value: 'fer.ap.pm@gmail.com' },
    { name: 'VENTANILLA', value: 'ednalorenaocanajimenez@gmail.com' },
    { name: 'VENTANILLA2', value: 'elizabeth1012b@gmail.com' },
    { name: 'REZAGADOS', value: 'serviciosalumbrado@gmail.com' },
    
  ]; 

  area1 ='Iluminación';
  area2 = 'Señalizaciones';
  area3 = 'Topes';
  area4 = 'Baches';
  area5 = 'Obstrucciones vía pública';
  area6 = 'Basura';
  area7 = 'Parques y jadínes';
  area8 = 'Emergencias';
  status = ['recibido', 'pendiente', 'verificado', 'programado', 'atendido', 'cerrado', 'similar'];
  counts: any = {};

  public selectStatus: any = [
    
    { name: 'Recibido', value: 'recibido' },
    { name: 'Verificado', value: 'verificado' },
    { name: 'Programado', value: 'programado' },
    { name: 'Pendiente', value: 'pendiente' },
    { name: 'Atendido', value: 'atendido' },
    { name: 'Cerrado', value: 'cerrado' },
    { name: 'Similar', value: 'similar' }
  ]; 


  public selectedStatus = [];

  public selectedUser = [];

  public selectedDepen = [];

  public searchValue = '';


  public sales = '230k';
  public customers = '8.549k';
  public products = '1.423k';
  public revenue = '$9745';


  fileName= 'reportes.xlsx';
    // decorator
    @ViewChild(DatatableComponent) table: DatatableComponent;

 
  // private
  private tempData = [];
  public copyCodeStatus: boolean = false;
  public totalReports;
   public totalReportsArea;
  public expanded = {};
  public timeout: any;
  public offset = 0;
  public rows;
  public url;
  public tempFilterData;
  public tempFilterDataUser;
  public previousStatusFilter = '';
  public previousUserFilter = '';
  private _unsubscribeAll: Subject<any>;
  /**
   *
   * @param {CoreTranslationService} _coreTranslationService
   */
  constructor(
    private sanitizer: DomSanitizer,
    private _coreTranslationService: CoreTranslationService,
    private _route: ActivatedRoute, 
    private _router: Router,
    private http: HttpClient,
    private _adminService: AdminService,
    private _reportService: ReportService,
    private _coreConfigService: CoreConfigService,
    private toastr: ToastrService
    ) {

      this.totalReports = 0;
      this.totalReportsArea = 0;
      this.options = this.toastr.toastrConfig;
      this._unsubscribeAll = new Subject();
    this._coreTranslationService.translate(en, fr, de, pt);
    this.identity = this._adminService.getIdentity();
    this.token = this._adminService.getToken();
    

    this.baseUrl = environment.baseUrl;
    this.next_page = 1;
    this.prev_page = 1;


    this.contentHeader = {
      headerTitle: 'TizAPP',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Todos los reportes',
            isLink: false,
            
          }
          
         
        ]
      }
    }

  


    this.salesChartoptions = {
      chart: {
        height: 100,
        type: 'area',
        toolbar: {
          show: false
        },
        sparkline: {
          enabled: true
        }
      },
      colors: [this.$danger],
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 2.5
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 0.9,
          opacityFrom: 0.7,
          opacityTo: 0.5,
          stops: [0, 80, 100]
        }
      },
      series: [
        {
          name: 'Sales',
          data: [10, 15, 7, 12, 3, 16]
        }
      ],
      tooltip: {
        x: { show: false }
      }
    };


    
   
    
  }
  onPage(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //console.log('paged!', event);
    }, 100);
  }



  
  countRec(){
    const tR = 'recibido';
    const tV = 'verificado';
    const tPro = 'programado';
    const tPe = 'pendiente';
    const tAt = 'atendido';
    const tCe = 'cerrado';
    const tSi = 'similar';  
    // public totalReports;
    // public totalRecibidos;
    // public totalVerificados;
    // public totalProgramados;
    // public totalPendientes;
    // public totalAtendidos;
    // public totalCerrados;
    // public totalSimilares;

 
    // // filter our data
    // this.totalRecibidos = this.data.filter(function (d) {
      
    //   return d.status_report.indexOf(tR) !== -1 || !tR;
    // });
    // this.totalVerificados = this.data.filter(function (d) {
    //   return d.status_report.indexOf(tV) !== -1 || !tV;
    // });
    // this.totalProgramados = this.data.filter(function (d) {
    //   return d.status_report.indexOf(tPro) !== -1 || !tPro;
    // });
    // this.totalPendientes = this.data.filter(function (d) {
    //   return d.status_report.indexOf(tPe) !== -1 || !tPe;
    // });
    // this.totalAtendidos = this.data.filter(function (d) {
    //   return d.status_report.indexOf(tAt) !== -1 || !tAt;
    // });
    // this.totalCerrados = this.data.filter(function (d) {
    //   return d.status_report.indexOf(tCe) !== -1 || !tCe;
    // });
    // this.totalSimilares = this.data.filter(function (d) {
    //   return d.status_report.indexOf(tSi) !== -1 || !tSi;
    // });

  }
  

  filterUpdate(event) {
    // Reset ng-select on search
    this.selectedStatus = this.selectStatus[0];

    this.selectedUser = this.selectUser[0];

    const val = event.target.value.toLowerCase();
    //console.log('val',val)
    // filter our data
    
    const temp = this.tempData.filter(function (d) {
      return d.report_id.toLowerCase().indexOf(val) !== -1 || !val;
    });

    //console.log('temp',temp)

    // update the rows
    this.rows = temp;

    console.log('thisrows',this.rows)
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  filterByStatus(event) {
    const filter = event ? event.value : '';
    this.previousStatusFilter = filter;
    this.tempFilterData = this.filterRows(filter, this.previousUserFilter);
    this.rows = this.tempFilterData;

    //console.log('tpsf',filter)
  }
  filterByUser(event) {
    const filter = event ? event.value : '';
    this.previousUserFilter = filter;
    this.tempFilterDataUser = this.filterRows(this.previousStatusFilter, filter);
    this.rows = this.tempFilterDataUser ;

    //console.log('tpsf',filter)
  }
  /**
   * Filter Rows
   *
   * @param statusFilter
   */
  filterRows(statusFilter, userFilter): any[] {
    // Reset search on select change
    this.searchValue = '';

    statusFilter = statusFilter;
    userFilter = userFilter;
    
    return this.tempData.filter(row => {
      const isPartialNameMatch = row.status_report.indexOf(statusFilter) !== -1 || !statusFilter ;
      const isPartialuserMatch = row.usrxgene.indexOf(userFilter) !== -1 || !userFilter ;
      return isPartialNameMatch && isPartialuserMatch;
    });
  }
  
  copy(value) {
    const selectBox = document.createElement('textarea');
    selectBox.style.position = 'fixed';
    selectBox.value = value;
    document.body.appendChild(selectBox);
    selectBox.focus();
    selectBox.select();
    document.execCommand('copy');
    document.body.removeChild(selectBox);
    setTimeout(() => {
      this.copyCodeStatus = false;
    }, 500);
    this.copyCodeStatus = true;

    this.toastr.success('Folio copiado correctamente!', value, {
      progressBar: true,
      toastClass: 'toast ngx-toastr',
      closeButton: true
    });
  }
 
  copyCode(inputTextValue) {
    const selectBox = document.createElement('textarea');
    selectBox.style.position = 'fixed';
    selectBox.value = inputTextValue;
    document.body.appendChild(selectBox);
    selectBox.focus();
    selectBox.select();
    document.execCommand('copy');
    document.body.removeChild(selectBox);

    this.toastr.success(inputTextValue, 'Copiado correctamente!' , {
      progressBar: true,
      toastClass: 'toast ngx-toastr',
      closeButton: true
    });
  }
  /**
   * On init
   */
  
  getReportCountByStatus(status: string) {
   
    this._reportService.getReportCountByStatus(this.token, status).subscribe(
      (response) => {
        this.totalReports += response.count;
        if (status == 'recibido'){
          this.totalRecibidos = response.count;
        }else if( status == 'pendiente'){
          this.totalPendientes = response.count;
        }
        else if( status == 'verificado'){
          this.totalVerificados = response.count;
        }
        else if( status == 'programado'){
          this.totalProgramados = response.count;
        }
        else if( status == 'atendido'){
          this.totalAtendidos = response.count;
        }
        else if( status == 'cerrado'){
          this.totalCerrados = response.count;
        }
        else if( status == 'similar'){
          this.totalSimilares = response.count;
        }
       
      },
      (error) => {
          var errorMessage = <any>error;
          return errorMessage;
      }
  );
}

 

  ngOnInit() {  
  


    if(this.identity.role == 'ROLE_PJ'){
    
      this.getReports('Parques y jadínes','');
      
              
    }else if(this.identity.role == 'ROLE_PC'){
  
      this.getReports('Emergencias','');
    }
    else if(this.identity.role == 'ROLE_TRANS'){
      this.getReports('','Coche,Estacionamiento,Cerrada,Obstaculo');
    }
    else if(this.identity.role == 'ROLE_BAC'){
      this.getReports('Baches','');
      
    }else if(this.identity.role == 'ROLE_AL'){
      this.getReports('Iluminación','');
      
    }
    else if(this.identity.role == 'ROLE_EU'){

      this.getReports('Parques y jadínes,Señalizaciones','');

    }else if(this.identity.role == 'ROLE_REGLA'){
      this.getReports('','Invasión');
     

    }else if(this.identity.role == 'ROLE_RES'){
      this.getReports('Basura','');
    }else if(this.identity.role == 'ROLE_MOV'){
      this.getReports('Topes','');
    }

    else if(this.identity.role == 'ROLE_PLA'){
      this.getReports('Otro','');
      

    }
    //DIRECTOR SPM
    else if(this.identity.role == 'ROLE_DIRSPM'){

      this.getReports('Obstrucciones vía pública,Topes,Basura,Parques y jadínes,Señalizaciones,Iluminación,Baches','');      
    }
    
    else if(this.identity.role == 'ROLE_PRESIDENTA' || this.identity.role == 'ROLE_ADMIN'){

      this.getReports('','');      
    }


    

   
    if(this.identity.role == 'ROLE_PJ'){
    
   
      this.report_area = 'Parques y jadínes';
      this.report_type = '';
      
            

    }else if(this.identity.role == 'ROLE_PC'){
  

      this.report_area = 'Emergencias';
      this.report_type = '';
    }
    else if(this.identity.role == 'ROLE_TRANS'){
     
      this.report_area = '';
      this.report_type = 'Coche,Estacionamiento,Cerrada,Obstaculo';
    }
    else if(this.identity.role == 'ROLE_BAC'){
  
      this.report_area = 'Baches';
      this.report_type = '';
      
    }else if(this.identity.role == 'ROLE_AL'){
     
      this.report_area = 'Iluminación';
      this.report_type = '';
      
    }
    else if(this.identity.role == 'ROLE_EU'){

     
      this.report_area = 'Parques y jadínes,Señalizaciones';
      this.report_type = '';

    }else if(this.identity.role == 'ROLE_REGLA'){
      
     this.report_area = '';
      this.report_type = 'Invasión';

    }else if(this.identity.role == 'ROLE_RES'){
   
      this.report_area = 'Basura';
      this.report_type = '';
    }else if(this.identity.role == 'ROLE_MOV'){
    
      this.report_area = 'Topes';
      this.report_type = '';
    }

    else if(this.identity.role == 'ROLE_PLA'){
      
      this.report_area = 'Otro';
      this.report_type = '';
      

    }
    //DIRECTOR SPM
    else if(this.identity.role == 'ROLE_DIRSPM'){
      this.report_area = 'Obstrucciones vía pública,Topes,Basura,Parques y jadínes,Señalizaciones,Iluminación,Baches';
      this.report_type = '';
     
    }
    
    else if(this.identity.role == 'ROLE_PRESIDENTA'  || this.identity.role == 'ROLE_ADMIN'){
      this.report_area = '';
      this.report_type = '';
        
    }
    this.report_area = encodeURIComponent(this.report_area);
      this.report_type = encodeURIComponent(this.report_type); 
      console.log(this.report_area + this.report_type)
  //  this.getReportCountByAreaAndStatus('Iluminación', 'atendido');


    // this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
    //   // If we have zoomIn route Transition then load datatable after 450ms(Transition will finish in 400ms)
    //   if (config.layout.animation === 'zoomIn') {
    //     setTimeout(() => {
    //       this._reportService.onInvoiceListChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
    //         this.data = response;
    //         this.rows = this.data;
    //         this.tempData = this.rows;
    //         this.tempFilterData = this.rows;

    //         console.log('si se pudo', this.rows)
    //       });
          
    //     }, 450);
    //   } else {
    //     this._reportService.onInvoiceListChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
    //       this.data = response;
    //       this.rows = this.data;
    //       this.tempData = this.rows;
    //       this.tempFilterData = this.rows;
    //       console.log('no se pudo', this.rows)
    //     });
    //   }
    // });
   
   
    
    //console.log(this.identity)  
    
  }
  ngAfterViewInit() {
    // Subscribe to core config changes
    this._coreConfigService.getConfig().subscribe(config => {
      // If Menu Collapsed Changes
      if (
        (config.layout.menu.collapsed === true || config.layout.menu.collapsed === false) &&
        localStorage.getItem('currentUser')
      ) {
        setTimeout(() => {
          // Get Dynamic Width for Charts
          this.isMenuToggled = true;
          this.statisticsBar.chart.width = this.statisticsBarRef?.nativeElement.offsetWidth;
          this.statisticsLine.chart.width = this.statisticsLineRef?.nativeElement.offsetWidth;
          this.gainedChartoptions.chart.width = this.gainedChartoptionsRef?.nativeElement.offsetWidth;
          this.revenueChartoptions.chart.width = this.revenueChartoptionsRef?.nativeElement.offsetWidth;
          this.salesChartoptions.chart.width = this.salesChartoptionsRef?.nativeElement.offsetWidth;
          this.orderChartoptions.chart.width = this.orderChartoptionsRef?.nativeElement.offsetWidth;
          this.trafficChartoptions.chart.width = this.trafficChartoptionsRef?.nativeElement.offsetWidth;
          this.userChartoptions.chart.width = this.userChartoptionsRef?.nativeElement.offsetWidth;
          this.newsletterChartoptions.chart.width = this.newsletterChartoptionsRef?.nativeElement.offsetWidth;
        }, 500);
      }
    });
  }

  getReports(report_area: string, report_type: string) {
    if (!this.hasFetched) {
    this._route.params.forEach((params: Params) => {
      let page = +params['page'];
      
      if (!page) {
        page = 1;
      } else {
        this.next_page = page + 1;
        this.prev_page = page - 1;

        if (this.prev_page == 0) {
          this.prev_page = 1;
        }

        report_area = encodeURIComponent(report_area);
        report_type = encodeURIComponent(report_type);
      }
      this._reportService.getReportsPaginated(this.token, page, report_area, report_type).subscribe(
        (response) => {
          if (!response.tzxcityreports) {
            this._router.navigate(['/']);
          } else {
            
            this.hasFetched = true;
            this.reports = response.tzxcityreports;
            this.data = this.reports;
            this.rows = this.data;
            this.tempData = this.rows;
            this.tempFilterData = this.rows;
            this.tempFilterDataUser = this.rows;
            this.tempFilterData = this.rows.length;
            this.tempFilterDataUser = this.rows.length;
            this.totalTotal = response.total_items;
            this.countReceived = response.countReceived;
            this.countVerified = response.countVerified;
            this.countProgrammed = response.countProgrammed;
            this.countPending = response.countPending;
            this.countAttended = response.countAttended;
            this.countClosed = response.countClosed;
            this.countSimilar = response.countSimilar;
            this.isLoading = false;
          }
        },
        (error) => {
          var errorMessage = <any>error;
        }
      );
    });
     }
  }

  loadMoreData() {
  





    if (!this.isLoading && this.hasFetched) {
     
    this.isLoading = true;

    this._reportService.getReportsPaginated(this.token, this.currentPage, this.report_area, this.report_type).subscribe(
      (response) => {
        if (!response.tzxcityreports) {
          this._router.navigate(['/']);
        } else {
          this.currentPage++;
          this.hasFetched = true; // cambia el estado de ha cargado alguna vez a true
          const newReports = response.tzxcityreports.filter(report => !this.shownReports.has(report.uid));
        newReports.forEach(report => this.shownReports.add(report.uid));
        this.reports = this.reports.concat(newReports);
          this.data = this.reports;
          this.rows = this.data;
          this.tempData = this.rows;
          this.tempFilterData = this.rows;
          this.tempFilterDataUser = this.rows;
          this.tempFilterData = this.rows.length;
          this.tempFilterDataUser = this.rows.length;
          this.isLoading = false;
          //nueva comprobación
          if(response.isLastPage || newReports.length<10) {
            this.isLastPage = true;
          }
        }
      },
      (error) => {
        this.isLoading = false;
        var errorMessage = <any>error;
      }
    );
     } 
  }




  onDeleteConfirm(id) {
    this.confirmado = id;
  }

  onCancelReport() {
    this.confirmado = null;
  }

  onDeleteReport(id) {
    this._reportService.deleteReport(this.token, id).subscribe(
      (response) => {
        if (!response.tzxcityreports) {
          
          location.reload();
        }
       // this.getReports();
      },
      (error) => {
        var errorMessage = <any>error;

      }
    );
  }

  borrar() {
    (document.querySelectorAll('.notification .delete') || []).forEach(
      ($delete) => {
        const $notification = $delete.parentNode;

        $notification.parentNode.removeChild($notification);
      }
    );
  }
  backPage(){
    window.history.back();
  }


//   exportexcel(): void
//   {
//     /* pass here the table id */
//     let element = document.getElementById('excel-table');
//     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

//     /* generate workbook and add the worksheet */
//     const wb: XLSX.WorkBook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

//     /* save to file */
//     XLSX.writeFile(wb, this.fileName);

//   }
openPopover(popoverContent) {
  this.popover.open({ content: popoverContent });
}

openPopF(imagenPrev){
 
  this.imagenPrevobte = imagenPrev;
  
  this.activepopUp = true;
}
closePopF(){
  this.activepopUp = false;
}

rotate() {
   this.rotationAngle += 90;
}
get safeStyle() {
  return this.sanitizer.bypassSecurityTrustStyle('rotate(' + this.rotationAngle + 'deg)');
}

// PANTALLA EXCEL
exportexcel(table: any): void {
  let data = table.rows; // Obtener los datos de la tabla ngx-datatable
  let ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data); // Convertir los datos en un objeto de hoja de cálculo
  let wb: XLSX.WorkBook = XLSX.utils.book_new(); // Crear un nuevo libro de trabajo
  let dateexcel = new Date();
  let formattedDate = dateexcel.toLocaleDateString().replace(/\//g, "-");
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1'); // Agregar la hoja de trabajo al libro
  XLSX.writeFile(wb, 'reportes-en-pantalla' + formattedDate + '.xlsx'); // Guardar el libro en un archivo con el nombre 'data.xlsx'
}



//TODOS EXCEL

getReportsExcel() {
  this._route.params.forEach((params: Params) => {
    let page = +params['page'];
    
    if (!page) {
      page = 1;
    } else {
      this.next_page = page + 1;
      this.prev_page = page - 1;

      if (this.prev_page == 0) {
        this.prev_page = 1;
      }
    }
    this._reportService.getReports(this.token, page).subscribe(
      (response) => {
        this.loadingExcel = false;
        if (!response.tzxcityreports) {
          this._router.navigate(['/']);
          
        }

          
          else{
            this.reports = response.tzxcityreports;

            const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.reports);
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Reports');
            let dateexcel = new Date();
            let formattedDate = dateexcel.toLocaleDateString().replace(/\//g, "-");
            /* generar un archivo excel y forzar la descarga */
            XLSX.writeFile(wb, 'TOTAL-Reportes' + formattedDate + '.xlsx');

          }
      },
      (error) => {
        this.loadingExcel = false;
        var errorMessage = <any>error;


      }
    ) ;
  });

}


downloadExcel() {
  this.loadingExcel = true;
  this.getReportsExcel();
}

 }
