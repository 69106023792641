import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CoreCommonModule } from '@core/common.module';

import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SampleComponent } from './sample.component';
import { HomeComponent } from './home.component';

import { LoginComponent } from './login.component';
import { NgSelectModule } from '@ng-select/ng-select';

import { ReportDetailComponent } from './reportdetail.component';
import { SspComponent } from './ssp.component';
import { SsplistComponent } from './ssplist.component';
import { SspdetailComponent } from './sspdetail.component';

import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

import { SafeStylePipe } from './safe-styles.pipe';

import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';

import { QuillModule } from 'ngx-quill';

// import { DateTimePickerModule } from 'app/main/forms/form-elements/date-time-picker/date-time-picker.module';

import { AddEventComponent } from './comunication/events/add-event.component';
import { AddAlertComponent } from './comunication/alerts/add-alert.component';
import { AddNewComponent } from './comunication/news/add-new.component';

import { BannerComponent } from './comunication/banners/banner.component';
// import { NgSelectModule } from '@ng-select/ng-select';


import { HomeComponentSSP } from './components/home/home.componentssp';
import { GeolocComponent } from './components/geoloc/geoloc.component';
import { InfoContainerComponent } from './components/info-container/info-container.component';

import { DatePipe } from '@angular/common';
import { PreloadAllModules, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
const routes = [
  {
    path:'panel',
    loadChildren: () => import('./components/views.module').then(m => m.ViewsModule)
  },
 
  {
    path: 'reportes',
    component: SampleComponent,
    data: { animation: 'sample' }
  },
  
  {
    path: 'ssp-list',
    component: SsplistComponent,
    data: { animation: 'sample' }
  },
  {
    path: 'iniciar-sesion',
    component: LoginComponent,
    data: { animation: 'sample' }
  },
  // {
  //   path: 'iniciar-sesion',
  //   component: LoginComponent,
  //   data: { animation: 'home' }
  // },
  {
    path: 'inicio',
    component: HomeComponent,
    data: { animation: 'home' }
  },
  {
    path: 'ssp',
    component: SspComponent,
    data: { animation: 'home' }
  },
  {
    path:'solicitud/:informant',
    loadChildren:() => import('./info-container/sub-contain.module').then(m => m.SubContainModule),    
  },
  {
    path: 'reporte/:uid',
    component: ReportDetailComponent,
    data: { animation: 'home' }
  },

  {
    path: 'agregar-evento',
    component: AddEventComponent,
    data: { animation: 'home' }
  },

  {
    path: 'agregar-alerta',
    component: AddAlertComponent,
    data: { animation: 'home' }
  },

  {
    path: 'agregar-noticia',
    component: AddNewComponent,
    data: { animation: 'home' }
  },
  {
    path: 'editar-banners',
    component: BannerComponent,
    data: { animation: 'home' }
  }
];

@NgModule({

declarations: [SampleComponent,SafeStylePipe, BannerComponent, HomeComponent, AddAlertComponent, AddNewComponent, ReportDetailComponent, SspComponent, SsplistComponent, SspdetailComponent, AddEventComponent],
  imports: [ OwlDateTimeModule, 
    OwlNativeDateTimeModule,RouterModule.forChild(routes),NgbModule, NgxDatatableModule, ContentHeaderModule, TranslateModule, NgSelectModule, CoreCommonModule,RouterModule.forChild(routes),
    CoreCommonModule,
    NgbModule,
    ContentHeaderModule,
    RouterModule.forChild(routes),
    CardSnippetModule,
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    NgSelectModule,
    QuillModule.forRoot()
   ],
  exports: [SampleComponent, HomeComponent, RouterModule],
  providers: [DatePipe]
})
export class SampleModule {}
