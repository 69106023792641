<script src="https://kit.fontawesome.com/b0bf6f8939.js" crossorigin="anonymous"></script>

<div class="content-wrapper" style="overflow: hidden;">
    <div class="content-body" style=" position: relative;
    
    height: 100vh;
    overflow: hidden;">

        <button type="button" routerLink="/ssp-list" class="btn btn-lg btn-icon btn-icon rounded-circle btn-warning float" rippleEffect>
    <span [data-feather]="'list'"></span></button>

        <div class="prev-vw">
            <div #mapas id="mapa" class="map-container">

            </div>

        </div>
        <div *ngIf="ContSkelet" class="prev-vw">
            <div class="content">
                <div class="line"></div>
            </div>
        </div>



    </div>
</div>