import { Injectable } from '@angular/core';
import { HttpClient  } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { Report } from '../models/tzxcityreports';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { tap } from 'rxjs/operators';

@Injectable()
export class ReportService implements Resolve<any> {

  private _refresh$ = new Subject<void>();
    rows: any;
  onInvoiceListChanged: BehaviorSubject<any>;
  public baseUrl: string = environment.baseUrl;
  public token;
  public errorMessage;
  public empleadoprev: any;
  constructor(private _http: HttpClient) {  
    this.baseUrl = environment.baseUrl;
    this.onInvoiceListChanged = new BehaviorSubject({});
  }

get_refresh$(){
  return this._refresh$;
}


resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
  return new Promise<void>((resolve, reject) => {
    Promise.all([this.getDataTableRows(this.token)]).then(() => {
      resolve();
    }, reject);
  })
}

  getReport(token, uid: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers
      .set('Content-Type', 'application/json')
      .set('Authorization', token);
    let options = {
      headers: headers
    }

    return this._http.get(this.baseUrl+'reporte/'+uid, options)
    .pipe(map((res) => res))
    ;


  }
 isLoading = false;
 getReportsPaginated(token, page, reports_area = null, reports_type = null): Observable<any> {
  if (!this.isLoading) {
    this.isLoading = true;
    let headers = new HttpHeaders();
    headers = headers
      .set('Content-Type', 'application/json')
      .set('Authorization', token);
    let options = {
      headers: headers
    }
    let url = this.baseUrl+'reportes/paginated/'+page;
    if (reports_area != null) {
      url += '?reports_area='+reports_area;
      if (reports_type != null) {
        url += '&reports_type='+reports_type;
      }
    } else if (reports_type != null) {
      url += '?reports_type='+reports_type;
    }
    return this._http.get(url, options)
      .pipe(
        tap(() => this.isLoading = false),
        map((res) => res)
      );
  }
};

getReportsPaginatedsinIf(token, page, reports_area = null, reports_type = null): Observable<any> {
  
    
    let headers = new HttpHeaders();
    headers = headers
      .set('Content-Type', 'application/json')
      .set('Authorization', token);
    let options = {
      headers: headers
    }
    let url = this.baseUrl+'reportes/paginated/'+page;
    if (reports_area != null) {
      url += '?reports_area='+reports_area;
      if (reports_type != null) {
        url += '&reports_type='+reports_type;
      }
    } else if (reports_type != null) {
      url += '?reports_type='+reports_type;
    }
    return this._http.get(url, options)
      .pipe(
        tap(() => this.isLoading = false),
        map((res) => res)
      );
  
};


    getReportCountByStatus(token, status: string): Observable<any> {
      let headers = new HttpHeaders();
    headers = headers
      .set('Content-Type', 'application/json')
      .set('Authorization', token);
    let options = {
      headers: headers
    }

    return this._http.get(this.baseUrl + 'reportes/count/status/' + status, options).pipe(map((res) => res))
   ;
  }

  getReportCountByAreaAndStatus(token, area: string, status: string): Observable<any> {
    let headers = new HttpHeaders();
  headers = headers
    .set('Content-Type', 'application/json')
    .set('Authorization', token);
  let options = {
    headers: headers
  }

  return this._http.get(this.baseUrl + 'reportes/count/area/' + area + '/status/' + status, options).pipe(map((res) => res))
 ;
}




  // setEmployee2(data){
  //   this.empleadoprev = data;
  // }


  // getEmployee2(){
  //   return this.empleadoprev;

  // }
  getDataTableRows(token): Promise<any[]> {
     
    let headers = new HttpHeaders();
    headers = headers
      .set('Content-Type', 'application/json')
      .set('Authorization', token);
    let options = {
      headers: headers
    }
    return new Promise((resolve, reject) => {
      this._http.get(this.baseUrl+'reportes/', options).subscribe((response: any) => {
        this.rows = response;
        this.onInvoiceListChanged.next(this.rows);
        resolve(this.rows);
      }, reject);
    });
  }


  getReports(token, page): Observable<any> {

    
    let headers = new HttpHeaders();
    headers = headers
      .set('Content-Type', 'application/json')
      .set('Authorization', token);
    let options = {
      headers: headers
    }

    return this._http.get(this.baseUrl+'reportes/', options)
    .pipe(map((res) => res));

   
  }




  editReport(token, uid:string, report: Report): Observable<any> {
    let json = JSON.stringify(report);
    let params = json;
    let headers = new HttpHeaders();
    headers = headers
      .set('Content-Type', 'application/json')
      .set('Authorization', token);

    return this._http
      .put(this.baseUrl + 'reporte/'+ uid, params, { headers: headers })
      .pipe(map((res) => res));
  }

  deleteReport(token, uid: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers
      .set('Content-Type', 'application/json')
      .set('Authorization', token);
    let options = {
      headers: headers
    }
    return this._http
      .delete(this.baseUrl + 'reporte/' + uid, options)
      .pipe(map((res) => res));
  }
}

