<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <p>Folios List</p>

        <hr>
        <!-- Revisar paginado y reordenamiento mas reciente / OK  -->
        <!-- En esta pagina sera de uso para retroalimentacion si fue atendido -->
        <div *ngFor="let item of List[0]">
            
            <div (click)="go_to(item.usrxfolio)">
        
                <p class="fole-sty" >
                    Folio: {{ item.usrxfolio }} 
                </p>              
                <p>Fecha: {{item.coordsxusr[0].dayorigin | date: ' EEEE dd, MMM y, h:mm:ss a' }}</p>                
                
                
                <p>Usuario:{{item.xname}}</p>
        
                
            </div> 
            <!-- AJUSTAR PUSH y Pagina de detalles testear y ajustar fecha -->
            <!-- Agregar sonido de campana una vez recibido el feedback -->
            <div *ngIf="!item.sscxagent" class="form-agent" >
                <form autocomplete="off" [formGroup]="FormAgent" (ngSubmit)="SendRepo(item.usrxfolio)">
        
                    <input type="text" placeholder="Matricula(Agente)" formControlName="campxagent">
                        <br>
                    <input type="text" placeholder="Descripción" formControlName="campxdetails">
                    <button class="btn btn-secondary">Actualizar</button>
                </form>
            </div>
            
            <div *ngIf="item.sscxagent">
                <p><b>Agente: </b>{{item.sscxagent}}</p>
                <p><b>Comentarios: </b>{{item.sscxdetails}}</p>
            </div>
        
            
            <hr> 
        </div>
        
        
        
        <div *ngIf="List">
            <button (click)="AporLosFlacos()">Ver Mapa</button> 
        </div>
        <!-- 
        <pre>
            {{List}}
        </pre>
        <div *ngFor="let item of List[0]">

            <div (click)="go_to(item.usrxfolio)">
                <p class="fole-sty">
                    Folio: {{ item.usrxfolio }}
                </p>
                <p>Fecha/hora:{{item.coordsxusr[0].dayorigin | date:'short'}}</p>
                <p>Usuario:{{item.xname}}</p>
            </div>
            <hr>
        </div>



        <div *ngIf="List">
            <button (click)="AporLosFlacos()">Ver Mapa</button>
        </div> -->



        <!-- <section class="invoice-list-wrapper">
            <div class="card">

                <div class="row">
                    <div class="col-md-6 col-12">
                        <div class="d-flex justify-content-between align-items-center m-1">
                            <div class="d-flex align-items-center">
                                <label class="d-flex align-items-center">Mostrar
                        <select class="form-control mx-25" [(ngModel)]="selectedOption">
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </label>
                                <label class="d-flex align-items-center mb-0 ml-1 ml-md-0"><input
                        [(ngModel)]="searchValue"
                        name="searchValue"
                        type="search"
                        class="form-control"
                        placeholder="Buscar folio..."
                        (keyup)="filterUpdate($event)"
                        (search)="filterUpdate($event)"
                    /></label>

                            </div>
                        </div>
                    </div>




                </div>

                <ngx-datatable [rows]="rows" [rowHeight]="'auto'" class="bootstrap core-bootstrap" [limit]="selectedOption" [columnMode]="ColumnMode.force" [headerHeight]="50" [footerHeight]="50" [scrollbarH]="true" [sorts]="[{prop: 'dayorigin', dir: 'asc'}]">



                    <ngx-datatable-column name="FOLIO" prop="usrxfolio" [width]="300" [sortable]="false">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <a (click)="copy(row.usrxfolio)"><i size="18" data-feather="copy"></i
                                ></a> &nbsp; <a [routerLink]="['/solicitud', row.usrxfolio]" style="font-weight: 800!important;">{{ row.usrxfolio }}</a>
                            <hr style="margin: 5px!important;">


                        </ng-template>
                    </ngx-datatable-column>







                    <ngx-datatable-column name="Teléfono" prop="xphone" [width]="70" [sortable]="false">
                        <ng-template let-row="row" ngx-datatable-cell-template>

                            <div style="font-weight: 800;">
                                <a href="tel:{{ row.xphone }}"><i class="fas fa-phone"></i>&nbsp; {{ row.xphone }}</a>
                            </div>

                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Usuario" prop="xname" [width]="70" [sortable]="false">
                        <ng-template let-row="row" ngx-datatable-cell-template>

                            <div style="font-weight: 800;">
                                {{ row.xname }}
                            </div>

                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Fecha" prop="dayorigin" [width]="70">
                        <ng-template let-row="row" ngx-datatable-cell-template>

                            <div style="font-weight: 800;">
                                {{ row.coordsxusr[0].dayorigin | date: 'medium'}}
                            </div>

                        </ng-template>
                    </ngx-datatable-column>


                </ngx-datatable>

            </div>
        </section> -->














        <!-- <div class="row match-height">
            <div class="col-lg-12 col-md-12 col-12">
                <div class="card card-browser-states">
                    <div class="card-header">
                        <h4 class="card-title">Actividad</h4>

                    </div>
                    <div class="card-body">

                        <div class="browser-states head-st">
                            <div class="media">
                                <h6 class="align-self-center mb-0" style="text-align:center; color:white!important;">FOLIO</h6>
                            </div>
                            <div class="d-flex align-items-center">
                                <div class="font-weight-bold text-body-heading mr-1" style="text-align:center; color:white!important;">FECHA / HORA</div>

                            </div>
                            <div class="d-flex align-items-center" style="text-align:center; color:white!important;">
                                <div class="font-weight-bold text-body-heading mr-1" style="text-align:center; color:white!important;">USUARIO</div>
                            </div>
                        </div>

                        <div routerLink="/solicitud" class="browser-states">
                            <div class="media">
                                <h6 class="align-self-center mb-0">tzxORSXG5BQGAYQ-ssp-1992022</h6>
                            </div>
                            <div class="d-flex align-items-center">
                                <div class="font-weight-bold text-body-heading mr-1">19/09/2022 - 22:34:05 hrs.</div>

                            </div>
                            <div class="d-flex align-items-center">
                                <div class="font-weight-bold text-body-heading mr-1">ferbock10@gmail.com</div>
                            </div>
                        </div>
                        <div routerLink="/solicitud" class="browser-states">
                            <div class="media">
                                <h6 class="align-self-center mb-0">tzxORSXG5BQGAYQ-ssp-992022</h6>
                            </div>
                            <div class="d-flex align-items-center">
                                <div class="font-weight-bold text-body-heading mr-1">09/09/2022 - 12:09:45 hrs.</div>

                            </div>
                            <div class="d-flex align-items-center">
                                <div class="font-weight-bold text-body-heading mr-1">ferbock10@gmail.com</div>
                            </div>
                        </div>
                        <div routerLink="/solicitud" class="browser-states">
                            <div class="media">
                                <h6 class="align-self-center mb-0">tzxORSXG5BQGAYQ-ssp-792022</h6>
                            </div>
                            <div class="d-flex align-items-center">
                                <div class="font-weight-bold text-body-heading mr-1">07/09/2022 - 20:12:05 hrs.</div>

                            </div>
                            <div class="d-flex align-items-center">
                                <div class="font-weight-bold text-body-heading mr-1">ferbock10@gmail.com</div>
                            </div>
                        </div>
                        <div routerLink="/solicitud" class="browser-states">
                            <div class="media">
                                <h6 class="align-self-center mb-0">tzxORSXG5BQGAYQ-ssp-492022</h6>
                            </div>
                            <div class="d-flex align-items-center">
                                <div class="font-weight-bold text-body-heading mr-1">04/09/2022 - 20:12:05 hrs.</div>

                            </div>
                            <div class="d-flex align-items-center">
                                <div class="font-weight-bold text-body-heading mr-1">ferbock10@gmail.com</div>
                            </div>
                        </div>
                        <div routerLink="/solicitud" class="browser-states">
                            <div class="media">
                                <h6 class="align-self-center mb-0">tzxORSXG5BQGAYQ-ssp-192022</h6>
                            </div>
                            <div class="d-flex align-items-center">
                                <div class="font-weight-bold text-body-heading mr-1">01/09/2022 - 05:03:18 hrs.</div>

                            </div>
                            <div class="d-flex align-items-center">
                                <div class="font-weight-bold text-body-heading mr-1">ferbock10@gmail.com</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->






    </div>
</div>