
import { CoreConfigService } from '@core/services/config.service';




import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { Router } from '@angular/router';

import { Geodata } from '../../interfaces/watch-user';
// import { UserInf } from '../../models/marks';
import { StorageService } from '../../services/storage.service';
import { DbService } from '../../services/transit-info.service';

import  Pusher from 'pusher-js';
import { FollowService } from 'app/services/follow.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-home',
  templateUrl: './ssp.component.html',
  styleUrls: ['./ssp.component.css']
})
export class SspComponent implements OnInit {
  @ViewChild('mapas', { static: true }) mapasElement!:ElementRef;
  
  //  // Status 
  //  Idfolio:any;
  //  Pre_li:any[] =[];
  //  bakcdat:any;
 
  //  TmpMarks:any[] = [];
  //  WEEK_PANIC:any[] = [];
   
  //  followssp:any[]=[];
 
  //  //skelet
  //  ContSkelet:boolean = false;
   
  //  //skelet
  //  WaitLi:any[] = [false];
  //  today:any = this.datePipe.transform(Date.now(),'shortDate');
 
  //  // MAPS
  //  map!: google.maps.Map;
  //  marcadores: google.maps.Marker[] = [];
  //  infowindows: google.maps.InfoWindow[] = [];
 
  //  operatives:Geodata[] = [];
 
  //  pusher = new Pusher('f3a4fb5dcaf927343746', {
  //    cluster: 'us2'
    
  // Status 
  Idfolio:any;
  Pre_li:any[] =[];
  bakcdat:any;

  TmpMarks:any[] = [];
  WEEK_PANIC:any[] = [];
  
  followssp:any[]=[];

  //skelet
  ContSkelet:boolean = false;
  
  //skelet
  today:any = this.datePipe.transform(Date.now(),'shortDate');

  // MAPS
  map!: google.maps.Map;
  marcadores: google.maps.Marker[] = [];
  infowindows: google.maps.InfoWindow[] = [];

  operatives:Geodata[] = [];

  // Analitcs ?
  WaitLi:any[] = [];

  pusher = new Pusher('f3a4fb5dcaf927343746', {
    cluster: 'us2'
   });
  
  constructor(private _coreConfigService: CoreConfigService,
    private datePipe: DatePipe,
              private storage:StorageService,
              private route:Router,
              private trasit:DbService,
              private fire:FollowService) {

     // Configure the layout
     this._coreConfigService.config = {
        layout: {
          navbar: {
            hidden: true
          },
          footer: {
            hidden: true
          },
          menu: {
            hidden: true
          },
          customizer: false,
          enableLocalStorage: false
        }
      };
    
  }

  public contentHeader: object

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  async ngOnInit() {
    // console.log('INIT COMPONENT ',this.followssp.length);    
    // testear normalidad de funcionamiento una vez conectado a internet
   
    // only the map
    try{

      // let change = parseInt(this.day) + 1  
        this.LoadMap();
        this.LaunchStorage();
        
        // THIS_ELSE / this.bakcdat == undefined
        const channel = this.pusher.subscribe('tzxssp');
        channel.bind('reqxssp', async (data:any) => {
          // console.log('THIS_ELSE'); 
          this.USRList(data);
        });

        // const Yesterday = await this.storage.GetVariables();
        // // console.log('StorageDate: ',Yesterday);

        // if(this.today != Yesterday){
        //   // console.log("Another Day")
          
        //   await this.storage.GET_WaitList();
        //   await this.storage.CLEAN_TempUsrs();
        //   await this.storage.CLEAN_WaitLi();
        // }

    // }
    // catch(err){
    //   // alert('Comprueba tu conexion a internet');
    //   this.ContSkelet = true;
    // } 
  }
  catch(err){
    // alert('Comprueba tu conexion a internet');
    this.ContSkelet = true;
  } 


    this.contentHeader = {
      headerTitle: 'TizAPP',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'SSP',
            isLink: true,
            link: '/'
          },
          {
            name: 'Botón Pánico',
            isLink: false
          }
        ]
      }
    }
  }
  ngOnDestroy(){
    this.LaunchStorage();
  }
 
  LoadMap(){   
    const latLng = new google.maps.LatLng(19.8509038,-98.9658552);
    
    const mapOptions:google.maps.MapOptions = {
        center:latLng,
        zoom:14,
        mapTypeId: google.maps.MapTypeId.ROADMAP
    }

    this.map = new google.maps.Map(this.mapasElement.nativeElement, mapOptions);
  }
  async LaunchStorage(){
  }
 // ------------------- PRINCIPAL PROCSS ( PERSISTENT INFO ) --------------------- 
  // * NODE - A (On Live) 
  async USRList(CoordsInfo:Geodata){
  }
   // :: Common Tool ::
   MarkStyle(usr:any,dir:string,local?:any){
  }
   // ------------------- MARKER Options ---------------------
  // >> OFFLINE (NodeB)
  addMarkerStorage(usr:Geodata){
  }
   // >> REAL TIME (NodeA) 
   addMarker(usr:Geodata){ 
  }
 


  
  
 




home(){
  this.route.navigateByUrl('/');
} 

// ------------------- Analitics ---------------------

}
