import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DbService } from '../../services/transit-info.service';
import { StorageService } from 'app/services/storage.service';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexStroke, ApexTooltip } from 'ng-apexcharts';
import { GlobalConfig, ToastrService } from 'ngx-toastr';

import { FormBuilder,FormGroup,Validators } from '@angular/forms';
export interface ChartOptions {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  stroke: ApexStroke;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  fill: ApexFill;
  colors: string[];
}
@Component({
  selector: 'app-home',
  templateUrl: './ssplist.component.html',
  styleUrls: ['./sample.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SsplistComponent implements OnInit {
 // decorator
 @ViewChild(DatatableComponent) table: DatatableComponent;

  private tempData = [];
  public rows;
  public tempFilterData;
    public tempFilterDataUser;
    public previousStatusFilter = '';
    public previousUserFilter = '';
    public solicitudes: [];
    public copyCodeStatus: boolean = false;
public data = [];
  public cols = [];
  public totalReports;
  public totalRecibidos;
  public contentHeader: object
  public ColumnMode = ColumnMode;
   
  public selectUser: any = [
   
    { name: 'PRESIDENCIA', value: 'fer.ap.pm@gmail.com' },
    { name: 'VENTANILLA', value: 'ventanilla@gmail.com' },
    { name: 'VENTANILLA', value: 'elizabeth1012b@gmail.com' },
    
  ]; 

  public selectStatus: any = [
    
    { name: 'Recibido', value: 'recibido' },
    { name: 'Verificado', value: 'verificado' },
    { name: 'Programado', value: 'programado' },
    { name: 'Pendiente', value: 'pendiente' },
    { name: 'Atendido', value: 'atendido' },
    { name: 'Cerrado', value: 'cerrado' },
    { name: 'Similar', value: 'similar' }
  ]; 


  public selectedStatus = [];

  public selectedUser = [];

  public selectedDepen = [];

  public searchValue = '';
  public selectedOption = 10;
  
  private options: GlobalConfig;
  public timeout: any;

  List:any[]=[];
  temp:any;

  FormAgent:FormGroup = this.fb.group({
    campxagent: ['', Validators.required],
    campxdetails: ['', Validators.required], 

    c2xagent:[localStorage.getItem('name') || 'persona_c2',Validators.required],
    c2xmatricula:[localStorage.getItem('matricula') || 'matricula_c2',Validators.required]
  });


  constructor(private route:Router,
    private libs:DbService,
    private fb: FormBuilder,
    private storage:StorageService,
    private toastr: ToastrService
    ) { 
      this.options = this.toastr.toastrConfig;
    }


    onPage(event) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        //console.log('paged!', event);
      }, 100);
    }
  
    countRec(){
      const tR = 'recibido';
  
    
  
   
      // filter our data
      this.totalRecibidos = this.data.filter(function (d) {
        
        return d.status_report.indexOf(tR) !== -1 || !tR;
      });
      
  
  
      
      
     
    }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------


  filterUpdate(event) {
    // Reset ng-select on search
    
    const val = event.target.value.toLowerCase();
    //console.log('val',val)
    
    // filter our data
    const temp = this.tempData.filter(function (d) {
      return d.report_id.toLowerCase().indexOf(val) !== -1 || !val;
    });

    //console.log('temps', temp)

    // update the rows
    this.rows = temp;

    //console.log('thisrows',this.rows)
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }
  filterRows(statusFilter, userFilter): any[] {
    // Reset search on select change
    this.searchValue = '';

  


    return this.tempData.filter(row => {
      const isPartialNameMatch = row.status_report.indexOf(statusFilter) !== -1 || !statusFilter ;
      const isPartialuserMatch = row.usrxgene.indexOf(userFilter) !== -1 || !userFilter ;
      return isPartialNameMatch && isPartialuserMatch;
    });

   
    
  }
  filterByStatus(event) {
    const filter = event ? event.value : '';
    this.previousStatusFilter = filter;
    this.tempFilterData = this.filterRows(filter, this.previousUserFilter);
    this.rows = this.tempFilterData;

    //console.log('tpsf',filter)
  }
  /**
   * On init
   */
   copy(value) {
    const selectBox = document.createElement('textarea');
    selectBox.style.position = 'fixed';
    selectBox.value = value;
    document.body.appendChild(selectBox);
    selectBox.focus();
    selectBox.select();
    document.execCommand('copy');
    document.body.removeChild(selectBox);
    setTimeout(() => {
      this.copyCodeStatus = false;
    }, 500);
    this.copyCodeStatus = true;

    this.toastr.success('Folio copiado correctamente!', value, {
      progressBar: true,
      toastClass: 'toast ngx-toastr',
      closeButton: true
    });
  }
  ngOnInit() {

     
     this.libs.Getlibry().subscribe( async (resp) => {      
       
       // console.log("entry:",resp)
       if(resp){
 
        this.USRLi(resp.arch);
        // Ordenar la lista de folios después de obtenerla
        this.List[0].sort((a, b) => {
          const dateA = new Date(a.coordsxusr[0].dayorigin);
          const dateB = new Date(b.coordsxusr[0].dayorigin);
          return dateB.getTime() - dateA.getTime();
        });       
       }
     });  
   

    this.contentHeader = {
      headerTitle: 'TizAPP',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'SSP',
            isLink: true,
            link: '/'
          },
          {
            name: 'Lista Activaciones',
            isLink: false
          }
        ]
      }
    }
  }
  USRLi(Info:any){      
    
    this.List = [Info,...this.List];
    // console.log('Foles::',this.List);            
  }
  go_to(id:any){
    this.route.navigate(['/solicitud/',id]); 
  }
  SendRepo(fole:string){
    if(this.FormAgent.valid){

      this.libs.Agent_repo(this.FormAgent.value,fole).subscribe();
      this.libs.Calculate().subscribe();

      this.FormAgent.reset();
      location.reload();
    }
    else{
      alert('Completa los campos solicitados');
    }
  }

  
  AporLosFlacos(){
    this.route.navigateByUrl('/ssp');
  }


  
  
  
}
